import React from 'react';

import {
    Row,
    Divider,
    Space,
    Button,
    Typography
} from 'antd';

import {
    EditOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons';

import { checkOnlyContainsDaily } from '../../utils/DefinitionUtils';
import * as Constants from '../../utils/Constants';

export function DefinitionTable(props) {

    const { upOrder, downOrder, editMonth, monthly } = props;

    // console.log(monthly);
    const _months = monthly.m.map((mth) => Constants.months[mth]);
    // console.log(_months);
    return (
        <React.Fragment key={JSON.stringify(_months)}>
            <Row justify="left" className={"primary"}>{
                _months.join(", ").replace(/, ([^,]*)$/, ' and $1')
            }</Row>
            <Space direction="vertical" size="small" style={{ paddingTop: "10px" }}>
                {monthly.d.map((daysArray, index) => {
                    const _days = daysArray.map((da) => Constants.days[da]);
                    // console.log("day array");
                    // console.log(daysArray);
                    // console.log("monthly")
                    // console.log(monthly.only);
                    // console.log("comparison")
                    // console.log(monthly.only.contains(daysArray));
                    // console.log(monthly.only.includes(daysArray));

                    // console.log(daysArray.indexOf(monthly.only));
                    // console.log(monthly._id + _days);
                    return <React.Fragment key={monthly._id + " " + index}>
                        <Row style={{ padding: "4px" }} justify="left">
                            <Space size="small">
                                <Typography.Text>
                                    {_days.join(", ").replace(/, ([^,]*)$/, ' and $1')}
                                </Typography.Text>
                                {monthly.only
                                    ? checkOnlyContainsDaily(monthly.only, daysArray)
                                        ? <Typography.Text>Only *</Typography.Text>
                                        : <></>
                                    : <></>}
                            </Space>
                        </Row>
                    </React.Fragment>
                })}
            </Space>
            <Divider orientation="right">
                <Space size="small" direction="horizontal">
                    {/* <Popconfirm title="Are you sure want to delete this whole definition?" okText="Yes" onConfirm={() => console.log("Delete")} cancelText="Cancel" onCancel={Constants.cancel} >
						<Button type="ghost" icon={<DeleteOutlined />} danger>Delete</Button>
					</Popconfirm> */}
                    <Button type="ghost" key="up" icon={<CaretUpOutlined />} onClick={() => upOrder(monthly._id)} />
                    <Button type="ghost" key="down" icon={<CaretDownOutlined />} onClick={() => downOrder(monthly._id)} />
                    <Button type="primary" key="edit" onClick={() => editMonth(monthly)} icon={<EditOutlined />}>Edit</Button>
                </Space>
            </Divider>
        </React.Fragment>
    )
}
