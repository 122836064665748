// import React from "react";
// import axios from 'axios';
// CSS
import "../../pages/css/Timetables.css";

import * as Constants from "../../utils/Constants";
import { checkOnlyContainsDaily } from "../../utils/DefinitionUtils";

export function DayLabels(props) {
    // return level={5} <Typography.Text></Typography.Text>
    const { dayArray, only } = props;

    let markAsOnly = false;
    // console.log(`compare ${dayArray} | ${only}`);
    if (checkOnlyContainsDaily(only, dayArray)) {
        markAsOnly = true;
    }

    let title = "";
    for (let i = 0; i < dayArray.length; i++) {
        if (dayArray.length === 1) {
            // definitionsArray.push({ "monthLabel": months[month[0]], "monthDefinitions": month, "dayDefinitions": days });
            title = Constants.days[dayArray[0]];
        } else if (dayArray.length === 7) {
            // definitionsArray.push({ "monthLabel": months[month[0]], "monthDefinitions": month, "dayDefinitions": days });
            title = `${Constants.days[dayArray[0]]} to ${Constants.days[dayArray[6]]}`;
        } else {
            title = `${dayArray.slice(0, dayArray.length - 1).map((d) => Constants.days[d]).join(", ")} and ${Constants.days[dayArray[dayArray.length - 1]]}`
            // title = dayArray.map((i, index) => {
            //     // console.log(i);
            //     if (dayArray.length - 1 === index) {
            //         return Constants.days[i];
            //     } else if (dayArray.length - 2 === index) {
            //         return Constants.days[i] + " and ";
            //     } else {
            //         return Constants.days[i] + ", ";
            //     }
            // });
        }
    }
    return markAsOnly ? `${title} Only` : `${title}`;
};