import React, { useState, useRef } from "react";

import { Space, Card, Row, Col, Typography, Button, message } from "antd";

import { PrinterOutlined } from "@ant-design/icons";

import { Drawer } from "antd";
import * as TimeUtils from "../../utils/TimeUtils";
import * as Constants from "../../utils/Constants";
// import { usb, getDeviceList } from "usb";

import ReactToPrint from "react-to-print";
import { format } from "date-fns";
import { Spin } from "antd";
import { Select } from "antd";
import { CheckInStatus } from "../tickets/checkin/CheckInStatus";
import { updateCheckInStatus } from "../../services/TicketService";

export function PrintLabel(props) {
    const { ticket, setTicket, setUpdate } = props;

    const componentRef = useRef();

    const [openTestDrawer, setOpenTestDrawer] = useState(false);

    const showDrawer = () => {
        setOpenTestDrawer(true);
    };
    const onClose = () => {
        setOpenTestDrawer(false);
    };

    async function updateTicketCheckInStatus(status) {
        let { error } = await updateCheckInStatus(ticket._id, status);
        if (error === 0) {
            let newTicket = ticket;
            newTicket.meta.checkedin = status;
            setTicket(newTicket);
            setUpdate(1);
        } else {
            message.error("Failed to update status");
        }
    }

    const [labelSize, setLabelSize] = useState("large");

    return (
        <Space direction="horizontal" size="middle">
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Button onClick={() => showDrawer()} icon={<PrinterOutlined />}>
                    Print Car Label
                </Button>
                <Drawer title="Print Label" placement="right" onClose={onClose} open={openTestDrawer}>
                    {/* <Space direction="horizontal">
                    <Button type={labelSize === "large" ? "primary" : "default"} onClick={() => setLabelSize("large")}>
                    Large
                    </Button>
                    <Button type={labelSize !== "large" ? "primary" : "default"} onClick={() => setLabelSize("small")}>
                    Small
                    </Button>
                </Space> */}
                    {ticket == null ? <Spin /> : <PrinterLabel ref={componentRef} ticket={ticket} labelSize={labelSize} />}
                    {/* <Typography.Text  style={{ fontSize: "12px" }}>{`${JSON.stringify(ticket)}`}</Typography.Text> */}
                    <ReactToPrint
                        trigger={() => (
                            <Button icon={<PrinterOutlined />} type="primary">
                                Print Label
                            </Button>
                        )}
                        content={() => componentRef.current}
                    />
                </Drawer>
            </Space>
            {ticket.passengers.map((p) => p.quantity * p.deckSpaces > 0).reduce((total, increment) => (total += increment), 0) > 0 ? (
                <Select
                    style={{ width: "100%" }}
                    onSelect={(status) => updateTicketCheckInStatus(status)}
                    value={ticket.meta.checkedin ?? 0}
                >
                    <Select.Option value={0}>
                        <CheckInStatus passengers={ticket.passengers} status={0} />
                    </Select.Option>
                    <Select.Option value={3}>
                        <CheckInStatus passengers={ticket.passengers} status={3} />
                    </Select.Option>
                    <Select.Option value={1}>
                        <CheckInStatus passengers={ticket.passengers} status={1} />
                    </Select.Option>
                </Select>
            ) : (
                <></>
            )}
        </Space>
    );
}

export const PrinterLabel = React.forwardRef((props, ref) => {
    const { ticket, labelSize } = props;

    // const labelFontSize = labelSize === "large" ? "4px" : "6px";
    // const infoFontSize = labelSize === "large" ? "18px" : "14px";
    // const timeFontSize = labelSize === "large" ? "22px" : "16px";

    return (
        <div ref={ref}>
            <Row gutter={[16, 16]} style={{ padding: "20px" }}>
                <Col span={24}>
                    <Space direction="vertical" size={1}>
                        {/* {`${labelSize} ${labelFontSize}`} */}
                        {`${ticket.name}`.trim() !== "" && ticket.name !== "null" ? (
                            <Space size={1} direction="vertical">
                                <Typography.Text style={{ fontSize: "12px" }}>PASSENGER</Typography.Text>
                                <Typography.Text style={{ fontSize: "20px" }}>{`${Constants.toTitleCase(ticket.name)}`}</Typography.Text>
                            </Space>
                        ) : (
                            <></>
                        )}
                        <Space size={1} direction="vertical">
                            <Typography.Text style={{ fontSize: "12px" }}>CAR DETAILS</Typography.Text>
                            <Typography.Text style={{ fontSize: "20px" }}>{`${ticket.meta.cardetails}`.toUpperCase()}</Typography.Text>
                        </Space>
                        {`${ticket.meta.specialreq}`.trim() !== "" ? (
                            <Space size={1} direction="vertical">
                                <Typography.Text style={{ fontSize: "12px" }}>SPECIAL REQS</Typography.Text>
                                <Typography.Text style={{ fontSize: "20px" }}>{ticket.meta.specialreq}</Typography.Text>
                            </Space>
                        ) : (
                            <></>
                        )}
                        <Space size={1} direction="vertical">
                            <Typography.Text style={{ fontSize: "12px" }}>OUT</Typography.Text>
                            <Typography.Text style={{ fontSize: "16px" }}>
                                {ticket.ticket.outJourney === "dt" ? "Departing Arranmore" : "Departing Burtonport"}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: "24px" }}>
                                {`${format(ticket.ticket.outDate, "dd MMM yyyy")} ${TimeUtils.getPrettyTimeAMPM(ticket.ticket.outTime)}`}
                            </Typography.Text>
                        </Space>

                        {ticket.ticket.returnTicket && !ticket.ticket.openReturn ? (
                            <Space size={1} direction="vertical">
                                <Typography.Text style={{ fontSize: "12px" }}>RETURN</Typography.Text>
                                <Typography.Text style={{ fontSize: "16px" }}>
                                    {ticket.ticket.outJourney === "dt" ? "Departing Burtonport" : "Departing Arranmore"}
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: "24px" }}>
                                    {`${format(ticket.ticket.returnDate, "dd MMM yyyy")} ${TimeUtils.getPrettyTimeAMPM(
                                        ticket.ticket.returnTime
                                    )}`}
                                </Typography.Text>
                            </Space>
                        ) : (
                            <></>
                        )}
                        {ticket.ticket.returnTicket && ticket.ticket.openReturn ? (
                            <Space size={1} direction="vertical">
                                <Typography.Text style={{ fontSize: "12px" }}>RETURN</Typography.Text>
                                <Typography.Text style={{ fontSize: "16px" }}>
                                    {ticket.ticket.outJourney !== "dt" ? "Departing Burtonport" : "Departing Arranmore"}
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: "20px" }}>This is an open return</Typography.Text>{" "}
                            </Space>
                        ) : (
                            <></>
                        )}
                        <Space size={1} direction="vertical">
                            <Typography.Text style={{ fontSize: "12px" }}>PASSENGERS</Typography.Text>
                            <>
                                {ticket.passengers.map((p) => (
                                    <Typography.Text
                                        style={{ fontSize: "16px", display: "block" }}
                                    >{`${p.quantity} x ${p.label}`}</Typography.Text>
                                ))}
                            </>
                        </Space>
                        <Space size={1} direction="vertical">
                            {/* <Typography.Text  style={{ fontSize: "12px" }}>STATUS</Typography.Text> */}
                            <Typography.Text style={{ fontSize: "20px" }}>
                                {ticket.status === "active" || ticket.status === "succeeded"
                                    ? "[PAID]"
                                    : `[${ticket.status}]`.toUpperCase()}
                            </Typography.Text>
                        </Space>
                    </Space>
                    {/* {ticket.name.trim() !== "" && ticket.name !== "null" ? (
                        <>
                            <Typography.Text style={{ margin: "0px", display: "block" }}>PASSENGER</Typography.Text>
                            <Typography.Text style={{ margin: "0px", display: "block", fontSize: "20px" }}>
                                {" "}
                                {`${ticket.name}`}{" "}
                            </Typography.Text>
                        </>
                    ) : (
                        <></>
                    )}
                    <Typography.Text style={{ margin: "0px", display: "block" }}>CAR DETAILS</Typography.Text>
                    <Typography.Text style={{ fontSize: "20px" }}>{`${ticket.meta.cardetails}`.toUpperCase()}</Typography.Text>
                    {ticket.meta.specialreq.trim() !== "" ? (
                        <>
                            <Typography.Text style={{ margin: "0px", display: "block" }}>SPECIAL REQS</Typography.Text>
                            <Typography.Text style={{ fontSize: "20px" }}>{ticket.meta.specialreq}</Typography.Text>
                        </>
                    ) : (
                        <></>
                    )}
                    <Typography.Text style={{ margin: "0px", display: "block" }}>OUT</Typography.Text>
                    <Typography.Text style={{ fontSize: "20px", display: "block" }}>
                        {ticket.ticket.outJourney === "dt" ? "Departing Burtonport" : "Departing Arranmore"}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "16px", display: "block" }}>
                        {format(ticket.ticket.outDate, "dd MMM yyyy")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "20px" }}>{TimeUtils.getPrettyTimeAMPM(ticket.ticket.outTime)}</Typography.Text>
                    {ticket.ticket.returnTicket && !ticket.ticket.openReturn ? (
                        <>
                            <Typography.Text style={{ margin: "0px", display: "block" }}>RETURN</Typography.Text>
                            <Typography.Text style={{ fontSize: "16px", display: "block" }}>
                                {ticket.ticket.outJourney !== "dt" ? "Departing Burtonport" : "Departing Arranmore"}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: "16px", display: "block" }}>
                                {format(ticket.ticket.returnDate, "dd MMM yyyy")}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: "20px" }}>
                                {TimeUtils.getPrettyTimeAMPM(ticket.ticket.returnTime)}
                            </Typography.Text>
                        </>
                    ) : (
                        <></>
                    )}
                    {ticket.ticket.returnTicket && ticket.ticket.openReturn ? (
                        <>
                            <Typography.Text style={{ margin: "0px", display: "block" }}>RETURN</Typography.Text>
                            <Typography.Text style={{ fontSize: "16px", display: "block" }}>
                                {ticket.ticket.outJourney !== "dt" ? "Departing Burtonport" : "Departing Arranmore"}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: "20px" }}>This is an open return</Typography.Text>{" "}
                        </>
                    ) : (
                        <></>
                    )}
                    <Typography.Text style={{ margin: "0px", display: "block" }}>PASSENGERS</Typography.Text>
                    {ticket.passengers.map((p) => (
                        <Typography.Text style={{ fontSize: "16px", display: "block" }}>{`${p.quantity} x ${p.label}`}</Typography.Text>
                    ))}
                    <Typography.Text style={{ margin: "0px", display: "block" }}>STATUS</Typography.Text>
                    <Typography.Text style={{ fontSize: "20px" }}>{ticket.status === "active" ? "PAID" : ticket.status}</Typography.Text> */}
                </Col>
            </Row>
        </div>
    );
});
