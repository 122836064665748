import { checkOnlyContainsDaily, checkOnlyIndex } from "./DefinitionUtils";

export function getMonthlyData(months, ferries) {
    let times = [];

    // console.log(ferries);

    ferries.forEach((element) => {
        // console.log(element);
        if (element.months.some((r) => months.includes(r))) {
            times.push(element);
        }
    });

    // console.log(times);
    return times;
}

export function getDailyData(journey, daysDef, ferries, only) {
    let times = [];

    //   console.log(`DAILY: ${days} ${only}`)

    //   console.log("get daily data")
    //   console.log(journey)
    //   console.log(daysDef)
    //   console.log(only)
    //   console.log(checkOnlyContainsDaily(only, daysDef))

    ferries.forEach((element) => {
        // console.log(element)
        // console.log(checkOnlyContainsDaily(only, daysDef))
        if (checkOnlyContainsDaily(only, daysDef)) {
            //   console.log(element)
            //   console.log(days)
            //   console.log(checkOnlyIndex(only,daysDef))
            if (
                JSON.stringify(element.days) ===
                JSON.stringify(only[checkOnlyIndex(only, daysDef)])
            ) {
                if (element.journey === journey) {
                    times.push(element);
                }
            }
        } else {
            // console.log(element.days)
            // console.log(only)
            // IF AN EXACT MATCH FOR ANY ONLY DAY
            if (only.length > 0) {
                if (
                    !only.some(
                        (o) =>
                            JSON.stringify(o) === JSON.stringify(element.days)
                    )
                ) {
                    // console.log("ONLY")
                    if (
                        element.days.some((r) => daysDef.includes(r)) &&
                        !(
                            JSON.stringify(element.days) ===
                            JSON.stringify(only[checkOnlyIndex(only, daysDef)])
                        )
                    ) {
                        if (element.journey === journey) {
                            times.push(element);
                        }
                    }
                }
            }
        }
    });

    // console.log(times);
    times.sort((a, b) => {
        return a.time - b.time;
    });
    // times.sort();
    return times;
}
