import React from "react";

import { Space, Typography, Tooltip } from "antd";

import { CarOutlined, UserOutlined } from "@ant-design/icons";

import * as Constants from "../../../utils/Constants";
import { TbMotorbike } from "react-icons/tb";
import { BsTruck } from "react-icons/bs";
import { Tag } from "antd";
import { FaShuttleVan } from "react-icons/fa";

export function PassengerIconsSummary(props) {
    const { passengers, vertical, cardetails, showText } = props;

    if (passengers == undefined) {
        return (
            <Space direction="vertical" wrap>
                <Tag>OLD FORMAT</Tag>
                {/* <Typography.Text>{`Old Format Ticket`}</Typography.Text> */}
            </Space>
        );
    }

    const totalPass =
        passengers === null
            ? 0
            : passengers.map((p) => p.quantity * p.passengers).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalCars =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 2)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalBikes =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 1)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalVans =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 4)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalTrucks =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 6)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalVehicles = totalCars + totalBikes + totalVans + totalTrucks;

    return (
        <Space direction={vertical ? "vertical" : "horizontal"} wrap>
            <Space direction={"horizontal"} size="middle" wrap>
                <Tooltip title={`${totalPass} Passengers`}>
                    <Space direction="horizontal" size="small">
                        <UserOutlined />
                        <Typography.Text>{totalPass}</Typography.Text>
                    </Space>
                </Tooltip>
                {Constants.maxDeckSpacesAvailable > 0 && totalCars > 0 ? (
                    <Tooltip title={`${totalCars} Cars`}>
                        <Space direction="horizontal" size="small">
                            <CarOutlined />
                            <Typography.Text>{totalCars}</Typography.Text>
                        </Space>
                    </Tooltip>
                ) : (
                    <></>
                )}

                {Constants.maxDeckSpacesAvailable > 0 && totalBikes > 0 ? (
                    <Tooltip title={`${totalBikes} Bikes`}>
                        <Space direction="horizontal" size="small">
                            <TbMotorbike />
                            <Typography.Text>{totalBikes}</Typography.Text>
                        </Space>
                    </Tooltip>
                ) : (
                    <></>
                )}

                {Constants.maxDeckSpacesAvailable > 0 && totalVans > 0 ? (
                    <Tooltip title={`${totalVans} Vans`}>
                        <Space direction="horizontal" size="small">
                            <FaShuttleVan />
                            <Typography.Text>{totalVans}</Typography.Text>
                        </Space>
                    </Tooltip>
                ) : (
                    <></>
                )}
                {Constants.maxDeckSpacesAvailable > 0 && totalTrucks > 0 ? (
                    <Tooltip title={`${totalTrucks} Trucks`}>
                        <Space direction="horizontal" size="small">
                            <BsTruck />
                            <Typography.Text>{totalTrucks}</Typography.Text>
                        </Space>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </Space>
            {totalVehicles > 0 ? <Typography.Text>{cardetails}</Typography.Text> : <></>}
        </Space>
    );
}
