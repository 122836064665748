import React, { useState } from "react";
import { Drawer, Typography, Space } from "antd";
import * as TimeUtils from "../../../utils/TimeUtils";
import { format } from "date-fns";
import { PassengerIconsSummary } from "../passengers/PassengerIconsSummary";
import TicketStatus from "../TicketStatus";
import * as CarUtils from "../../../utils/CarUtils";
import * as Constants from "../../../utils/Constants";

import { InfoCircleFilled } from "@ant-design/icons";

export function AvailabilityDrawer(props) {
    const { date, time, tickets, isOpen, onClose } = props;
    const [width] = useState(window.innerWidth);

    return (
        <Drawer
            open={isOpen}
            onClose={() => onClose()}
            title={`Tickets for ${format(new Date(date), `do MMMM yyyy`)} at ${TimeUtils.getPrettyTimeAMPM(time)}`}
            width={width > 900 ? 640 : width}
        >
            {date === null || date === undefined || time === null || time === undefined ? (
                <Typography.Text>{`isopen: ${isOpen} date: ${date}, time: ${time}`}</Typography.Text>
            ) : (
                <Space direction="vertical">
                    {/* <Typography.Text>{`${isOpen}`}</Typography.Text> */}
                    {/* <Typography.Text>{`${format(new Date(date), "yyyy-MM-dd")} at ${TimeUtils.getPrettyTimeAMPM(time)}`}</Typography.Text> */}
                    {/* <Typography.Text>{JSON.stringify(tickets)}</Typography.Text> */}
                    {/* {tickets.map((t, index) => ( */}
                    <Space key={`}`} direction="vertical">
                        {tickets.filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 2) > 0).length > 0 ? (
                            <Space direction="vertical">
                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 2) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`car${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`car${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 2)}x ${t.name}, ${
                                                    `${t.meta.cardetails}`.toUpperCase() ?? "No car details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 4) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`van${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`van${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 4)}x ${t.name}, ${
                                                    `${t.meta.cardetails}`.toUpperCase() ?? "No van details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 1) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`bike${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`bike${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 1)}x ${t.name}, ${
                                                    `${t.meta.cardetails}`.toUpperCase() ?? "No bike details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 6) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`lorry${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`lorry${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 6)}x ${t.name}, ${
                                                    `${t.meta.cardetails}`.toUpperCase() ?? "No lorry details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {/* {`${tickets.filter((t) => t.meta.specialreq !== null || `${t.meta.specialreq}` !== "null").length}`} */}
                            </Space>
                        ) : (
                            <Typography.Text>No Cars Booked</Typography.Text>
                        )}
                        {tickets.filter((t) => t.meta.specialreq !== null && `${t.meta.specialreq}` !== "null" && t.meta.specialreq !== "")
                            .length > 0 ? (
                            <Space direction="vertical" style={{ marginTop: "20px" }}>
                                <Space direction="horizontal">
                                    <Typography.Text style={{ fontWeight: "bold" }}>
                                        <InfoCircleFilled />
                                        {`  Special Requirements ${time ? `at ${Constants.getTimestamp(time)}` : ""}`}
                                    </Typography.Text>
                                </Space>
                                {tickets
                                    .filter(
                                        (t) => t.meta.specialreq !== null && `${t.meta.specialreq}` !== "null" && t.meta.specialreq !== ""
                                    )
                                    .map((t, index) => (
                                        <Typography.Text key={index}>{`${t.name}: ${t.meta.specialreq}`}</Typography.Text>
                                    ))}
                            </Space>
                        ) : (
                            <></>
                        )}
                    </Space>
                    {/* ))} */}
                </Space>
            )}
        </Drawer>
    );
}
