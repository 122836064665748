import React, { useEffect, useState } from "react";
import * as GoldenTicketService from "../../services/GoldenTicketService";
import { Drawer, Typography, DatePicker, Space, Button, Form, Input, message } from "antd";
import { CiCircleOutlined, CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { add } from "date-fns";
import moment from "moment";
import { Spin } from "antd";
import { Divider } from "antd";
//
export function EditGoldenTicketPanel(props) {
    const { onClose, openDrawer, id, reload } = props;
    const [width] = useState(window.innerWidth);

    const [currentTicket, setCurrentTicket] = useState();

    useEffect(() => {
        loadOneGoldenTicket();
    }, [id]);

    async function loadOneGoldenTicket() {
        if (id != null) {
            setIsLoading(true);
            let { data, error } = await GoldenTicketService.getGoldenTickets();
            if (error === 0) {
                let filteredGT = data.filter((d) => d._id === id);
                if (filteredGT.length != 0) {
                    setGoldenTicketDetails(filteredGT[0]);
                } else {
                    message.error("Couldn't find the golden ticket requested");
                }
            }
            setIsLoading(false);
        }
    }

    // time, message, priceLimit
    const [time, setTime] = useState(new Date().getTime());
    const [gtMessage, setgGMessage] = useState("");
    const [priceLimit, setPriceLimit] = useState(100);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    function setGoldenTicketDetails(goldenTicket) {
        console.log("updating current");
        setCurrentTicket(goldenTicket);
        // setTime(goldenTicket.)
        setTime(new Date(goldenTicket.goldenTime).getTime());
        setgGMessage(goldenTicket.message);
        setPriceLimit(goldenTicket.priceLimit / 100);
    }

    function updateDate(date) {
        const timeInt = date.getTime();
        console.log(timeInt);
        setTime(timeInt);
    }

    function getRandomNumber(max, min) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function setRandomDate() {
        let date = new Date();
        let randomDate = add(date, { days: getRandomNumber(0, 7), hours: getRandomNumber(0, 23), minutes: getRandomNumber(0, 23) });
        setTime(randomDate.getTime());
    }

    function resetForm() {
        setTime(new Date().getTime());
        setgGMessage("");
        setPriceLimit(100);
    }

    async function editGoldenTicket() {
        setIsUpdating(true);
        let { error } = await GoldenTicketService.editGoldenTicket(id, time, gtMessage, priceLimit * 100);
        if (error === 0) {
            message.success("Updated Golden Ticket");
            resetForm();
            onClose();
        } else {
            message.error("Couldn't Updated Golden Ticket");
        }
        setIsUpdating(false);
    }

    async function deleteGoldenTicket() {
        setIsUpdating(true);
        let { error } = await GoldenTicketService.deleteGoldenTicket(id);
        if (error === 0) {
            message.success("Delete Golden Ticket");
            resetForm();
            reload();
            onClose();
        } else {
            message.error("Couldn't Delete Golden Ticket");
        }
        setIsUpdating(false);
    }

    return (
        <Drawer title="Edit Golden Ticket" placement="right" onClose={() => onClose()} open={openDrawer} width={width > 900 ? 640 : "100%"}>
            {isLoading ? (
                <Spin />
            ) : (
                <Space direction="vertical">
                    {/* <Typography.Text>{`${JSON.stringify(currentTicket)}`}</Typography.Text> */}
                    <Form layout={"vertical"}>
                        <Form.Item label="Date">
                            <Space style={{ width: "100%" }}>
                                <DatePicker value={new moment(time)} onChange={(v) => updateDate(v._d)} showTime allowClear={false} />
                                <Button onClick={() => setRandomDate()}>Pick Random Time in Week</Button>
                            </Space>
                        </Form.Item>
                        <Form.Item label="Message">
                            <Input
                                value={gtMessage}
                                placeholder="Congratulations Message to user"
                                onChange={(v) => setgGMessage(v.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Price Limit">
                            <Typography.Text style={{ paddingBottom: "0.5em" }}>
                                This is the upper limit on ticket price give away, default is €100
                            </Typography.Text>
                            <Input value={priceLimit} placeholder={100} onChange={(v) => setPriceLimit(v.target.value)} />
                        </Form.Item>
                    </Form>

                    <Button type="primary" icon={<SaveOutlined />} onClick={() => editGoldenTicket()} loading={isUpdating}>
                        Update
                    </Button>
                    <Divider />

                    <Button type="primary" icon={<CloseCircleOutlined />} onClick={() => deleteGoldenTicket()} loading={isUpdating} danger>
                        Delete
                    </Button>
                    {/* <Typography.Text>{`time: ${time}, gtMessage: ${gtMessage}, priceLimit: ${priceLimit}`}</Typography.Text> */}
                </Space>
            )}
        </Drawer>
    );
}
