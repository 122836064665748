import { Typography, Space, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import * as PriceService from "../../../services/PriceService";
import { PlusCircleOutlined } from "@ant-design/icons";
import { PassengerList } from "./PassengerList";
import { AddCustomPrice } from "./AddCustomPrice";
import { EditCustomPrice } from "./EditCustomPrice";

export function PassengerListBuilder(props) {
    const { newTicket, updatePassengers, removePassenger } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [priceList, setPriceList] = useState([]);
    const [editCustomPrice, setEditCustomPrice] = useState(false);

    useEffect(() => {
        getPrices();
    }, []);

    async function getPrices() {
        setIsLoading(true);
        let response = await PriceService.getPrices();
        // console.log(response);
        setPriceList(response);
        setIsLoading(false);
    }

    return (
        <Space style={{ width: "100%" }}>
            {isLoading ? (
                <Spin />
            ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {/* ISLANDERS */}
                    <Typography.Text style={{ fontWeight: "bold" }}>Islanders</Typography.Text>
                    <Space size={[16, 16]} wrap>
                        {priceList
                            .filter((p) => p.islander === true)
                            .map((price) => (
                                <Button
                                    key={price._id}
                                    size="medium"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() =>
                                        updatePassengers({
                                            code: price.code,
                                            label: price.label,
                                            price: price.price,
                                            quantity: 1,
                                            passengers: price.passengers,
                                            deckSpaces: price.deckSpaces,
                                            islander: price.islander
                                            // key: new Date().getTime()
                                        })
                                    }
                                >{`${price.label}`}</Button>
                            ))}
                    </Space>
                    {/* VISITORS */}
                    <Typography.Text style={{ fontWeight: "bold", paddingtop: "1em" }}>Visitors</Typography.Text>
                    <Space size={[16, 16]} wrap>
                        {priceList
                            .filter((p) => p.islander === false)
                            .map((price) => (
                                <Button
                                    key={price._id}
                                    size="medium"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() =>
                                        updatePassengers({
                                            code: price.code,
                                            label: price.label,
                                            price: price.price,
                                            quantity: 1,
                                            passengers: price.passengers,
                                            deckSpaces: price.deckSpaces,
                                            islander: price.islander
                                            // key: new Date().getTime()
                                        })
                                    }
                                >{`${price.label}`}</Button>
                            ))}
                    </Space>
                    <Typography.Text style={{ fontWeight: "bold", paddingtop: "1em" }}>Custom Price</Typography.Text>
                    <AddCustomPrice updateCustomPrice={updatePassengers} />
                    <EditCustomPrice editCustomPrice={editCustomPrice} setEditCustomPrice={setEditCustomPrice} customTicket={newTicket.passengers.filter((t) => t.code == "custom")} removePassenger={removePassenger} updateCustomPrice={updatePassengers} />
                    <PassengerList newTicket={newTicket} removePassenger={removePassenger} setEditCustomPrice={setEditCustomPrice} showRemove={true} showTotals={true} />
                </Space>
            )}
        </Space>
    );
}
