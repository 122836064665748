import React, { useState, useEffect } from "react";

import {
    PageHeader,
    Button,
    Row,
    Col,
    Card,
    Table,
    Spin,
    Space,
    Input,
    Tooltip,
    message
} from "antd";

import { CrownOutlined, UserOutlined } from "@ant-design/icons";

import * as Constants from "../utils/Constants";
import * as ToryService from "../services/ToryService";
import LogoutButton from "../components/LogoutButton";
import Fuse from "fuse.js";
import EditAccountPanel from "../components/accounts/EditAccountPanel";
import SendNotificationPanel from "../components/accounts/AccountsNotification";

// const { Meta } = Card;

const { Search } = Input;

function Accounts() {
    // SETTING NEW ONES
    const [accounts, setAccounts] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
    const [singleAccount, setSingleAccount] = useState(null);
    const [width] = useState(window.innerWidth);

    async function loadAccounts() {
        let response = await ToryService.getAccounts();
        // console.log(response);
        if (response.status === 200) {
            setAccounts(response.accounts);
        } else {
            // console.log("set 0");
            setAccounts(0);
        }
    }

    const searchOptions = {
        threshold: 0.4,
        keys: ["name", "email"]
    };

    const fuse = new Fuse(accounts, searchOptions);

    function searchAccounts(searchTerm) {
        if (searchTerm === "" || searchTerm === undefined) {
            loadAccounts();
        } else {
            // console.log(searchTerm);
            const searchedTickets = fuse.search(searchTerm);
            // console.log(searchedTickets);
            const newTickets = [];
            searchedTickets.map((t) => newTickets.push(t.item));

            setAccounts(newTickets);
        }
    }

    async function showAccountDetails(id) {
        // console.log(id);
        setSingleAccount(getAccountDetails(id));
        setShowDrawer(true);
    }

    async function showNotificationDetails(id) {
        // console.log(id);
        setSingleAccount(getAccountDetails(id));
        setShowNotificationDrawer(true);
    }

    function getAccountDetails(id) {
        let ticket = null;
        accounts.forEach((element) => {
            if (element._id === id) {
                ticket = element;
            }
        });
        return ticket;
    }

    function closeDrawer() {
        setShowDrawer(false);
        setShowNotificationDrawer(false);
        setSingleAccount(null);
        loadAccounts();
    }

    async function updateAdmin(type) {
        // console.log("make admin " + id)
        let response = await ToryService.updateAccount(
            "type",
            type,
            singleAccount.uid
        );
        console.log(response);
        if (response.status === 200) {
            message.success("Admin Status Changed");
            let updatedAccount = singleAccount;
            updatedAccount.type = "admin";
            setSingleAccount(updatedAccount);
        } else {
            message.error("Admin Status Can't Be Changed");
        }
    }

    async function removeAccount(id) {
        console.log("remove " + id);
        // TORY SERVICE DELETE ACCOUNT
        let response = await ToryService.removeAccount(id);
        if (response.status === 200) {
            message.success("User Deleted");
            setShowDrawer(false);
            loadAccounts();
        } else {
            message.error("Can't Delete This User");
        }
        // SET TICKET DETAIL ONLY
    }

    // async function updatePhoneNumber(phoneNumber) {
    // 	// console.log(phoneNumber)
    // 	if (phoneNumber !== "Not Set") {
    // 		let response = await ToryService.updateAccount("phoneNumber", phoneNumber, singleAccount.uid);
    // 		console.log(response);
    // 		if (response.status === 200) {
    // 			message.success("Phone Number Updated")
    // 		} else {
    // 			message.error("Can't update number")
    // 		}
    // 	}
    // }

    async function updateAccount(type, value) {
        // console.log(type, value);
        if (value !== "" || value !== null) {
            let response = await ToryService.updateAccountV2(
                type,
                value,
                singleAccount.uid
            );
            if (response.status === 200) {
                message.success(`${type} updated to ${value}`);
            } else {
                message.error(`Can't update ${type}`);
            }
        } else {
            message.error("Nothing to update");
        }
    }

    // async function updateName(name) {
    // 	// console.log(phoneNumber)
    // 	if (name !== "Not Set") {
    // 		let response = await ToryService.updateAccount("displayName", name, singleAccount.uid);
    // 		console.log(response);
    // 		if (response.status === 200) {
    // 			message.success("Name Updated")
    // 		} else {
    // 			message.error("Can't update name")
    // 		}
    // 	}
    // }

    useEffect(() => {
        loadAccounts();
    }, []);

    return (
        <>
            <PageHeader
                ghost={false}
                title="Accounts"
                extra={<LogoutButton />}
            ></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24}>
                        <Card
                            title="Accounts"
                            bordered={false}
                            extra={
                                <Space direction="horizontal">
                                    <Search
                                        placeholder="Search Name, Email"
                                        onSearch={searchAccounts}
                                        enterButton
                                        allowClear
                                    />
                                </Space>
                            }
                        >
                            {accounts === 0 ? (
                                <>
                                    <Row justify="center">
                                        Error with Account Sync
                                    </Row>
                                </>
                            ) : accounts === [] ? (
                                <>
                                    <Row justify="center">
                                        <Spin />
                                    </Row>
                                </>
                            ) : (
                                <AccountsTable
                                    accounts={accounts}
                                    showAccountDetails={showAccountDetails}
                                    showNotificationDetails={
                                        showNotificationDetails
                                    }
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            <EditAccountPanel
                onClose={closeDrawer}
                visible={showDrawer}
                width={width > 900 ? 640 : width}
                singleAccount={singleAccount}
                updateAdmin={updateAdmin}
                removeAccount={removeAccount}
                updateAccount={updateAccount}
                // updateName={updateName}
                // updatePhoneNumber={updatePhoneNumber}
            />

            <SendNotificationPanel
                onClose={closeDrawer}
                visible={showNotificationDrawer}
                width={width > 900 ? 640 : width}
                singleAccount={singleAccount}
                // sendNotification={sendNotification}
            />
        </>
    );
}

export default Accounts;

const AccountsTable = (props) => {
    let data = props.accounts;

    data.forEach((element) => {
        element.key = element._id;
    });

    // console.log(data);

    const columns = [
        {
            title: "Name",
            dataIndex: "displayName",
            key: "displayName",
            render: (value) => (
                <span>
                    {value === null || value === "" || value === "null"
                        ? "* None Provided"
                        : value}
                </span>
            )
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (value) => (
                <span>
                    {value === null || value === "" ? "Not Set" : value}
                </span>
            )
        },
        {
            title: "Car Details",
            dataIndex: "cardetails",
            key: "cardetails",
            render: (value) => (
                <span>
                    {value === null || value === "" || value === undefined
                        ? "Not Set"
                        : value}
                </span>
            )
        },
        {
            title: "User Type",
            dataIndex: "type",
            key: "type",
            render: (value) => (
                <span>
                    {value === "admin" ? (
                        <Tooltip title={value.toUpperCase()}>
                            <CrownOutlined />
                        </Tooltip>
                    ) : (
                        <Tooltip title={value.toUpperCase()}>
                            <UserOutlined />
                        </Tooltip>
                    )}
                </span>
            )
        },
        {
            title: "Login",
            dataIndex: "provider",
            key: "provider",
            render: (value) => <>{Constants.getSocialIcon(value)}</>
        },
        {
            title: "Actions",
            dataIndex: "_id",
            key: "_id",
            render: (value) => {
                return (
                    <Space direction="horizontal">
                        <Button
                            onClick={() => props.showAccountDetails(value)}
                            type="default"
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={() => props.showNotificationDetails(value)}
                            type="default"
                        >
                            Notify
                        </Button>
                    </Space>
                );
            }
        }
        // {
        // 	title: 'Edit',
        // 	dataIndex: '_id',
        // 	key: '_id',
        // 	render: (value) => (
        // 	),
        // },
        // {
        // 	title: 'Notify',
        // 	dataIndex: '_id',
        // 	key: '_id',
        // 	render: (value) => (
        // 	),
        // },
    ];

    return <Table dataSource={data} columns={columns} pagination={true} />;
};
