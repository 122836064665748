import React, { useState } from "react";
import { Card, Row, Col, Typography, Spin } from "antd";
import TicketTable from "../tickets/TicketTable";
import EditPanel from "../tickets/EditTicketPanel";
import ViewPanel from "../tickets/ViewPanel";
// import { getStartOfDate, getEndOfDate } from '../../utils/TimeUtils';

export default function DashboardTickets(props) {
    const { reload, tickets, date } = props;

    // SETTING NEW ONES
    // const [tickets, setTickets] = useState([]);
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    // const [newTicket, setNewTicket] = useState(null);
    const [showViewDrawer, setShowViewDrawer] = useState(false);
    const [singleTicket, setSingleTicket] = useState(null);
    const [width] = useState(window.innerWidth);

    function editTicket(id) {
        // console.log
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(getTicketDetails(id));
            setShowEditDrawer(true);
        }
    }

    function viewTicket(id) {
        console.log(id);
        if (id !== null) {
            setSingleTicket(getTicketDetails(id));
            setShowViewDrawer(true);
        }
    }

    // async function sendReceipt(id) {
    //     console.log(`id of receipt ${id}`);
    //     let response = await ToryService.sendReceipt(id);
    //     if (response.status === 200) {
    //         message.success("Ticket Receipt Sent");
    //         // this.props.onClose();
    //         setShowEditDrawer(false);
    //         // loadTickets();
    //     } else {
    //         message.error("Can't send receipt for ticket");
    //     }
    // }

    // async function sendUpdateReceipt(id) {
    //     // console.log(`id of receipt ${id}`);
    //     let response = await ToryService.sendUpdateReceipt(id);
    //     if (response.status === 200) {
    //         message.success("Ticket Update Email Sent");
    //         // this.props.onClose();
    //         setShowEditDrawer(false);
    //         // loadTickets();
    //     } else {
    //         message.error("Can't send email for ticket");
    //     }
    // }

    function getTicketDetails(id) {
        let ticket = null;
        // console.log(tickets);
        tickets.forEach((element) => {
            if (element._id === id) {
                ticket = element;
            }
        });
        return ticket;
    }

    function closeEditDrawer() {
        console.log("closing drawer");
        setShowEditDrawer(false);
        setSingleTicket(null);
        reload();
    }
    function closeViewDrawer() {
        setShowViewDrawer(false);
        setSingleTicket(null);
    }

    return (
        <>
            {tickets ? (
                tickets.length === 0 ? (
                    <Row justify="center">
                        <Card style={{ width: "100%", textAlign: "center" }}>
                            <Typography.Text>No Tickets For Selected Ferry</Typography.Text>
                        </Card>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <TicketTable
                                tickets={tickets}
                                count={tickets.length}
                                viewTicket={viewTicket}
                                editTicket={editTicket}
                                archive={false}
                                date={date}
                            />
                        </Col>
                    </Row>
                )
            ) : (
                <Row>
                    <Spin />
                </Row>
            )}

            {singleTicket ? (
                <EditPanel
                    onClose={closeEditDrawer}
                    visible={showEditDrawer}
                    width={width > 900 ? 640 : width}
                    singleTicket={singleTicket}
                    editTicket={editTicket}
                    // sendReceipt={sendReceipt}
                    // sendUpdateReceipt={sendUpdateReceipt}
                />
            ) : (
                <></>
            )}

            <ViewPanel onClose={closeViewDrawer} visible={showViewDrawer} width={width > 900 ? 640 : width} singleTicket={singleTicket} />
        </>
    );
}
