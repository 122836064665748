import React, { useState, useContext, useEffect } from "react";

import { PageHeader, Row, Col, Switch, Space, Alert, Card, Descriptions, Avatar, Tooltip, Typography, Input, Button, message } from "antd";

import { CrownOutlined, UserOutlined, LinkOutlined, PrinterOutlined } from "@ant-design/icons";

import * as Constants from "../utils/Constants";
import * as SettingsService from "../services/SettingsService";
import LogoutButton from "../components/LogoutButton";
import { UserContext } from "../provider/UserProvider";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { PrinterSettings } from "../components/settings/PrinterSettings";

const { Meta } = Card;

export default function Settings(props) {
    const user = useContext(UserContext);

    const [phoneNumber, setPhoneNumber] = useState(null);
    const [isDarkMode, setIsDarkMode] = React.useState(localStorage.getItem("theme") === "light" ? false : true ?? "light");
    // const [useLiveData, setUseLiveData] = React.useState(
    //     localStorage.getItem("livedata") === "true" ? true : false ?? false
    // );
    const [showPricesLocal, setShowPricesLocal] = React.useState(localStorage.getItem("showPrices") === "true" ? true : false ?? true);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        getPhoneNumber();
    }, []);

    const { switcher, themes } = useThemeSwitcher();

    // function switchAMPM(checked) {
    // 	console.log(`switch to ${checked}`);
    // }
    // THEME TOGGLE
    const toggleTheme = (isChecked) => {
        localStorage.setItem("theme", isChecked ? "dark" : "light");
        setIsDarkMode(isChecked);
        switcher({ theme: isChecked ? themes.dark : themes.light });
    };
    // DATA TOGGLE
    // const toggleData = (useLive) => {
    //     localStorage.setItem("livedata", useLive ? "true" : "false");
    //     setUseLiveData(useLive);
    // };

    const togglePrices = (checked) => {
        console.log(checked);
        localStorage.setItem("showPrices", checked);
        setShowPricesLocal(checked);
    };

    async function getPhoneNumber() {
        let response = await SettingsService.getPhone();
        // console.log(response);
        if (response.status === 200) {
            if (response.phone) {
                setPhoneNumber(response.phone.number);
            }
        } else {
            message.error("No Phone Number");
        }
    }

    async function updatePhone() {
        let response = await SettingsService.setPhone(phoneNumber);
        if (response.status === 200) {
            message.success("Update Phone Number");
        } else {
            message.error("Can't Update Phone Right Now");
        }
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Admin Settings"
                extra={
                    // <Button key="2" type="primary">Logout</Button>
                    <LogoutButton />
                }
            ></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col xs={24} md={12}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Card title="App Settings" bordered={false}>
                                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                    <Alert message="Changes made here will affect the app instantly." type="warning" closable />

                                    <Typography.Title level={5}>App Phone Number</Typography.Title>

                                    <Input.Group compact>
                                        <Input
                                            placeholder={"Phone Number"}
                                            style={{
                                                width: "calc(100% - 200px)"
                                            }}
                                            value={phoneNumber}
                                            onChange={(v) => setPhoneNumber(v.target.value)}
                                        />
                                        <Button type="primary" onClick={() => updatePhone()}>
                                            Update
                                        </Button>
                                    </Input.Group>
                                </Space>
                            </Card>
                            <PrinterSettings />
                            <Card title="Blog Controls" bordered={false}>
                                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                    <Typography.Text>
                                        The website blog is run using the Hexa Directus blogging platform which you can manage using the
                                        link below
                                    </Typography.Text>

                                    <Descriptions title="Credentials" layout="vertical" bordered>
                                        <Descriptions.Item label="Username">blog@thearranmoreferry.com</Descriptions.Item>
                                        <Descriptions.Item label="Password">
                                            <Button type="text" onClick={() => setHidden(!hidden)}>
                                                {hidden ? "****************" : "4rr4nm0r3BLOG"}
                                            </Button>
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <a
                                        href="https://directus.hexastudios.co/admin/content/arranmore_ferry"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button icon={<LinkOutlined />} type="primary">
                                            Blogging Platform
                                        </Button>
                                    </a>
                                </Space>
                            </Card>
                        </Space>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card title="Account Settings" bordered={false}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Typography.Title level={5}>Logged In As</Typography.Title>
                                <Card>
                                    <Meta
                                        avatar={user.photoURL ? <Avatar src={user.photoURL} /> : <Avatar src={user.photoURL} />}
                                        title={user.displayName}
                                        description={user.email}
                                    />
                                </Card>
                                <Descriptions title="Account" layout="vertical" bordered>
                                    <Descriptions.Item label="Phone Number">
                                        {user.phoneNumer !== null ? user.phoneNumber : "No Phone Number"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Account Type">
                                        {user.type === "admin" ? (
                                            <Tooltip title={user.type.toUpperCase()}>
                                                <CrownOutlined />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={user.type.toUpperCase()}>
                                                <UserOutlined />
                                            </Tooltip>
                                        )}{" "}
                                        {user.type.toUpperCase()}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Provider">{Constants.getSocialIcon(user.provider)}</Descriptions.Item>
                                    <Descriptions.Item label="Last Login" span={3}>
                                        {Constants.getDateString(user.datecreated)}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Space>
                        </Card>
                        <Card title="Panel Settings" bordered={false} style={{ marginTop: "15px" }}>
                            <Space direction="vertical">
                                {/* <Typography.Title level={5}>AMPM Settings</Typography.Title> */}

                                {/* <Switch defaultChecked onChange={switchAMPM} /> */}

                                <Typography.Title level={5}>Dark Mode Settings</Typography.Title>

                                <Switch checked={isDarkMode} onChange={toggleTheme} />

                                {/* {Constants.debug ? (
                                    <>
                                        <Typography.Title level={5}>
                                            Use Live Data
                                        </Typography.Title>
                                        <Switch
                                            checked={useLiveData}
                                            onChange={toggleData}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )} */}

                                <Typography.Title level={5}>Price Settings</Typography.Title>

                                <Typography.Text>Show prices on ticket tables.</Typography.Text>

                                <Switch checked={showPricesLocal} onChange={togglePrices} />
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

// export default Settings;
