import React from "react";

import {
    Button,
    Col,
    Card,
    Spin,
    Drawer,
    Avatar,
    Popconfirm,
    Radio,
    Descriptions,
    Input
} from "antd";

import { CloseSquareOutlined } from "@ant-design/icons";

import * as Constants from "../../utils/Constants";

const { Meta } = Card;
const { Search } = Input;

const EditAccountPanel = (props) => {
    const {
        width,
        onClose,
        visible,
        singleAccount,
        updateAccount,
        // updateName,
        // updatePhoneNumber,
        updateAdmin,
        removeAccount
    } = props;

    return (
        <Drawer
            title="View / Edit Account"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible}
        >
            {singleAccount ? (
                <>
                    <Descriptions bordered>
                        <Descriptions.Item span={3} label="User Card">
                            <Card>
                                <Meta
                                    avatar={
                                        singleAccount.photoURL ? (
                                            <Avatar
                                                src={singleAccount.photoURL}
                                            >
                                                {singleAccount.displayName}
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                src={singleAccount.photoURL}
                                            >
                                                {singleAccount.displayName.substring(
                                                    0,
                                                    1
                                                )}
                                            </Avatar>
                                        )
                                    }
                                    title={singleAccount.displayName}
                                    description={singleAccount.email}
                                />
                            </Card>
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Name">
                            <Search
                                width={"100px"}
                                defaultValue={
                                    singleAccount.displayName === null ||
                                    singleAccount.displayName === ""
                                        ? "Not Set"
                                        : singleAccount.displayName
                                }
                                // allowClear
                                enterButton="Update"
                                onSearch={(v) =>
                                    updateAccount("displayName", v)
                                }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Phone Number">
                            <Search
                                width={"100px"}
                                defaultValue={
                                    singleAccount.phoneNumber === null ||
                                    singleAccount.phoneNumber === ""
                                        ? "Not Set"
                                        : singleAccount.phoneNumber
                                }
                                // allowClear
                                enterButton="Update"
                                onSearch={(v) =>
                                    updateAccount("phoneNumber", v)
                                }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Car Details">
                            <Search
                                width={"100px"}
                                defaultValue={
                                    singleAccount.cardetails === null ||
                                    singleAccount.cardetails === "" ||
                                    singleAccount.cardetails === undefined
                                        ? "Not Set"
                                        : singleAccount.cardetails
                                }
                                // allowClear
                                enterButton="Update"
                                onSearch={(v) => updateAccount("cardetails", v)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Admin">
                            <Radio.Group
                                defaultValue={singleAccount.type}
                                onChange={(e) => updateAdmin(e.target.value)}
                                className={"pb-1"}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="admin">Admin</Radio.Button>
                                <Radio.Button value="user">User</Radio.Button>
                            </Radio.Group>
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Login Method">
                            {Constants.getSocialIcon(singleAccount.provider)}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Daner">
                            <Popconfirm
                                title="Are you sure delete this account, this cannot be undone?"
                                okText="Yes"
                                onConfirm={() =>
                                    removeAccount(singleAccount.uid)
                                }
                                onCancel={Constants.cancel}
                                cancelText="No"
                            >
                                <Button danger> Remove Account</Button>
                            </Popconfirm>
                        </Descriptions.Item>
                    </Descriptions>
                </>
            ) : (
                <Col xs={18} md={8} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <Spin />
                    </div>
                </Col>
            )}
        </Drawer>
    );
};

export default EditAccountPanel;
