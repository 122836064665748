import React from "react";
import { Card, Row, Col, Typography, Space, Button } from "antd";
import * as Constants from "../../../utils/Constants";
import { PassengerIconsSummary } from "../../tickets/passengers/PassengerIconsSummary";
import TicketStatus from "../../tickets/TicketStatus";
import { CheckInStatus } from "../../tickets/checkin/CheckInStatus";

export default function TicketCard(props) {
    const { t, viewTicket, editTicket } = props;
    return (
        <Card
            key={t._id}
            size="small"
            title={
                <Row justify="space-between">
                    <Typography.Text>{`${t.name}, ${t.email}`}</Typography.Text>
                    <Space direction="horizontal">
                        <TicketStatus value={t.status} />
                        <CheckInStatus passengers={t.passengers} status={t.meta.checkedin} />
                    </Space>
                </Row>
            }
            actions={[
                <Button size="small" type="link" onClick={() => viewTicket(t._id)} style={{ padding: "0px" }}>
                    View {Constants.getIDString(t._id).toUpperCase()}
                </Button>,
                <Button size="small" type="link" onClick={() => editTicket(t._id)} style={{ padding: "0px" }}>
                    Edit
                </Button>
            ]}
        >
            <Row>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text>
                            {`${Constants.getJourneyString(t.ticket.outJourney)} on ${Constants.getNumericalDateString(
                                t.ticket.outDate
                            )} at  ${Constants.getTimestamp(t.ticket.outTime)}`}
                        </Typography.Text>
                        {t.ticket.returnTicket ? (
                            <Typography.Text style={{ fontSize: "0.8em" }}>
                                {`Return: ${Constants.getJourneyString(
                                    t.ticket.outJourney === "dm" ? "dt" : "dm"
                                )} on ${Constants.getNumericalDateString(t.ticket.returnDate)} at ${Constants.getTimestamp(
                                    t.ticket.returnTime
                                )}`}
                            </Typography.Text>
                        ) : (
                            <Typography.Text style={{ fontSize: "0.8em" }}>Single Ticket</Typography.Text>
                        )}
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Space direction="vertical">
                        <PassengerIconsSummary passengers={t.passengers} vertical={false} cardetails={t.meta.cardetails} />
                    </Space>
                </Col>
                <Col span={12}></Col>
            </Row>
        </Card>
    );
}
