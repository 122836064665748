import React, { useState } from "react";

import {
    Divider,
    Button,
    Row,
    Col,
    Drawer,
    Tag,
    Space,
    Radio,
    Descriptions,
    Spin,
    message,
    Popconfirm,
    Typography,
    Select,
    Form,
    Input,
    TimePicker,
    Alert
} from "antd";

import {
    CloseSquareOutlined,
    SaveOutlined,
    CloseCircleOutlined
    // DownSquareFilled,
    // MailOutlined
} from "@ant-design/icons";

import { JourneyPicker } from "../../common/JourneyPicker";

import * as Constants from "../../../utils/Constants";
import * as TimeUtils from "../../../utils/TimeUtils";

import * as ToryService from "../../../services/ToryService";
import * as RecurringTicketService from "../../../services/RecurringTicketService";

import moment from "moment";
import { format } from "date-fns";
import { NormalizeDate } from "../../../utils/DateUtils";
import { PassengerListBuilder } from "../passengers/PassengerListBuilder";
// const { Option } = Select;

const timeFormat = "HH:mm";

export default function EditRecurringPanel(props) {
    //
    const {
        singleTicket,
        width,
        onClose,
        visible,
        // archiveTicket,
        viewAllTickets
    } = props;

    const [ticket, setTicket] = useState(singleTicket);
    // const [outTimes, setOutTimes] = useState(null);
    // const [returnTimes, setReturnTimes] = useState(null);
    const [update, setUpdate] = useState(0);

    const [recurRepeat, setRecurRepeat] = useState(singleTicket.recurrance.type);
    const [recurDuration, setRecurDuration] = useState(singleTicket.recurrance.duration);
    const [days, setDays] = useState(singleTicket.recurrance.days);

    // function selectDays(e) {
    //     // console.log(e.target.value);
    //     setDays(e);
    //     // recurranceChange();
    // }

    // const [monthlyDate, setMonthlyDate] = useState(1);

    async function updateMeta(property, value) {
        let updated = singleTicket;
        // console.log(updated);
        updated.meta[property] = value;
        // console.log(updated.meta);
        setTicket(updated);
        setUpdate(update + 1);
    }

    function setReturnTicket(returnTicket) {
        let newTicket = ticket;
        newTicket.ticket.returnTicket = returnTicket;
        setTicket(newTicket);
        // console.log(newTicket.ticket.returnDate);
        if (newTicket.ticket.returnDate === null) {
            const newReturnDate = new Date();
            changeReturnDateiOS(newReturnDate.getTime());
        } else {
            changeReturnDateiOS(newTicket.ticket.returnDate);
        }
        setUpdate(update + 1);
    }

    function setOutJourney(journey) {
        let newTicket = ticket;
        newTicket.ticket.outJourney = journey;
        setTicket(newTicket);
        onOutDateChange({ _d: newTicket.ticket.outDate });
        onReturnDateChange({ _d: newTicket.ticket.returnDate });
        setUpdate(update + 1);
    }

    function changeOutDateiOS(date) {
        // console.log(date);
        if (date !== null) {
            onOutDateChange({ _d: new Date(date) });
        }
    }
    function changeReturnDateiOS(date) {
        // console.log(date);
        if (date !== null) {
            onReturnDateChange({ _d: new Date(date) });
        }
    }

    async function onOutDateChange(date) {
        if (date !== null) {
            let newTicket = ticket;
            newTicket.ticket.outDate = Constants.getEpochDate(date._d);
            let response = await ToryService.getDateTimes(ticket.ticket.outJourney, Constants.getEpochDate(date._d));
            // RESET TICKET TIMES
            newTicket.ticket.outTime = null;
            newTicket.ticket.outReroute = false;
            setTicket(newTicket);
            // setOutTimes(Constants.getOneDayTimesArray(response));
            setUpdate(update + 1);
        }
    }

    async function onReturnDateChange(date) {
        // console.log(date);
        if (date !== null) {
            let newTicket = ticket;
            newTicket.ticket.returnDate = Constants.getEpochDate(date._d);
            // setState({ticket: newTicket});
            let response = await ToryService.getDateTimes(ticket.ticket.outJourney === "dm" ? "dt" : "dm", Constants.getEpochDate(date._d));
            // updateTicket("outTime", response.times);
            newTicket.ticket.returnTime = response.times;
            newTicket.ticket.returnReroute = false;
            setTicket(newTicket);
            setUpdate(update + 1);
            // setReturnTimes(Constants.getOneDayTimesArray(response));
        }
    }

    function changeTime(type, time) {
        // console.log(time);
        if (time != null && time !== undefined) {
            // console.log(type);
            // console.log(time._d);
            // console.log(time._d.getHours());
            // console.log(time._d.getMinutes());
            const timeNumerical = time._d.getHours() * 100 + time._d.getMinutes();
            if (type === "returnTime") {
                setReturnTime(timeNumerical, false);
            } else {
                setOutTime(timeNumerical, false);
            }
        }
    }

    function setOutTime(timeString, reroute) {
        // console.log(timeString)
        let newTicket = ticket;
        newTicket.ticket.outTime = timeString;
        newTicket.ticket.outReroute = reroute;
        setTicket(newTicket);
        setUpdate(update + 1);
    }
    function setReturnTime(timeString, reroute) {
        let newTicket = ticket;
        newTicket.ticket.returnTime = timeString;
        newTicket.ticket.returnReroute = reroute;
        setTicket(newTicket);
        setUpdate(update + 1);
    }

    async function deleteTicket() {
        // CALL SERVER
        let response = await RecurringTicketService.deleteTicket(ticket._id);
        if (response.status === 200) {
            message.success("Ticket Deleted");
            onClose();
        } else {
            message.error("Can't delete ticket");
        }
    }

    async function updateStatus(status) {
        let newStatus = Constants.getServerStatus(status);
        let newTicket = ticket;
        newTicket.status = newStatus;
        setTicket(newTicket);
        setUpdate(update + 1);
        updateTicketStatus();
    }

    async function updateTicketStatus() {
        // console.log(readyTicket);
        let response = await RecurringTicketService.updateTicketStatus(ticket._id, ticket.status);
        // console.log(response);
        if (response.status === 200) {
            message.success("Recurring Tickets Updated");
        } else {
            message.error("Recurring Ticket cannot be updated on the server");
        }
    }

    //
    function updatePassengers(passenger) {
        // console.log(passenger);
        // console.log(newTicket.passengers);
        let currentPassengers = ticket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(passenger.code);
        if (matchingCodeIndex > -1) {
            currentPassengers[matchingCodeIndex].quantity += 1;
        } else {
            currentPassengers.push(passenger);
        }
        let newTicket = ticket;
        newTicket.passengers = currentPassengers;
        // updateTicket("passengers", currentPassengers);
        setTicket(newTicket);
        setUpdate(update + 1);
    }
    //
    function removePassenger(code) {
        let currentPassengers = ticket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(code);
        if (matchingCodeIndex > -1) {
            if (currentPassengers[matchingCodeIndex].quantity > 1) {
                currentPassengers[matchingCodeIndex].quantity -= 1;
            } else {
                currentPassengers.splice(matchingCodeIndex, 1);
            }
        }
        let newTicket = ticket;
        newTicket.passengers = currentPassengers;
        // updateTicket("passengers", currentPassengers);
        setTicket(newTicket);
        setUpdate(update + 1);
    }

    async function saveTicketToServer() {
        // console.log(ticket);
        // let readyTicket = Constants.getServerTicket(ticket);
        // console.log(readyTicket);
        let readyTicket = ticket;

        readyTicket.recurrance.startDate = NormalizeDate(readyTicket.recurrance.startDate).getTime();
        readyTicket.recurrance.type = recurRepeat;
        readyTicket.recurrance.days = days;
        readyTicket.recurrance.duration = recurDuration;

        const ticketid = readyTicket._id;
        delete readyTicket._id;
        // let ticket = ticket
        // VALIDATE TICKET FIRST
        if (validateTicket(readyTicket)) {
            let response = await RecurringTicketService.updateTicket(ticketid, readyTicket);
            // console.log(response);
            if (response.status === 200) {
                message.success("Ticket Updated");
            } else {
                message.error("Ticket cannot be updated on the server");
            }
        } else {
            message.error("Ticket cannot be updated on the server");
        }
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    return (
        <Drawer
            title="Edit Recurring Ticket"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            visible={visible}
        >
            {singleTicket ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {/* <Typography.Text>{JSON.stringify(singleTicket)}</Typography.Text> */}
                    <Descriptions title="Ticket" layout="vertical" size="small" bordered>
                        {/* ROW */}
                        <Descriptions.Item label="Customer" span={2}>
                            {singleTicket.name} <br />
                            <a href={"mailto:" + props.singleTicket.email + ""}>{singleTicket.email}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status" span={1}>
                            <Space direction="vertical" style={{ width: "100%" }} size="small">
                                <Tag color={Constants.getPaidStatusColour(singleTicket.status)}>
                                    {Constants.getPaidStatus(singleTicket.status)}
                                </Tag>
                                <Select
                                    style={{ width: "100%" }}
                                    onSelect={updateStatus}
                                    value={Constants.getPaidStatus(singleTicket.status)}
                                    // value={singleTicket.status}
                                >
                                    <Select.Option value={"Paid"}>Paid</Select.Option>
                                    <Select.Option value={"Reserved"}>Reserved</Select.Option>
                                    <Select.Option value={"Inactive"}>Inactive</Select.Option>
                                    <Select.Option value={"Refund Requested"}>Refund Requested</Select.Option>
                                    <Select.Option value={"Refunded"}>Refunded</Select.Option>
                                    <Select.Option value={"Requires Action!"}>Requires Action!</Select.Option>
                                </Select>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Meta" span={3}>
                            <Form
                                labelCol={{
                                    span: 8
                                }}
                                wrapperCol={{
                                    span: 16
                                }}
                            >
                                <Form.Item label="Car Details">
                                    <Input
                                        placeholder="Make and Models"
                                        onChange={(v) => updateMeta("cardetails", v.target.value)}
                                        value={ticket.meta.cardetails}
                                    />
                                </Form.Item>
                                <Form.Item label="Special Reqs">
                                    <Input
                                        placeholder="Wheelchair Access..."
                                        onChange={(v) => updateMeta("specialreq", v.target.value)}
                                        value={ticket.meta.specialreq}
                                    />
                                </Form.Item>
                                <Form.Item label="Number">
                                    <Input
                                        placeholder="+353..."
                                        onChange={(v) => updateMeta("number", v.target.value)}
                                        value={ticket.meta.number}
                                    />
                                </Form.Item>
                                <Form.Item label="Smart Pass">
                                    <Input
                                        placeholder="Smart Pass ID"
                                        onChange={(v) => updateMeta("smartpass", v.target.value)}
                                        value={ticket.meta.smartpass}
                                    />
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>
                        <Descriptions.Item label="Return Ticket" span={3}>
                            <Radio.Group
                                value={ticket.ticket.returnTicket}
                                onChange={(e) => setReturnTicket(e.target.value)}
                                buttonStyle="solid"
                            >
                                <Radio.Button value={true}>Return</Radio.Button>
                                <Radio.Button value={false}>One Way</Radio.Button>
                            </Radio.Group>
                        </Descriptions.Item>
                        <Descriptions.Item label="Journey" span={3}>
                            <Space direction="vertical">
                                <JourneyPicker journey={ticket.ticket.outJourney} onJourneyChange={(e) => setOutJourney(e.target.value)} />
                                {ticket.ticket.returnTicket ? (
                                    <Typography.Text>
                                        Will Return:{" "}
                                        {ticket.ticket.outJourney === "dm"
                                            ? Constants.getJourneyString("dt")
                                            : Constants.getJourneyString("dm")}
                                    </Typography.Text>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Passengers" span={3}>
                            <PassengerListBuilder
                                updatePassengers={(t) => updatePassengers(t)}
                                removePassenger={removePassenger}
                                newTicket={ticket}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Recurrance">
                            <Space direction="vertical">
                                <Alert
                                    type="info"
                                    message="You can't edit recurrance, it would create too many changes to existing tickets, you can delete below or create a new recurring ticket"
                                />

                                <Space>
                                    <Tag color={"processing"}>
                                        {`${singleTicket.recurrance.duration}x ${singleTicket.recurrance.type.toUpperCase()}`}
                                    </Tag>
                                </Space>
                                <Space wrap>
                                    {singleTicket.recurrance.days.map((d, idx) => (
                                        <Tag key={idx} color={"purple"}>{`${Constants.days[d].toUpperCase()}`}</Tag>
                                    ))}
                                </Space>

                                <Typography.Text>{`Created on: ${format(singleTicket.datecreated, "do, MMM  yyy")}`}</Typography.Text>

                                <Space direction="horizontal">
                                    <Space
                                        style={{
                                            width: "100%",
                                            padding: "15px 0"
                                        }}
                                        direction="vertical"
                                    >
                                        <Typography.Title level={5}>Out Time</Typography.Title>
                                        {/* <Typography.Text>{`${singleTicket.ticket.outTime}`}</Typography.Text> */}
                                        <TimePicker
                                            defaultValue={moment(TimeUtils.getTimePickerTime(singleTicket.ticket.outTime), timeFormat)}
                                            onChange={(v) => changeTime("outTime", v)}
                                            format={timeFormat}
                                        />

                                        {ticket.ticket.outReroute ? <Tag color="orange">* REROUTED FERRY</Tag> : <></>}
                                    </Space>

                                    {ticket.ticket.returnTicket ? (
                                        <Space
                                            style={{
                                                width: "100%",
                                                padding: "15px 0"
                                            }}
                                            direction="vertical"
                                        >
                                            <Typography.Title level={5}>Return Time</Typography.Title>
                                            <TimePicker
                                                defaultValue={moment(
                                                    TimeUtils.getTimePickerTime(singleTicket.ticket.returnTime),
                                                    timeFormat
                                                )}
                                                onChange={(v) => changeTime("returnTime", v)}
                                                format={timeFormat}
                                            />

                                            {ticket.ticket.returnReroute ? <Tag color="orange">* REROUTED FERRY</Tag> : <></>}
                                        </Space>
                                    ) : (
                                        <></>
                                    )}
                                </Space>
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>

                    <Row type="flex" style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {validateTicket(ticket) ? (
                                <Divider orientation="right">
                                    <Popconfirm
                                        title="Are you sure you want to update? This will affect all future tickets only."
                                        okText="Update"
                                        // onConfirm={() => deleteTicket(ticket._id)}
                                        onConfirm={saveTicketToServer}
                                        onCancel={Constants.cancel}
                                        cancelText="No"
                                    >
                                        <Button icon={<SaveOutlined />} type="primary">
                                            Update Ticket
                                        </Button>
                                    </Popconfirm>
                                </Divider>
                            ) : (
                                <></>
                            )}

                            <Typography.Title level={4}>Actions</Typography.Title>

                            <Space style={{ width: "100%" }} size={"large"} direction="vertical">
                                <Button type="outline" onClick={() => viewAllTickets(ticket._id)}>
                                    View All Tickets
                                </Button>
                                {/* <Popconfirm
                                    title="Are you sure archive this ticket, this will not archive all future tickets?"
                                    okText="Yes"
                                    onConfirm={() => archiveTicket(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        icon={<DownSquareFilled />}
                                    >
                                        Archive Recurring Ticket
                                    </Button>
                                </Popconfirm> */}
                                {/* DELETE */}
                                <Popconfirm
                                    title="Are you sure delete this ticket, this will delete all future tickets?"
                                    okText="Yes"
                                    onConfirm={() => deleteTicket(ticket._id)}
                                    onCancel={Constants.cancel}
                                    cancelText="No"
                                >
                                    <Button type="outline" danger icon={<CloseCircleOutlined twoToneColor="#ff4d4f" />}>
                                        Delete Recurring Ticket
                                    </Button>
                                </Popconfirm>
                            </Space>

                            <br />
                        </Space>
                    </Row>

                    <Descriptions title="Server Side" layout="vertical" bordered>
                        {/* ROW */}
                        <Descriptions.Item label="Date Bought" span={2}>
                            {Constants.getShortDateString(singleTicket.datecreated)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Method" span={1}>
                            {Constants.getMethodIcon(singleTicket.method)}
                        </Descriptions.Item>
                        {/* ROW */}
                        <Descriptions.Item label="DB Info" span={3}>
                            Recurring Ticket ID: {singleTicket._id}
                            <br />
                            User ID: {singleTicket.userid}
                            {/* Date Created: {Constants.getDateString(singleTicket.datecreated)} */}
                            <br />
                        </Descriptions.Item>
                    </Descriptions>
                </Space>
            ) : (
                <Col xs={18} md={8} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <Spin />
                    </div>
                </Col>
            )}
        </Drawer>
    );
}

const validateTicket = function (ticket) {
    // let ticket = ticket
    let error = null;
    // JOURNEY
    if (ticket.ticket.returnTicket !== true || ticket.ticket.returnTicket !== false) {
        error = "Ticket Type is wrong";
    }
    // ORIGIN
    if (ticket.ticket.outJourney !== "dm" || ticket.ticket.outJourney !== "dt") {
        error = "Journeys are wrong";
    }
    // TIMES
    if (Array.isArray(ticket.ticket.outTime) || Array.isArray(ticket.ticket.returnTime)) {
        error = "Times aren't selected";
    }
    if (error !== null) {
        return error;
    } else {
        return true;
    }
    // return true;
};
