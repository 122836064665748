import React from "react";
import { Space, Typography } from "antd";
import { Input } from "antd";
export function TicketFilterStripePayment(props) {
    const { label, filterStripePayment, setFilterStripePayment } = props;

    return (
        <Space>
            <Typography.Text style={{ fontSize: "0.8em" }}>{`Payment ID`.toUpperCase()}:</Typography.Text>
            <Input value={filterStripePayment} onChange={(v) => setFilterStripePayment(v.target.value)} placeholder={`pi_xxxxx`} />
        </Space>
    );
}
