import React, {
	useState
} from 'react';

import {
	Layout,
	Row,
	Col,
	Card,
	Button,
	Spin,
	Typography,
	Space,
	Input,
	Form
} from 'antd';

import {
	MailOutlined
} from '@ant-design/icons';

import {
	GoogleOutlined,
	// MailOutlined
} from '@ant-design/icons';
import { signInWithEmail, signInWithGoogle } from '../services/firebase';
// import { UserContext } from '../provider/UserProvider';

const { Content } = Layout;

function SignIn() {

	const [isLoading, setIsLoading] = useState(false);
	const [username, setUsername] = useState("thearranmoreferry@hexastudios.co");
	const [password, setPassword] = useState("")
	// console.log(localStorage.getItem("loggedIn"));
	const [showG, setShowG] = useState(false)

	function login() {
		setIsLoading(true);
		signInWithGoogle()
	}

	function cancelLogin() {
		localStorage.setItem("loggedIn", "false");
	}

	return (
		localStorage.getItem("loggedIn") === "true" ? <Row justify="center" >
			<Col xs={18} md={8} style={{ textAlign: 'center' }}>
				<Space direction="vertical">

					<div style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "80px" }}>
						<img
							alt="Arramore Ferry Logo"
							src="144.png"
							style={{ width: "100px" }} />
					</div>

					<div style={{ textAlign: 'center', paddingTop: "10px" }}>
						<Spin />
						<br />
						<Button type="link" onClick={() => {
							cancelLogin();
							window.location.reload()
						}}>Cancel</Button>
					</div>

				</Space>
			</Col>
		</Row> :
			<Layout id={"vanta-bg"} style={{
				minHeight: "100vh",
				backgroundColor: "#f0f0f0"
			}}>
				<Content>
					<Row justify="center" >
						<Col xs={18} md={8} style={{ textAlign: 'center' }}>
							<div style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "80px" }}>
								<img
									alt="Arranmore Ferry Logo"
									src="144.png"
									style={{ width: "100px" }} />
							</div>
							<Card
								actions={[
									// <Button onClick={() => signInWithGoogle()} type="outlined" icon={<MailOutlined />}>Email Sign in</Button>,
									isLoading ? <Spin /> :
										<Space direction="horizontal">
											<Button icon={<MailOutlined />} type="primary" onClick={() => signInWithEmail(username, password)}>
												Sign In
											</Button>
											{showG
												? <Button danger onClick={() => login()} type="primary" icon={<GoogleOutlined />}>Google Sign in</Button>
												: <></>}
										</Space>
								]}>
								<Typography.Title level={4}>Arranmore Ferry Admin</Typography.Title>

								<Typography.Text>Log In</Typography.Text>

								<Form layout="vertical">
									<Form.Item label="Email" name="email">
										<Input type="email" name="email" placeholder="arranmoreferry@hexastudios.co" onChange={(v) => setUsername(v.target.value)} />
									</Form.Item>
									<Form.Item label="Password">
										<Input placeholder="password" name="password" type="password" onChange={(v) => setPassword(v.target.value)} />
									</Form.Item>
								</Form>

							</Card>

						</Col>
					</Row>
					<Row justify="center" >
						<Col xs={18} md={8} style={{ textAlign: 'center' }} onDoubleClick={() => setShowG(!showG)}>
							<div style={{ textAlign: 'center', paddingTop: "20px", paddingBottom: "10px", color: "#8c8c8c" }}>{`Arranmore Ferry © ${new Date().getFullYear()}`}</div>
						</Col>
					</Row>
				</Content>
			</Layout>
	);
}

export default SignIn