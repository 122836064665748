import React, {
	useState,
	useEffect,
} from 'react';

import {
	PageHeader,
	Row,
	Col,
	Card,
	Spin,
	Typography,
	Divider,
	Button,
	message
} from 'antd';

import {
	PlusCircleOutlined,
} from '@ant-design/icons';

import { EditDefinitionTable } from '../components/definitions/EditDefinitionTable';
import { NewDefinition } from '../components/definitions/NewDefinition';
import { DefinitionTable } from '../components/definitions/DefinitionTable';
import LogoutButton from '../components/LogoutButton';
import * as ToryService from '../services/ToryService';
// import * as Constants from '../utils/Constants';

// const { Meta } = Card;

export default function Definitions(props) {

	// constructor(props) {
	// 	super(props);
	// 	state = {
	// 		definitions: null,
	// 		selected: null,
	// 		new: null
	// 	};
	// }
	const [definitions, setDefinitions] = useState(null);
	const [selected, setSelected] = useState(null);
	const [newDef, setNewdef] = useState(null)

	useEffect(() => {
		loadDefinitions()
	}, [])


	// componentDidMount() {
	// 	loadDefinitions()
	// }

	async function loadDefinitions() {
		let response = await ToryService.getDefinitions();
		// console.log(response);
		// setState({
		// 	definitions: response.definitions
		// })
		setDefinitions(response.definitions);
	}

	function createNew() {
		setNewdef(true);
	}

	async function saveNewDefinition(monthDef) {
		// console.log(monthDef);
		// console.log(monthDef);
		if (monthDef.m.length !== 0 && monthDef.d.length !== 0) {
			let result = await ToryService.saveNewDefinition(monthDef);
			if (result.status === 200) {
				message.success("New Definition Saved")
				loadDefinitions();
			} else {
				message.error("New Definition Failed");
			}
		} else {
			message.info("You need to add dates")
		}
	}

	async function removeDefinition(id) {
		// console.log(id);
		let result = await ToryService.removeDefinition(id);
		if (result.status === 200) {
			message.success("Definition Removed")
			loadDefinitions();
			setSelected(null);
		} else {
			message.error("Definition Failed");
		}
	}

	async function prioritize(id, prioritise) {
		// console.log(id)
		const defs = definitions;
		const selected = defs.filter((d) => d._id === id);
		if (selected[0].order !== 0 || (selected[0].order === 0 && !prioritise)) {
			const _newOrder = selected[0].order + (prioritise ? -1 : 1);
			// console.log(_newOrder);
			let result = await ToryService.prioritiseDefinition(id, _newOrder);
			// console.log(result);
			if (result.status === 200) {
				message.success("Updated Order");
				loadDefinitions();
			} else {
				message.error("Couldn't Change Order");
			}
		} else {
			message.error("Already highest priority");
		}
	}

	return (<>
		<PageHeader
			ghost={false}
			title="Definitions"
			extra={
				<LogoutButton />
			}>
		</PageHeader>

		<div style={{ margin: '8px 16px 0' }}>
			<Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
				<Col className="gutter-row" xs={24} md={12} >
					<Card title="Definitions" bordered={false}>

						<Typography.Text>These are the definitions for how a months and week is broken down.</Typography.Text>

						<Divider />

						{definitions
							? definitions.map((def) => {
								return <DefinitionTable key={def._id} monthly={def} editMonth={(m) => setSelected(m)} upOrder={(v) => prioritize(v, true)} downOrder={(v) => prioritize(v, false)} />
							})
							: <Row justify="left"><Spin /></Row>}

					</Card>
				</Col>
				<Col className="gutter-row" xs={24} md={12} >
					<Card title="Edit Definitions" bordered={false}>
						{selected
							? <EditDefinitionTable definitions={definitions} monthly={selected} remove={(v) => removeDefinition(v)} cancelSelection={() => setSelected(null)} />
							: <>
								{newDef ? <NewDefinition saveNew={(v) => saveNewDefinition(v)} />
									: <>
										Edit a Definition or Add New
										<Divider orientation="right">
											<Button type="primary" onClick={() => createNew()} icon={<PlusCircleOutlined />}>Add New Definition</Button>
										</Divider>
									</>
								}
							</>
						}
					</Card>
				</Col>
			</Row>
		</div>

	</>)
}
