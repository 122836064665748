import { Typography, Space, Row, Col, Statistic, Card } from "antd";
import React from "react";
import * as Constants from "../../utils/Constants";
import Chart from "react-apexcharts";
// import {
//     carsPerFerry,
//     generateFerryArray,
//     passengerPerFerry
// } from "../../utils/StatisticUtils";
// import { TicketIcon } from "../../assets/TicketIcon";
// import * as CarUtils from "../../utils/CarUtils";
import * as StatsUtils from "../../utils/StatisticUtils";
// import * as PassengerUtils from "../../utils/PassengerUtils";

import {
    // CarOutlined,
    // UserOutlined,
    // PhoneOutlined,
    CrownOutlined,
    MobileOutlined,
    DesktopOutlined
} from "@ant-design/icons";
import { format } from "date-fns";

export default function TotalTicketTypeChart(props) {
    const { data, date, journey } = props;

    let chartData = {
        options: {
            chart: {
                id: "Passenger & Car Count",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: data.map((f) => Constants.getTimestamp(f.time))
            }
        },
        series: [
            {
                name: "Admin",
                data: data.map((f) => StatsUtils.TicketTypeTotal(f.tickets, "admin"))
            },
            {
                name: "Web",
                data: data.map((f) => StatsUtils.TicketTypeTotal(f.tickets, "web"))
            },
            {
                name: "App",
                data: data.map((f) => StatsUtils.TicketTypeTotal(f.tickets, "app"))
            }
        ]
    };

    return (
        <Card
            title={`Ticket Method Breakdown for ${format(date, "do MMM yyyy")} ${
                journey === "all" ? "" : Constants.getJourneyString(journey, false)
            }`}
            bordered={false}
            actions={[
                <Statistic
                    title={"Total Admin"}
                    value={`${data
                        .map((f) => StatsUtils.TicketTypeTotal(f.tickets, "admin"))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                    prefix={<CrownOutlined />}
                />,
                <Statistic
                    title={"Total Web"}
                    value={data
                        .map((f) => StatsUtils.TicketTypeTotal(f.tickets, "web"))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}
                    prefix={<DesktopOutlined />}
                />,
                <Statistic
                    title={"Total App"}
                    value={data
                        .map((f) => StatsUtils.TicketTypeTotal(f.tickets, "app"))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}
                    prefix={<MobileOutlined />}
                />
            ]}
        >
            <Row gutter={[16, { sm: 16, md: 24 }]} style={{ width: "100%" }}>
                <Col span={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Typography.Title level={4}></Typography.Title>
                            <Chart
                                options={chartData.options}
                                // toolbar={false}
                                series={chartData.series}
                                type="bar"
                                width="100%"
                                height="300px"
                            />
                        </Space>
                        {/* <Typography.Text>{data.map((d) => d.time)}</Typography.Text> */}
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}
