import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getAvailsForDate(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const data = { journey, date, time };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.dateAvailability}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getManifestForTime(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const data = { journey, date, time };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.dateManifest}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
