import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getMessages() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(`${Constants.getApiUrl()}${Constants.v2messages}`, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function postNewMessage(message, url, color, date, time) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.v2messages}`,
            { message, url, color, date, time },
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
