import React from "react";
// CSS
import "../../pages/css/Timetables.css";

import {
  Space,
  Typography,
} from "antd";
import { DayLabels } from "./DayLabels";
import { DeleteButtons } from "./DeleteButtons";

export function LabelDeleteButtons(props) {
  const { definitions, journey, times, showDrawer, updateFerry, loadTables } = props;
  return definitions.dayDefinitions.map((i, index) => {
    return (
      <Space
        key={index}
        direction="vertical"
        size="small"
        style={{ paddingBottom: "10px", maxWidth: "100%", width: "100%" }}
      >
        <Typography.Text level={5}>
          <DayLabels dayArray={i} only={definitions.onlyDefinitions} />
        </Typography.Text>
        <DeleteButtons
          journey={journey}
          months={definitions.monthDefinitions}
          only={definitions.onlyDefinitions} 
          days={i}
          times={times}
          showDrawer={showDrawer}
          updateFerry={updateFerry}
          loadTables={loadTables}
        />
      </Space>
    );
  });
};
