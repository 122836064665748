import { Space, Row, Col, Button } from "antd";
import React, { useEffect, useState } from "react";
import * as StatsService from "../../services/StatsService";
import { TableOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { message } from "antd";
import { Descriptions } from "antd";
import { RevenueTable } from "./RevenueTable";
//
export function RevenueCounter(props) {
    // const { date } = props;

    const [date, setDate] = useState(new Date());
    const [revenueDate, setRevenueData] = useState([]);
    const [toggleTable, setToggleTable] = useState(false);

    useEffect(() => {
        CollectRevenueStats();
    }, [date]);

    async function CollectRevenueStats() {
        // console.log(date);
        let { error, data } = await StatsService.getRevenueData(date);
        // console.log(response);
        if (error === 0) {
            setRevenueData(data);
        } else {
            message.error("Failed to collect revenue data");
        }
    }

    function changeDate(date) {
        // console.log(date);
        setDate(date._d);
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Card
                        title={`Revenue Totals`}
                        bordered={false}
                        extra={[
                            <Space key="dp">
                                <Button icon={<TableOutlined />} onClick={() => setToggleTable(!toggleTable)}>
                                    Show Table
                                </Button>
                                <DatePicker key="dp" value={new moment(date)} onChange={(v) => changeDate(v)} />
                            </Space>
                        ]}
                    >
                        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                            <Row gutter={[16, { sm: 16, md: 24 }]} style={{ width: "100%" }}>
                                <Col span={24}>
                                    <Space direction="vertical" style={{ width: "100%" }}>
                                        {/* <Typography.Text>{`${JSON.stringify(revenueDate)}`}</Typography.Text> */}
                                        <Descriptions bordered size="small">
                                            {revenueDate.map((rd, index) => (
                                                <Descriptions.Item key={`${index}`} label={`${rd.type}`.toUpperCase()} span={3}>{`€ ${(
                                                    rd.revenue / 100
                                                ).toFixed(2)}`}</Descriptions.Item>
                                            ))}
                                        </Descriptions>
                                    </Space>
                                </Col>
                            </Row>
                            {toggleTable ? <RevenueTable date={date} /> : <></>}
                        </Space>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}></Col>
            </Row>
        </>
    );
}
