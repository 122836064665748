// import React, { useEffect, useState } from "react";

// import {
//     PageHeader,
//     Row,
//     Col,
//     Space,
//     Spin,
//     // Button,
//     // Divider,
//     Alert
// } from "antd";
// import PricesTable from "../components/prices/PricesTables";
// import LogoutButton from "../components/LogoutButton";
// import NewPricesPanel from "../components/prices/NewPricesPanel";
// import ViewPricesPanel from "../components/prices/ViewPricesPanel";
// import EditPricesPanel from "../components/prices/EditPricesPanel";
// import * as PriceService from "../services/PriceService";
// // import { PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";

// export default function Prices(props) {
//     const [prices, setPrices] = useState([]);
//     const [newPrice, setNewPrice] = useState(false);
//     const [viewPrice, setViewPrice] = useState(null);
//     const [editPrice, setEditPrice] = useState(null);
//     const [width] = useState(window.innerWidth);
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         loadPrices();
//     }, []);

//     async function loadPrices() {
//         setLoading(true);
//         let response = await PriceService.getPrices();
//         // console.log(response);
//         setPrices(response);
//         setLoading(false);
//     }

//     function closeDrawer() {
//         loadPrices();
//         setNewPrice(false);
//         setViewPrice(null);
//         setEditPrice(null);
//     }

//     return (
//         <>
//             <PageHeader
//                 ghost={false}
//                 title="Prices"
//                 extra={<LogoutButton />}
//             ></PageHeader>

//             <div style={{ margin: "8px 16px 10px", maxWidth: "100%" }}>
//                 <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
//                     <Col className="gutter-row" xs={24}>
//                         <Alert
//                             message="Prices are static for now, please ignore this page"
//                             type="warning"
//                         />

//                         {/* <Divider orientation="right">
//                             <Space
//                                 direction={["vertical", "horizontal"]}
//                                 align="end"
//                                 justify="end"
//                             >
//                                 <Button
//                                     onClick={() => loadPrices()}
//                                     loading={loading}
//                                     icon={<SyncOutlined />}
//                                 />
//                                 <Button
//                                     type="primary"
//                                     onClick={() => setNewPrice(true)}
//                                     icon={<PlusCircleOutlined />}
//                                 >
//                                     Add New Price
//                                 </Button>
//                             </Space>
//                         </Divider> */}

//                         <Space
//                             direction="vertical"
//                             size="middle"
//                             style={{ maxWidth: "100%", width: "100%" }}
//                         >
//                             {loading ? (
//                                 <Row justify="center">
//                                     {" "}
//                                     <Spin />
//                                 </Row>
//                             ) : (
//                                 <PricesTable
//                                     data={prices}
//                                     viewPrice={setViewPrice}
//                                     editPrice={setEditPrice}
//                                     reload={loadPrices}
//                                 />
//                             )}
//                         </Space>
//                     </Col>
//                 </Row>
//             </div>
//             <NewPricesPanel
//                 width={width > 900 ? 640 : width}
//                 visible={newPrice}
//                 onClose={() => closeDrawer()}
//             />
//             <ViewPricesPanel
//                 width={width > 900 ? 640 : width}
//                 visible={viewPrice}
//                 onClose={() => closeDrawer()}
//             />
//             <EditPricesPanel
//                 width={width > 900 ? 640 : width}
//                 visible={editPrice}
//                 onClose={() => closeDrawer()}
//             />
//         </>
//     );
// }

import React, { useEffect, useState } from "react";

import {
  PageHeader,
  Row,
  Col,
  Space,
  Spin,
  Divider,
  Button,
} from "antd";
import PricesTable from "../components/prices/PricesTables";
import LogoutButton from "../components/LogoutButton";
import NewPricesPanel from "../components/prices/NewPricesPanel";
import ViewPricesPanel from "../components/prices/ViewPricesPanel";
import EditPricesPanel from "../components/prices/EditPricesPanel";
import * as PriceService from "../services/PriceService";
import { PlusCircleOutlined } from "@ant-design/icons";
export default function Prices(props) {
  const [prices, setPrices] = useState([]);
  const [newPrice, setNewPrice] = useState(false);
  const [viewPrice, setViewPrice] = useState(null);
  const [editPrice, setEditPrice] = useState(null);
  const [width] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPrices();
  }, []);

  async function loadPrices() {
    setLoading(true);
    let response = await PriceService.getPrices();
    // console.log(response);
    if (response) {
      setPrices(response);
    }
    setLoading(false);
  }

  function closeDrawer() {
    loadPrices();
    setNewPrice(false);
    setViewPrice(null);
    setEditPrice(null);
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Prices"
        extra={<LogoutButton />}
      ></PageHeader>

      <div style={{ margin: "8px 16px 10px", maxWidth: "100%" }}>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
          <Col className="gutter-row" xs={24}>
            {/* <Alert
              message="Prices are static for now, please ignore this page"
              type="warning"
            /> */}

            <Row style={{ marginTop: "8px" }} gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
              <Col xs={24}>
                <Divider
                  orientation="right"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={() => setNewPrice(true)}
                    type="primary"
                  >
                    New Price
                  </Button>
                </Divider>
              </Col>
            </Row>

            <Space
              direction="vertical"
              size="middle"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              {loading ? (
                <Row justify="center">
                  <Spin />
                </Row>
              ) : (
                <PricesTable
                  data={prices ?? []}
                  viewPrice={setViewPrice}
                  editPrice={setEditPrice}
                  reload={loadPrices}
                />
              )}
            </Space>
          </Col>
        </Row>
      </div>
      <NewPricesPanel
        width={width > 900 ? 640 : width}
        visible={newPrice}
        onClose={() => closeDrawer()}
      />
      <ViewPricesPanel
        width={width > 900 ? 640 : width}
        visible={viewPrice}
        onClose={() => closeDrawer()}
      />
      <EditPricesPanel
        width={width > 900 ? 640 : width}
        visible={editPrice}
        onClose={() => closeDrawer()}
      />
    </>
  );
}
