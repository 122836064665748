import React from "react";
import {
    Tag,
    Tooltip
    // Typography,
    // Space
} from "antd";
import { LockOutlined } from "@ant-design/icons";

export default function FerryTypeLabel(props) {
    const { hideScheduled, type, locked } = props;

    if (type === "locked" || type === "lock" || locked === true) {
        return (
            <Tooltip title="Ferry is locked for bookings">
                <Tag style={{ textTransform: "uppercase", fontSize: "0.6em" }} color={"orange"}>
                    <LockOutlined />
                    {` LOCKED`}
                </Tag>
            </Tooltip>
        );
    }

    if ((type === "master" || type === null || type === undefined) && hideScheduled) {
        return <></>;
    }

    return (
        <Tooltip title={`Ferry is ${type === null || type === undefined ? "scheduled" : type.toLowerCase()}`}>
            <Tag style={{ textTransform: "uppercase", fontSize: "0.6em" }} color={getLabelColour(type)}>
                {type === null || type === undefined || type === "master" ? "SCHEDULED" : type.toUpperCase()}
            </Tag>{" "}
        </Tooltip>
    );
}

function getLabelColour(type) {
    if (type === null || type === undefined) {
        return "blue";
    } else {
        switch (type.toLowerCase()) {
            case "cancelled":
                return "red";
            case "cancel":
                return "red";
            case "rerouted":
                return "orange";
            case "lock":
                return "yellow";
            case "locked":
                return "yellow";
            case "master":
                return "blue";
            default:
                return "green";
        }
    }
}
