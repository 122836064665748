import React, { useState, useRef } from "react";
import { Space, Card, Row, Col, Typography, Button } from "antd";
import { Drawer } from "antd";

// import { usb, getDeviceList } from "usb";

import ReactToPrint from "react-to-print";

export function PrinterSettings() {
    const componentRef = useRef();

    const [error, setError] = useState(0);

    const [openTestDrawer, setOpenTestDrawer] = useState(false);

    const showDrawer = () => {
        setOpenTestDrawer(true);
    };
    const onClose = () => {
        setOpenTestDrawer(false);
    };

    return (
        <Card title="Printer Settings" bordered={false}>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Typography.Text>
                    The label printer software needs to be installed on the computer and a JS plugin is required before this will work.
                </Typography.Text>
                {/* {error === 1 ? (
                    <Typography.Text>
                        We can't find the printer installed, please make sure it's plugged in, turned on. If that failed, please try
                        clicking the printer setup icon on the desktop.
                    </Typography.Text>
                ) : (
                    <></>
                )} */}
                <Button onClick={() => showDrawer()}>Print Test Label</Button>
            </Space>
            <Drawer title="Print Label" placement="right" onClose={onClose} open={openTestDrawer}>
                <ComponentToPrint ref={componentRef} />
                <ReactToPrint trigger={() => <Button type="primary">Print Label</Button>} content={() => componentRef.current} />
            </Drawer>
        </Card>
    );
}

export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <Row gutter={[16, 16]} style={{ padding: "20px" }}>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text>Passenger</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>Matthew Testing</Typography.Text>
                        <Typography.Text>Car Details</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>Yaris 08DL221</Typography.Text>
                        <Typography.Text>OUT</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>Departing Burtonport</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>11.45 AM</Typography.Text>
                        <Typography.Text>RETURN</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>Departing Arranmore</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>1.45 PM</Typography.Text>
                        <Typography.Text>PASSENGERS</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>1x CAR + DRIVER</Typography.Text>
                        <Typography.Text>STATUS</Typography.Text>
                        <Typography.Text style={{ fontSize: "20px" }}>PAID</Typography.Text>
                    </Space>
                </Col>
            </Row>
        </div>
    );
});
