import React, { useState, useEffect } from "react";

import {
    Divider,
    // Tabs,
    PageHeader,
    Button,
    Row,
    Col,
    Space,
    // Radio,
    Popconfirm,
    Card,
    Table,
    DatePicker,
    message,
    Spin,
    Typography
} from "antd";
import LogoutButton from "../components/LogoutButton";

import moment from "moment";

import {
    UnlockOutlined,
    UpSquareOutlined,
    DownSquareOutlined
} from "@ant-design/icons";

import * as ToryService from "../services/ToryService";
import * as LockService from "../services/LockService";
import * as Constants from "../utils/Constants";
import { JourneyPicker } from "../components/common/JourneyPicker";
// const { TabPane } = Tabs;
import CancelButtons from "../components/cancel/CancelButtons";
// const format = 'HH:mm';

export default function Lock() {
    const [journey, setJourney] = useState("dm");
    const [chosenDate, setChosenDate] = useState(new Date());
    const [ferries, setFerries] = useState(0);
    const [times, setTimes] = useState([]);
    // const [conflictTickets, setConflictTickets] = useState([]);
    // const [confirmTime, setConfirmTime] = useState(null);
    const [showArchive, setShowArchive] = useState(false);
    const [archiveFerries, setArchiveFerries] = useState({ dt: [], dm: [] });

    function resetForm() {
        setJourney("dm");
        setChosenDate(new Date());
        setFerries({ dt: [], dm: [] });
        setTimes([]);
        // setConflictTickets([])
        // setConfirmTime(null)
    }

    async function loadTables() {
        let response = await LockService.getLock();
        // console.log(response);
        setFerries(response);
    }

    async function toggleArchive() {
        // DO POST
        let archiveFerries = await LockService.getLockArchive();

        setShowArchive(!showArchive);
        setArchiveFerries(archiveFerries);
    }

    async function cancelFerry(time) {
        // console.log(time);
        // console.log(journey);
        // console.log(chosenDate);
        let result = await LockService.lockFerry(journey, chosenDate, time);
        // console.log(result);
        if (result.status === 200) {
            message.success("Ferry Locked");
            resetForm();
            loadTables();
        } else if (result.status === "CONFLICTS") {
            message.info("Please confirm");
            // CONFLICTS
            // CONFIRM TIME
            // setConfirmTime(time);
            // setConflictTickets(result.tickets)
        } else {
            message.error("Couldn't Lock Ferry");
        }
    }

    async function undelete(id) {
        console.log(id);
        let result = await LockService.unLockFerry(id);
        if (result === 200) {
            message.success("Ferry Unlocked");
            resetForm();
            loadTables();
        } else {
            message.error("Couldn't unlock Ferry");
        }
    }

    useEffect(() => {
        // LOAD TABLES
        loadTables();
        // console.log("[] effect run");
    }, []);

    useEffect(() => {
        // console.log("useEffect only for time");
        loadTimes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenDate, journey]);

    function changeJourney(journey) {
        setJourney(journey);
        // loadTimes()
    }
    function changeDate(date, dateString) {
        if (date !== null) {
            // console.log("non null date");
            // console.log(Constants.getEpochDate(date._d));
            setChosenDate(Constants.getEpochDate(date._d));
            // loadTimes();
        } else {
            setChosenDate(new Date());
        }
    }

    async function loadTimes() {
        // console.log(chosenDate)
        if (chosenDate !== 0) {
            // console.log(journey);
            // console.log(chosenDate)
            let result = await ToryService.getDateTimes(journey, chosenDate);
            // console.log(result);
            setTimes(Constants.getOneDayTimesArray(result));
        } else {
            setTimes([]);
        }
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Lock"
                extra={<LogoutButton />}
            ></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Card title="Lock Ferry" bordered={false}>
                            <Space
                                direction="vertical"
                                style={{ maxWidth: "100%", width: "100%" }}
                            >
                                <Typography.Title level={5}>
                                    Journey
                                </Typography.Title>

                                <JourneyPicker
                                    journey={journey}
                                    onJourneyChange={(e) =>
                                        changeJourney(e.target.value)
                                    }
                                />

                                <Typography.Title level={5}>
                                    Date
                                </Typography.Title>

                                <DatePicker
                                    value={new moment(chosenDate)}
                                    onChange={changeDate}
                                />

                                <Typography.Title level={5}>
                                    Times
                                </Typography.Title>
                                {times.length === 0 ? (
                                    <p>No Times Available</p>
                                ) : (
                                    <CancelButtons
                                        lock={true}
                                        cancelFerry={cancelFerry}
                                        ferries={times}
                                    />
                                )}
                            </Space>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Card title="Status" bordered={false}>
                            {ferries === 0 ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : (
                                <CancelTables
                                    ferries={ferries}
                                    undelete={undelete}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            <div style={{ margin: "24px 16px 0" }}>
                <Divider orientation="left">
                    <Button
                        icon={
                            showArchive ? (
                                <UpSquareOutlined />
                            ) : (
                                <DownSquareOutlined />
                            )
                        }
                        onClick={toggleArchive}
                        type="outline"
                        color="secondary"
                    >
                        {showArchive ? "Hide" : "Show"} Archive{" "}
                    </Button>
                </Divider>
                {showArchive ? (
                    <ArchiveTable archiveFerries={archiveFerries} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

// export default Cancel;

const CancelTables = (props) => {
    return (
        <Space size="small" direction="vertical" style={{ width: "100%" }}>
            <CancelTable
                journey="dm"
                ferries={props.ferries.dm}
                undelete={props.undelete}
            />
            <CancelTable
                journey="dt"
                ferries={props.ferries.dt}
                undelete={props.undelete}
            />
        </Space>
    );
};

const CancelTable = (props) => {
    let data = [];

    props.ferries.map((i, index) => {
        let j = i;
        j.key = i.id;
        return data.push(j);
    });

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => <span>{Constants.getDateString(value)}</span>
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (value) => <span>{Constants.getTimestamp(value)}</span>
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (value) => (
                <>
                    <Popconfirm
                        title="Are you sure remove this ferry lock?"
                        okText="Yes"
                        onConfirm={() => props.undelete(value)}
                        onCancel={Constants.cancel}
                        cancelText="No"
                    >
                        <Button size="small" type="default">
                            {" "}
                            Unlock <UnlockOutlined />
                        </Button>
                    </Popconfirm>
                </>
            )
        }
    ];

    const journey = Constants.getJourneyString(props.journey);

    return (
        <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
            <Typography.Title level={5}>{journey}</Typography.Title>
            <Table
                size="small"
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </Space>
    );
};

const ArchiveTable = (props) => {
    // console.log(props.archiveFerries);

    let archiveData = [];

    props.archiveFerries.dm.map((i, index) => {
        let j = i;
        j.key = i.id;
        j.journey = "dm";
        return archiveData.push(j);
    });
    props.archiveFerries.dt.map((i, index) => {
        let j = i;
        j.journey = "dt";
        j.key = i.id;
        return archiveData.push(j);
    });

    archiveData.sort((a, b) => {
        return a.time - b.time;
    });

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (value) => (
                <span>{Constants.getIDString(value).toUpperCase()}</span>
            )
        },
        {
            title: "Journey",
            dataIndex: "journey",
            key: "journey",
            render: (value) => <span>{Constants.getJourneyString(value)}</span>
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => <span>{Constants.getDateString(value)}</span>
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (value) => <span>{Constants.getTimestamp(value)}</span>
        }
    ];

    return (
        <>
            <Table
                dataSource={archiveData}
                columns={columns}
                pagination={true}
            />
        </>
    );
};
