// TIME HELPERS

export function getPrettyTimeAMPM(inputTime) {
    const hours = Math.floor(inputTime / 100);
    const minutes = inputTime - hours * 100;
    return `${String(hours > 12 ? hours - 12 : hours)}:${String(minutes).padStart(2, "0")}${String(hours >= 12 ? "pm" : "am")}`;
}

export function getDateFromEpoch(epoch) {
    const newDate = new Date(0);
    newDate.setUTCMilliseconds(epoch);
    return newDate;
}

export function getEpochDate(dateTime) {
    let formattedDate = new Date(dateTime);
    formattedDate.setHours(12);
    formattedDate.setMinutes(0);
    return formattedDate.getTime();
}

export function getAccurateTime() {
    const utcdate = new Date();
    const localTime = utcdate.getTime();
    const localOffset = utcdate.getTimezoneOffset() * 60000;
    let utcTime = localTime + localOffset;
    utcTime = returnTrueTime(utcTime);
    return utcTime;
}

export function getTimePickerTime(num) {
    // console.log(num)
    let workingNumber = num;
    let timestamp = 0;
    if (workingNumber === "-") {
        timestamp = workingNumber;
    } else if (num < 1200) {
        if (num < 10) {
            workingNumber = "000" + num;
        } else if (num < 100) {
            workingNumber = "00" + num;
        } else if (num < 1000) {
            workingNumber = "0" + num;
        } else {
            workingNumber = "" + num + "";
        }
        timestamp = [workingNumber.slice(0, 2), ":", workingNumber.slice(2)].join("");
        // if (ampm === true) {
        //   timestamp = timestamp + "am"
        // }
    } else {
        // if (num > 1259 && ampm === true) {
        //   num = num - 1200
        // }
        if (num < 1000) {
            workingNumber = "0" + num + "";
        } else {
            workingNumber = "" + num + "";
        }
        timestamp = [workingNumber.slice(0, 2), ":", workingNumber.slice(2)].join("");
        // if (ampm === true) {
        //   timestamp = timestamp + "pm"
        // }
    }
    //   if (timestamp.substring(0, 1) === "0" ) {
    //     timestamp = timestamp.substring(1)
    //   }
    return timestamp;
}

export function getCombinedTimestamp(date, time) {
    // console.log(date);
    // console.log(time);
    // console.log(getAccurateTime());
    // console.log(`${Math.floor(time / 100)}, ${time - (Math.floor(time / 100) * 100)}`)
    return date.setHours(Math.floor(time / 100), time - Math.floor(time / 100) * 100);
}

export function getStartOfDate(date) {
    const newDate = new Date(date);
    return newDate.setHours(0, 1, 0, 0);
}
export function getEndOfDate(date) {
    const newDate = new Date(date);
    return newDate.setHours(23, 59, 0, 0);
}

// function getNumericalTime(date) {
//     const rawDate = new Date(date);

//     const hours = lessThanTen(rawDate.getHours());
//     const minutes = lessThanTen(rawDate.getMinutes());

//     const time = "" + hours + minutes;
//     return time;
// }

function returnTrueTime() {
    /* Return a Date for the last Sunday in a month
     ** @param {number} year - full year number (e.g. 2015)
     ** @param {number} month - calendar month number (jan=1)
     ** @returns {Date} date for last Sunday in given month
     */
    function getLastSunday(year, month) {
        // Create date for last day in month
        var d = new Date(year, month, 0);
        // Adjust to previous Sunday
        d.setDate(d.getDate() - d.getDay());
        return d;
    }

    // FORMAT INTO PARSABLE STRING
    function formatDate(d, offset) {
        function z(n) {
            return ("0" + n).slice(-2);
        }
        // Default offset to 0
        offset = offset || 0;
        // Generate offset string
        // var offSign = offset < 0 ? '-' : '+';
        offset = Math.abs(offset);
        // var offString = offSign + ('0' + (offset / 60 | 0)).slice(-2) + ':' + ('0' + (offset % 60)).slice(-2);
        // Generate date string
        return (
            "" +
            d.getUTCFullYear() +
            "/" +
            z(d.getUTCMonth() + 1) +
            "/" +
            z(d.getUTCDate()) +
            " " +
            z(d.getUTCHours()) +
            ":" +
            z(d.getUTCMinutes()) +
            ":" +
            z(d.getUTCSeconds()) +
            ""
        );
    }

    /* Return Date object for current time in London. Assumes
     ** daylight saving starts at 01:00 UTC on last Sunday in March
     ** and ends at 01:00 UTC on the last Sunday in October.
     ** @param {Date} d - date to test. Default to current
     **                   system date and time
     ** @param {boolean, optional} obj - if true, return a Date object. Otherwise, return
     **                        an ISO 8601 formatted string
     */
    function getLondonTime(d, obj) {
        // Use provided date or default to current date and time
        d = d || new Date();

        // Get start and end dates for daylight saving for supplied date's year
        // Set UTC date values and time to 01:00
        var dstS = getLastSunday(d.getFullYear(), 3);
        var dstE = getLastSunday(d.getFullYear(), 10);
        dstS = new Date(Date.UTC(dstS.getFullYear(), dstS.getMonth(), dstS.getDate(), 1));
        dstE = new Date(Date.UTC(dstE.getFullYear(), dstE.getMonth(), dstE.getDate(), 1));
        // If date is between dstStart and dstEnd, add 1 hour to UTC time
        // and format using +60 offset
        if (d > dstS && d < dstE) {
            d.setUTCHours(d.getUTCHours() + 1);
            return formatDate(d, 60);
        }
        // Otherwise, don't adjust and format with 00 offset
        return obj ? d : formatDate(d);
    }

    return getLondonTime(new Date());
}

export function lessThanTen(number) {
    let newNumber = number;
    if (number < 10) {
        newNumber = "0" + number;
    }
    return newNumber;
}
