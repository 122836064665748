import * as Constants from "../utils/Constants"
import * as Firebase from '../services/firebase';
import axios from "axios"
// import { title } from 'process';
// const qs = require('querystring');

export async function getLock() {
  const headers = await Firebase.getHeaders()
  return await axios
    .get(Constants.getApiUrl() + Constants.adminLock, headers)
    .then((res) => {
      // console.log(res.data.ferries);
      return res.data.ferries
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}

export async function lockFerry(journey, date, time) {
  const headers = await Firebase.getHeaders()
  const meta = {
    createdOn: new Date().getTime(),
    createdBy: "admin",
  }
  let lockDate = {
    journey: journey,
    time: time,
    date: date,
    archive: false,
    meta: meta,
  }
  // console.log(lockDate);

  return await axios
    .post(Constants.getApiUrl() + Constants.adminLockAdd, lockDate, headers)
    .then((res) => {
      console.log(res)
      console.log(res.data)
      return res.data
    })
    .catch((e) => {
      console.log(e)
      return e
    })
}

export async function unLockFerry(id) {
  const headers = await Firebase.getHeaders()
  return await axios
    .get(Constants.getApiUrl() + Constants.adminLockRemove + id, headers)
    .then((res) => {
      return res.status
    })
    .catch((e) => {
      console.log(e)
      return e
    })
}

export async function getLockArchive() {
  const headers = await Firebase.getHeaders()
  return axios
    .get(Constants.getApiUrl() + Constants.adminLockArchive, headers)
    .then((res) => {
      // console.log(res.data.ferries);
      return res.data.ferries
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}
