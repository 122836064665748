import React from "react"; // useEffect // useState, // Component,

import { Button, Table, Space, Typography } from "antd";

import * as Constants from "../../utils/Constants";
import * as PassengerUtils from "../../utils/PassengerUtils";
import * as CarUtils from "../../utils/CarUtils";
import { PassengerIconsSummary } from "./passengers/PassengerIconsSummary";
import TicketStatus from "./TicketStatus";

export default function SmallTicketsTable(props) {
    const { viewTicket, tickets } = props;

    let data = tickets;

    data.forEach((d) => (d.key = d._id));

    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            // width: "8%",
            render: (value, row) => (
                <Button
                    type="link"
                    onClick={() => {
                        // console.log(value)
                        viewTicket(row);
                    }}
                    style={{ padding: "0px" }}
                >
                    {Constants.getIDString(value).toUpperCase()}
                </Button>
            ),
            sorter: (a, b) => {
                if (a._id < b._id) {
                    return -1;
                }
                if (a._id > b._id) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Name",
            key: "name",
            // width: "15%",
            render: (value, row) => (
                <Space direction="vertical" size="small">
                    <Typography.Text>{row.name}</Typography.Text>
                    <Typography.Text style={{ fontSize: "0.8em" }}>
                        <a href={"mailto:" + row.email}>{row.email}</a>
                    </Typography.Text>
                </Space>
            ),
            sorter: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Pass.",
            render: (value, row) => (
                <>
                    {/* <Typography.Text>{JSON.stringify(row.passengers)}</Typography.Text> */}
                    <PassengerIconsSummary passengers={row.passengers} />
                </>
            ),
            // width: "10%",
            sorter: async (a, b) => {
                const aIndex = CarUtils.getSingleTicketVehicleTotal(a, 2) * 100 + PassengerUtils.SingleTicketPassengerTotal(a, 2);
                const bIndex = CarUtils.getSingleTicketVehicleTotal(b, 2) * 100 + PassengerUtils.SingleTicketPassengerTotal(b, 2);
                if (aIndex < bIndex) {
                    return -1;
                }
                if (aIndex > bIndex) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value) => <TicketStatus value={value} />,
            // ellipsis: true,
            // width: "5%",
            sorter: (a, b) => {
                const astatus = Constants.getPaidStatus(a.status);
                const bstatus = Constants.getPaidStatus(b.status);
                if (astatus < bstatus) {
                    return -1;
                }
                if (astatus > bstatus) {
                    return 1;
                }
                return 0;
            }
        }
    ];

    return (
        <>
            <Typography.Title level={5}>Tickets List</Typography.Title>
            {/* <Typography.Text>{JSON.stringify(data)}</Typography.Text> */}
            <Table
                dataSource={data}
                columns={columns}
                pagination={data.length < 20 ? false : { defaultPageSize: 20 }}
            // scroll={{ x: 1200 }}
            // onChange={tableChange}
            />
        </>
    );
}
