import React from 'react';

import {
    Row,
    Divider,
    Space,
    Button,
    Typography,
} from 'antd';

import * as Constants from '../../utils/Constants';

import {
    DeleteOutlined,
    SaveOutlined,
} from '@ant-design/icons';

export function NewDefinitionTable(props) {

    const { monthly, remove, save } = props;

    const _months = monthly.m.map((mth, index) => Constants.months[mth]);

    // console.log(monthly);

    return (
        <React.Fragment key={JSON.stringify(monthly.m)}>
            <Row justify="left" className={"primary"}>{
                _months.join(", ").replace(/, ([^,]*)$/, ' and $1')
            }</Row>

            {monthly.d.map((daysArray, index) => {
                const sortedArray = daysArray.sort()
                const _days = sortedArray.map((da) => Constants.days[da]);
                return <React.Fragment key={JSON.stringify(_days)} style={{ paddingTop: "10px" }}>
                    <Row style={{ padding: "4px" }} justify="space-between" align="middle" >
                        <Space size="large">
                            <Typography.Text>
                                {_days.join(", ").replace(/, ([^,]*)$/, ' and $1')}
                            </Typography.Text>
                            {monthly.only.includes(daysArray)
                                ? <Typography.Text>Only *</Typography.Text>
                                : <></>}
                        </Space>
                        <Button type="ghost" danger size="small" onClick={() => remove(daysArray)} icon={<DeleteOutlined />}>Remove</Button>
                    </Row>
                </React.Fragment>

            })}

            <Divider orientation="right">
                <Space size="large" direction="horizontal">
                    <Button type="primary" onClick={() => save(monthly)} icon={<SaveOutlined />}>Save Definition</Button>
                </Space>
            </Divider>
        </React.Fragment>
    )
}