import React, { useState } from "react";
import { Space, Dropdown, Menu, Button, message } from "antd";

import NewTicketPanel from "./NewTicketPanel";
import NewRecurringTicketPanel from "../recurringTickets/NewRecurringTicketPanel";

import { PlusCircleOutlined, PartitionOutlined } from "@ant-design/icons";

export default function NewTicketButton(props) {
    const { reload, recurring } = props;

    // NEW TICKET
    const [newTicket, setNewTicket] = useState(false);
    const [newRecurringTicket, setNewRecurringTicket] = useState(false);
    const [width] = useState(window.innerWidth);

    function AddNewTicket() {
        setNewTicket(true);
    }

    function closeNewDrawer() {
        setNewTicket(false);
        reload();
    }

    function closeRecurringDrawer() {
        setNewRecurringTicket(false);
        reload();
    }

    // const menu = (
    //     <Menu
    //         items={[
    //             {
    //                 label: "Add Recurring Ticket",
    //                 key: "1",
    //                 onClick: () => setNewRecurringTicket(true),
    //                 icon: <PartitionOutlined />
    //             }
    //         ]}
    //     ></Menu>
    // );

    // const items = [
    //     {
    //         label: "1st menu item",
    //         key: "1",
    //         icon: <UserOutlined />
    //     },
    //     {
    //         label: "2nd menu item",
    //         key: "2",
    //         icon: <UserOutlined />
    //     },
    //     {
    //         label: "3rd menu item",
    //         key: "3",
    //         icon: <UserOutlined />
    //     }
    // ];
    const menuProps = {
        items: [
            {
                label: "Add Recurring Ticket",
                key: "1",
                icon: <PlusCircleOutlined />,
                onClick: () => setNewRecurringTicket(true)
            }
        ]
    };

    return (
        <>
            <Space size="middle">
                {recurring ? (
                    <Button type={"primary"} onClick={() => setNewRecurringTicket(true)} icon={<PlusCircleOutlined />}>
                        Add Recurring
                    </Button>
                ) : (
                    <Dropdown.Button type="primary" icon={<PlusCircleOutlined />} onClick={AddNewTicket} menu={menuProps}>
                        Add A Ticket
                    </Dropdown.Button>
                )}
            </Space>
            <NewTicketPanel onClose={closeNewDrawer} visible={newTicket} width={width > 900 ? 640 : "100%"} />
            <NewRecurringTicketPanel onClose={closeRecurringDrawer} visible={newRecurringTicket} width={width > 900 ? 640 : width} />
        </>
    );
}

export const MemoizedNewTicketButton = NewTicketButton;
