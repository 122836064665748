import React, { useState, useEffect } from "react";

import {
    PageHeader,
    Button,
    Row,
    Col,
    Space,
    Input,
    message,
    Tooltip,
    Divider,
    Spin
} from "antd";

import { SyncOutlined } from "@ant-design/icons";

import * as ToryService from "../services/ToryService";
import * as RecurringTicketService from "../services/RecurringTicketService";

import LogoutButton from "../components/LogoutButton";
import NewTicketButton from "../components/tickets/newticket/NewTicketButton";
import RecurringTicketTable from "../components/tickets/recurringTickets/RecurringTicketTable";
import EditRecurringPanel from "../components/tickets/recurringTickets/EditRecurringPanel";
import ViewRecurringPanel from "../components/tickets/recurringTickets/ViewRecurringPanel";

const { Search } = Input;

export default function RecurringTickets(props) {
    // SETTING NEW ONES
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [ticketCount, setTicketCount] = useState(20);
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    const [showViewDrawer, setShowViewDrawer] = useState(false);
    const [singleTicket, setSingleTicket] = useState(null);
    // ARCHIVE
    const [width] = useState(window.innerWidth);

    // const searchOptions = {
    //     threshold: 0.4,
    //     keys: ["_id", "name", "email"]
    // };

    async function searchTickets(searchTerm) {
        if (
            searchTerm === null ||
            searchTerm === undefined ||
            searchTerm === ""
        ) {
            loadTickets();
        }
        // console.log(searchTerm);
        // console.log(searchTerm.length);
        if (searchTerm.length > 3) {
            let response = await RecurringTicketService.searchTickets(
                searchTerm
            );
            setTickets(response.tickets);
        }
        // const fuse = new Fuse(response.tickets, searchOptions);
        // if (searchTerm === "" || searchTerm === undefined) {
        // } else {
        //     const searchedTickets = fuse.search(searchTerm);
        //     const newTickets = [];
        //     searchedTickets.map((t) => newTickets.push(t.item));
        //     setTickets(newTickets);
        // }
    }

    function closeNewDrawer() {
        // setNewTicket(false);
        setSingleTicket(null);
        loadTickets();
    }

    function editTicket(id) {
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(getTicketDetails(id));
            setShowEditDrawer(true);
        }
    }

    function viewTicket(id) {
        // console.log(id);
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(getTicketDetails(id));
            setShowViewDrawer(true);
        }
    }

    async function cleanUp() {
        // console.log("clean up");
        // CALL SERVER
        let response = await ToryService.cleanUpTickets();
        // const response = { "status": 200 };
        if (response.status === 200) {
            message.success("Tickets Cleaned Up");
            loadTickets();
        } else {
            message.error("Can't clean up tickets");
        }
    }

    async function sendReceipt(id) {
        // console.log(`id of receipt ${id}`);
        let response = await ToryService.sendReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Receipt Sent");
            // this.props.onClose();
            setShowEditDrawer(false);
            loadTickets();
        } else {
            message.error("Can't send receipt for ticket");
        }
    }

    async function sendUpdateReceipt(id) {
        // console.log(`id of receipt ${id}`);
        let response = await ToryService.sendUpdateReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Update Email Sent");
            // this.props.onClose();
            setShowEditDrawer(false);
            loadTickets();
        } else {
            message.error("Can't send email for ticket");
        }
    }

    async function archiveTicket(id) {
        // CALL SERVER
        let response = await ToryService.archiveTicket(id);
        if (response.status === 200) {
            message.success("Ticket Archived");
            // this.props.onClose();
            setShowEditDrawer(false);
            loadTickets();
        } else {
            message.error("Can't archive ticket");
        }
    }

    function viewArchiveTicket(archiveTicket) {
        // console.log(id);
        if (archiveTicket !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(archiveTicket);
            setShowViewDrawer(true);
        }
    }

    function getTicketDetails(id) {
        let ticket = null;
        // console.log(tickets);
        tickets.forEach((element) => {
            if (element._id === id) {
                ticket = element;
            }
        });
        return ticket;
    }

    function closeEditDrawer() {
        setShowEditDrawer(false);
        setSingleTicket(null);
        loadTickets();
    }
    function closeViewDrawer() {
        setShowViewDrawer(false);
        setSingleTicket(null);
        loadTickets();
    }

    async function loadTickets() {
        setLoading(true);
        // let response = await ToryService.getAdvancedTickets({ current: 0, pageSize: 20 }, {}, { field: null, order: "ascend" });
        // console.log(response);
        let response = await RecurringTicketService.getTickets();
        // console.log(`tickets: ${response.tickets}`);
        // console.log(response.tickets)
        if (response.status === 200) {
            setTickets(response.tickets);
            setTicketCount(response.count);
        } else {
            // console.log("set 0");
            setTickets([]);
        }
        setLoading(false);
    }

    async function tableChange(pagination, filters, sorter) {
        console.log(pagination);
        console.log(filters);
        console.log(sorter);
    }

    useEffect(() => {
        loadTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader
                ghost={false}
                title="Recurring Tickets"
                extra={<LogoutButton />}
            ></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24}>
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ maxWidth: "100%", width: "100%" }}
                        >
                            <Divider orientation="right">
                                <Space
                                    direction={["vertical", "horizontal"]}
                                    size="middle"
                                    align="end"
                                    justify="end"
                                >
                                    <Search
                                        placeholder="Search, ID, Name, Email"
                                        // onSearch={searchTickets}
                                        onChange={(v) =>
                                            searchTickets(v.target.value)
                                        }
                                        enterButton
                                        allowClear
                                        style={{ width: "400px" }}
                                    />
                                    <Tooltip title="Reload">
                                        <Button
                                            onClick={() => loadTickets()}
                                            icon={<SyncOutlined />}
                                        ></Button>
                                    </Tooltip>
                                    {/* <Tooltip title="Clean Up Old Tickets">
                                        <Popconfirm
                                            title="Clear up old tickets?"
                                            okText="Yes"
                                            onConfirm={() => cleanUp()}
                                            onCancel={Constants.cancel}
                                            cancelText="No"
                                        >
                                            <Button
                                                type="ghost"
                                                icon={<ClearOutlined />}
                                            >
                                                Clean Up
                                            </Button>
                                        </Popconfirm>
                                    </Tooltip> */}
                                    <NewTicketButton
                                        reload={closeNewDrawer}
                                        recurring={true}
                                    />
                                </Space>
                            </Divider>

                            {loading ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : tickets === 0 ? (
                                <>
                                    <Row justify="center">
                                        Error with Ticketing
                                    </Row>
                                </>
                            ) : tickets === [] ? (
                                <>
                                    <Row justify="center">
                                        {/* <Spin /> */}
                                        "No Tickets Yet!"
                                    </Row>
                                </>
                            ) : (
                                <>
                                    {/* {tickets.map((t, index) => (
                                        <Typography.Text key={index}>
                                            {`${t._id} ${t.name} ${t.recurrance} ${t.datecreated} ${t.status}`}
                                        </Typography.Text>
                                    ))} */}
                                    <RecurringTicketTable
                                        tickets={
                                            tickets.length === 0 ? [] : tickets
                                        }
                                        viewTicket={viewTicket}
                                        editTicket={editTicket}
                                        archiveTicket={archiveTicket}
                                        tableChange={tableChange}
                                        showDateCreated={true}
                                    />
                                </>
                            )}
                        </Space>
                    </Col>
                </Row>
            </div>

            {singleTicket ? (
                <EditRecurringPanel
                    onClose={closeEditDrawer}
                    visible={showEditDrawer}
                    width={width > 900 ? 640 : width}
                    singleTicket={singleTicket}
                    archiveTicket={archiveTicket}
                    editTicket={editTicket}
                    sendReceipt={sendReceipt}
                    sendUpdateReceipt={sendUpdateReceipt}
                />
            ) : (
                <></>
            )}

            <ViewRecurringPanel
                onClose={closeViewDrawer}
                visible={showViewDrawer}
                width={width > 900 ? 640 : width}
                singleTicket={singleTicket}
            />
        </>
    );
}
