import React from "react"; // useEffect // useState, // Component,

import { Button, Table, Space, Typography, Tag } from "antd";

import * as Constants from "../../../utils/Constants";
import * as PassengerUtils from "../../../utils/PassengerUtils";
import * as CarUtils from "../../../utils/CarUtils";
import { showPrices } from "../../../utils/Global";
import { PassengerIconsSummary } from "../passengers/PassengerIconsSummary";
import TicketStatus from "../TicketStatus";
import { format } from "date-fns";

export default function RecurringTicketTable(props) {
    const { showDateCreated } = props;

    let data = props.tickets;
    // console.log(data);

    data.forEach((d) => (d.key = d._id));

    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            width: "7%",
            render: (value) => (
                <Button type="link" onClick={() => props.viewTicket(value)} style={{ padding: "0px" }}>
                    {Constants.getIDString(value).toUpperCase()}
                </Button>
            ),
            sorter: (a, b) => {
                if (a._id < b._id) {
                    return -1;
                }
                if (a._id > b._id) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Name",
            key: "name",
            width: "15%",
            render: (value, row) => (
                <Space direction="vertical" size="small">
                    {row.name === null || row.name === "" || row.name === "null" ? (
                        <Typography.Text>-</Typography.Text>
                    ) : (
                        <Typography.Text>{`${row.name}`}</Typography.Text>
                    )}
                    <Typography.Text style={{ fontSize: "0.8em" }}>
                        <a href={"mailto:" + row.email}>{row.email}</a>
                    </Typography.Text>
                </Space>
            ),
            sorter: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Recurrance",
            dataIndex: "recurrance",
            width: "18%",
            key: "recurrance",
            render: (value, row) => {
                return (
                    <Space direction="vertical">
                        {/* <Typography.Text>{`${JSON.stringify(
                            value
                        )}`}</Typography.Text> */}
                        <Space>
                            <Tag color={"processing"}>{`${value.duration}x ${value.type.toUpperCase()}`}</Tag>
                        </Space>
                        <Space size={"small"} wrap>
                            {value.days.map((d, idx) => (
                                <Tag
                                    style={{
                                        marginLeft: "0px",
                                        marginRight: "0px"
                                    }}
                                    key={idx}
                                    color={"purple"}
                                >{`${Constants.days[d].toUpperCase()}`}</Tag>
                            ))}
                        </Space>
                    </Space>
                );
            }
        },
        {
            title: "Times",
            dataIndex: "recurrance",
            width: "10%",
            key: "recurrance",
            render: (value, row) => {
                return (
                    <Space direction="vertical">
                        <Typography.Text>Out Time: {`${Constants.getTimestamp(row.ticket.outTime)}`}</Typography.Text>
                        {row.ticket.returnTicket ? (
                            <Typography.Text>Return Time: {`${Constants.getTimestamp(row.ticket.returnTime)}`}</Typography.Text>
                        ) : (
                            <></>
                        )}
                    </Space>
                );
            }
        },
        {
            title: "Pass.",
            render: (value, row) => (
                <>
                    <PassengerIconsSummary passengers={row.passengers} cardetails={row.meta.cardetails ?? ""} />
                </>
            ),
            width: "10%",
            // ellipsis: true,
            sorter: (a, b) => {
                const aIndex = CarUtils.getSingleTicketVehicleTotal(a, 2) * 100 + PassengerUtils.SingleTicketPassengerTotal(a, 2);
                const bIndex = CarUtils.getSingleTicketVehicleTotal(b, 2) * 100 + PassengerUtils.SingleTicketPassengerTotal(b, 2);
                if (aIndex < bIndex) {
                    return -1;
                }
                if (aIndex > bIndex) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value) => <TicketStatus value={value} />,
            // ellipsis: true,
            width: "10%",
            sorter: (a, b) => {
                const astatus = Constants.getPaidStatus(a.status);
                const bstatus = Constants.getPaidStatus(b.status);
                if (astatus < bstatus) {
                    return -1;
                }
                if (astatus > bstatus) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Actions",
            dataIndex: "_id",
            key: "_id",
            fixed: "right",
            render: (value) => (
                <Space direction="vertical">
                    <Button type="outline" onClick={() => props.editTicket(value)}>
                        Edit
                    </Button>
                    {/* <Button
                        type="outline"
                        onClick={() => props.editTicket(value)}
                    >
                        View Tickets
                    </Button> */}
                </Space>
            ),
            width: "10%"
        }
    ];

    if (showDateCreated) {
        columns.splice(columns.length - 1, 0, {
            title: "Date Created",
            dataIndex: "datecreated",
            key: "datecreated",
            render: (value) => `${format(value, "d/M/YYY, HH:mm")}`,
            width: "8%",
            // ellipsis: true,
            defaultSortOrder: "descend",
            sorter: (a, b) => a.datecreated - b.datecreated
        });
    }

    // console.log(showPrices())
    if (showPrices()) {
        // console.log("splice")
        columns.splice(columns.length - 2, 0, {
            title: "Price (per ticket)",
            dataIndex: "price",
            key: "price",
            render: (value) => <Typography.Text>{Constants.getFormattedPrice(value)}</Typography.Text>,
            width: "10%",
            sorter: (a, b) => a.price - b.price
        });
    }

    if (!props.archive) {
        columns.filter((c) => c.title === "Archive");
    }

    // setFilteredInfo()
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                pagination={data.length > 20 ? { defaultPageSize: 20, total: 100 } : false}
                scroll={{ x: 1200 }}
                // onChange={tableChange}
            />
        </>
    );
}
