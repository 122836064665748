// import React from "react"

// import {
//     Drawer,
//     Space,
//     Typography,
//     Descriptions,
// } from "antd"

// import { CloseSquareOutlined } from "@ant-design/icons"

// import { getFormattedPrice } from "../../utils/Constants"

// export default function NewPricesPanel(props) {
//     const { visible, width, onClose } = props

//     const price = visible;

//     return (
//         <>
//             <Drawer
//                 title="View Price"
//                 placement="right"
//                 closable={true}
//                 closeIcon={<CloseSquareOutlined />}
//                 width={width}
//                 onClose={() => onClose()}
//                 visible={visible}
//             >
//                 {price === null
//                     ? <Typography.Title level={3}>No Price Selected</Typography.Title>
//                     :
//                     <Space direction="vertical" size="middle" style={{ width: "100%" }}>
//                         <Descriptions title="Price Info" column={1} bordered>
//                             <Descriptions.Item label="Label">
//                                 <Space direction="horiztonal" size="small" style={{ maxWidth: "100%" }}>
//                                     <Typography.Text>{`${price.label}`}</Typography.Text>
//                                     <Typography.Text
//                                         style={{ fontSize: "0.8em" }}
//                                     >{`(${price.labelIE})`}</Typography.Text>
//                                 </Space>
//                             </Descriptions.Item>
//                             <Descriptions.Item label="Code">
//                                 {`${price.code}`}
//                             </Descriptions.Item>
//                             <Descriptions.Item label="Price">
//                                 {`${getFormattedPrice(price.price)}`}
//                             </Descriptions.Item>
//                             <Descriptions.Item label="Passengers">
//                                 {`${price.passengers}`}
//                             </Descriptions.Item>
//                             <Descriptions.Item label="Cars">
//                                 {`${price.cars}`}
//                             </Descriptions.Item>
//                             <Descriptions.Item label="Islander">
//                                 {`${price.islander ? "Islander" : "Visitor"}`}
//                             </Descriptions.Item>
//                         </Descriptions>
//                     </Space>
//                 }
//             </Drawer>
//         </>
//     )
// }

import React from "react";

import { Drawer, Space, Typography, Descriptions } from "antd";

import { CloseSquareOutlined } from "@ant-design/icons";

import { getFormattedPrice } from "../../utils/Constants";

export default function NewPricesPanel(props) {
  const { visible, width, onClose } = props;

  const price = visible;

  return (
    <>
      <Drawer
        title="View Price"
        placement="right"
        closable={true}
        closeIcon={<CloseSquareOutlined />}
        width={width}
        onClose={() => onClose()}
        open={visible}
      >
        {price === null ? (
          <Typography.Title level={3}>No Price Selected</Typography.Title>
        ) : (
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Label">
                <Space direction="horiztonal" size="small" style={{ maxWidth: "100%" }}>
                  <Typography.Text>{`${price.label}`}</Typography.Text>
                  <Typography.Text style={{ fontSize: "0.8em" }}>{`(${price.labelIE})`}</Typography.Text>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Code">{`${price.code}`}</Descriptions.Item>
              <Descriptions.Item label="Price">{`${getFormattedPrice(price.price)}`}</Descriptions.Item>
              <Descriptions.Item label="Passengers">{`${price.passengers}`}</Descriptions.Item>
              <Descriptions.Item label="Cars">{`${price.cars}`}</Descriptions.Item>
              <Descriptions.Item label="Deck Spaces">{`${price.deckSpaces}`}</Descriptions.Item>
              <Descriptions.Item label="Islander">{`${price.islander ? "Islander" : "Visitor"}`}</Descriptions.Item>
              <Descriptions.Item label="Admin Only">{`${price.adminOnly ? "Admin" : "No. Anyone Can Book"}`}</Descriptions.Item>
            </Descriptions>
          </Space>
        )}
      </Drawer>
    </>
  );
}
