import { Typography, Space, Row, Col, Statistic, Card } from "antd";
import React from "react"; //  { useEffect, useState }
import * as Constants from "../../utils/Constants";
import Chart from "react-apexcharts";
// import {
//     carsPerFerry,
//     generateFerryArray,
//     passengerPerFerry
// } from "../../utils/StatisticUtils";
// import { TicketIcon } from "../../assets/TicketIcon";
import * as CarUtils from "../../utils/CarUtils";
import * as PassengerUtils from "../../utils/PassengerUtils";
import { FerryIcon } from "../../../src/assets/FerryIcon";

import { CarOutlined, UserOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { FaMotorcycle, FaShuttleVan, FaTruck } from "react-icons/fa";

export default function TotalPassengerCountChart(props) {
    const { data, date, journey } = props;

    let series = [
        {
            name: "Passenger Count",
            data: data.map((f) => PassengerUtils.TicketPassengerTotal(f.tickets))
        },
        {
            name: "Car Count",
            data: data.map((f) => CarUtils.getVehicleTotal(f.tickets, 2))
        }
    ];

    let tickets = [];
    data.forEach((d) => {
        tickets = [...tickets, ...d.tickets];
    });

    if (CarUtils.getVehicleTotal(tickets, 1) > 0) {
        series.push({
            name: "Bike Count",
            data: data.map((f) => CarUtils.getVehicleTotal(f.tickets, 1))
        });
    }
    if (CarUtils.getVehicleTotal(tickets, 4) > 0) {
        series.push({
            name: "Van Count",
            data: data.map((f) => CarUtils.getVehicleTotal(f.tickets, 4))
        });
    }
    if (CarUtils.getVehicleTotal(tickets, 6) > 0) {
        series.push({
            name: "Lorry Count",
            data: data.map((f) => CarUtils.getVehicleTotal(f.tickets, 6))
        });
    }

    let chartData = {
        options: {
            chart: {
                id: "Passenger & Car Count",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: data.map((f) => Constants.getTimestamp(f.time))
            }
        },
        series: series
    };
    return (
        <Card
            title={`Passengers Statistics for ${format(date, "do MMM yyyy")} ${
                journey === "all" ? "" : Constants.getJourneyString(journey, false)
            }`}
            bordered={false}
            actions={[
                <Space direction="horizontal" size={"large"}>
                    <Statistic
                        title={"Total Cars"}
                        value={`${data
                            .map((f) => CarUtils.getVehicleTotal(f.tickets, 2))
                            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                        prefix={<CarOutlined />}
                    />
                    {data
                        .map((f) => CarUtils.getVehicleTotal(f.tickets, 4))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0) > 0 ? (
                        <Statistic
                            title={"Total Vans"}
                            value={`${data
                                .map((f) => CarUtils.getVehicleTotal(f.tickets, 4))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                            prefix={<FaShuttleVan />}
                        />
                    ) : (
                        <></>
                    )}
                    {data
                        .map((f) => CarUtils.getVehicleTotal(f.tickets, 1))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0) > 0 ? (
                        <Statistic
                            title={"Total Bikes"}
                            value={`${data
                                .map((f) => CarUtils.getVehicleTotal(f.tickets, 1))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                            prefix={<FaMotorcycle />}
                        />
                    ) : (
                        <></>
                    )}
                    {data
                        .map((f) => CarUtils.getVehicleTotal(f.tickets, 6))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0) > 0 ? (
                        <Statistic
                            title={"Total Lorries"}
                            value={`${data
                                .map((f) => CarUtils.getVehicleTotal(f.tickets, 6))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                            prefix={<FaTruck />}
                        />
                    ) : (
                        <></>
                    )}
                </Space>,
                <Statistic
                    title={"Total Passengers"}
                    value={data
                        .map((f) => PassengerUtils.TicketPassengerTotal(f.tickets))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}
                    prefix={<UserOutlined />}
                />,
                <Statistic title={"Total Ferries"} value={data.length} prefix={<FerryIcon />} />
            ]}
        >
            <Row gutter={[16, { sm: 16, md: 24 }]} style={{ width: "100%" }}>
                <Col span={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {/* <Typography.Title level={4}>Passenger and Car Statistics</Typography.Title> */}
                            <Chart
                                options={chartData.options}
                                // toolbar={false}
                                series={chartData.series}
                                type="bar"
                                width="100%"
                                height="300px"
                            />
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}
