// import React, { useContext, useState } from "react";

// import {
//     Divider,
//     Button,
//     Drawer,
//     Space,
//     Input,
//     Form,
//     InputNumber,
//     Typography,
//     Checkbox,
//     message
// } from "antd";

// import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";

// import { getFormattedPrice } from "../../utils/Constants";
// import * as PriceService from "../../services/PriceService";
// import { UserContext } from "../../provider/UserProvider";

// export default function NewPricesPanel(props) {
//     const { visible, width, onClose } = props;

//     const user = useContext(UserContext);

//     const [code, setCode] = useState("");
//     const [label, setLabel] = useState("");
//     const [labelIE, setLabelIE] = useState("");
//     const [passengers, setPassengers] = useState(0);
//     const [cars, setCars] = useState(0);
//     const [price, setPrice] = useState(0);
//     const [islander, setIslander] = useState(false);
//     const [admin, setAdmin] = useState(false);

//     async function saveNewPrice() {
//         console.log(
//             `${label}, ${labelIE}, ${passengers}, ${cars}, ${price}, ${islander}`
//         );
//         // VALIDATE
//         if (label === "" && labelIE === "") {
//             message.error("You need to include a label in English and Gaeilge");
//         }
//         if (passengers === 0 && cars === 0) {
//             message.error("You need add at least one type of passenger");
//         }
//         const priceObject = {
//             label,
//             labelIE,
//             passengers,
//             cars,
//             price,
//             islander,
//             admin,
//             code
//         };
//         // POST TO SERVICE
//         let response = await PriceService.addPrice(priceObject, user._id);
//         // GET RESPONSE
//         if (response.status === 200) {
//             message.success("You've saved the price");
//             onClose();
//             resetForm();
//         } else {
//             message.error("You can't save this price right now.");
//         }
//     }

//     async function resetForm() {
//         setLabel("");
//         setLabelIE("");
//         setPassengers(0);
//         setCars(0);
//         setPrice(0);
//         setIslander(false);
//         setAdmin(false);
//     }

//     return (
//         <>
//             <Drawer
//                 title="New Price"
//                 placement="right"
//                 closable={true}
//                 closeIcon={<CloseSquareOutlined />}
//                 width={width}
//                 onClose={() => onClose()}
//                 visible={visible}
//             >
//                 <Space
//                     direction="vertical"
//                     size="middle"
//                     style={{ width: "100%" }}
//                 >
//                     <Typography.Text>
//                         Define a new price / ticket type for websites and apps.
//                     </Typography.Text>
//                     <Form layout={"vertical"}>
//                         <Form.Item label="Price Label">
//                             <Input
//                                 value={label}
//                                 placeholder="Label for Ticket"
//                                 onChange={(v) => setLabel(v.target.value)}
//                             />
//                         </Form.Item>
//                         <Form.Item label="Price Label Gaeilge">
//                             <Input
//                                 value={labelIE}
//                                 placeholder="Label for Ticket in Gaeilge"
//                                 onChange={(v) => setLabelIE(v.target.value)}
//                             />
//                         </Form.Item>
//                         <Form.Item label="Code">
//                             <Input
//                                 value={code}
//                                 placeholder="Code"
//                                 onChange={(v) => setCode(v.target.value)}
//                             />
//                         </Form.Item>
//                         <Form.Item label="Number of Passengers">
//                             <InputNumber
//                                 value={passengers}
//                                 onChange={(v) => setPassengers(v)}
//                             />
//                         </Form.Item>
//                         <Form.Item label="Number of Cars">
//                             <InputNumber
//                                 value={cars}
//                                 onChange={(v) => setCars(v)}
//                             />
//                         </Form.Item>
//                         <Form.Item label="Price in Cents (for calculating)">
//                             <Space
//                                 direction="vertical"
//                                 style={{ width: "100%" }}
//                             >
//                                 <InputNumber
//                                     value={price}
//                                     onChange={(v) => setPrice(v)}
//                                 />
//                                 <Typography.Text>
//                                     {getFormattedPrice(price)}
//                                 </Typography.Text>
//                             </Space>
//                         </Form.Item>
//                         <Form.Item label="Island Ticket">
//                             <Checkbox
//                                 checked={islander}
//                                 onChange={(v) => setIslander(v.target.checked)}
//                             >
//                                 Check box for islander ticket
//                             </Checkbox>
//                         </Form.Item>
//                         <Form.Item label="Admin Only Ticket">
//                             <Checkbox
//                                 checked={admin}
//                                 onChange={(v) => setAdmin(v.target.checked)}
//                             >
//                                 Check box for admin ticket
//                             </Checkbox>
//                         </Form.Item>
//                     </Form>
//                     <Divider orientation="right">
//                         <Button
//                             type="primary"
//                             onClick={() => saveNewPrice()}
//                             icon={<SaveOutlined />}
//                         >
//                             Save
//                         </Button>
//                     </Divider>
//                 </Space>
//             </Drawer>
//         </>
//     );
// }

import React, { useContext, useState } from "react";

import { Divider, Button, Drawer, Space, Input, Form, InputNumber, Typography, Checkbox, message } from "antd";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";

import { getFormattedPrice, maxDeckSpacesAvailable } from "../../utils/Constants";
import * as PriceService from "../../services/PriceService";
import { UserContext } from "../../provider/UserProvider";

export default function NewPricesPanel(props) {
    const { visible, width, onClose } = props;

    const user = useContext(UserContext);

    const [code, setCode] = useState("");
    const [label, setLabel] = useState("");
    const [labelIE, setLabelIE] = useState("");
    const [passengers, setPassengers] = useState(0);
    const [cars, setCars] = useState(0);
    const [deckSpaces, setDeckSpaces] = useState(0);
    const [price, setPrice] = useState(0);
    const [islander, setIslander] = useState(false);
    const [adminOnly, setAdminOnly] = useState(false);

    async function saveNewPrice() {
        // console.log(`${label}, ${labelIE}, ${passengers}, ${cars}, ${price}, ${islander}`);
        // VALIDATE
        if (label === "" && labelIE === "") {
            message.error("You need to include a label in English and Gaeilge");
        }
        if (passengers === 0 && cars === 0) {
            message.error("You need add at least one type of passenger");
        }
        const priceObject = {
            label,
            labelIE,
            passengers,
            cars,
            price,
            islander,
            adminOnly,
            deckSpaces,
            code
        };
        // POST TO SERVICE
        let response = await PriceService.addPrice(priceObject, user._id);
        // GET RESPONSE
        if (response.status === 200) {
            message.success("You've saved the price");
            onClose();
            resetForm();
        } else {
            message.error("You can't save this price right now.");
        }
    }

    async function resetForm() {
        setLabel("");
        setLabelIE("");
        setPassengers(0);
        setCars(0);
        setPrice(0);
        setIslander(false);
        setAdminOnly(false);
    }

    return (
        <>
            <Drawer
                title="New Price"
                placement="right"
                closable={true}
                closeIcon={<CloseSquareOutlined />}
                width={width}
                onClose={() => onClose()}
                open={visible}
            >
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <Typography.Text>Define a new price / ticket type for websites and apps.</Typography.Text>
                    <Form layout={"vertical"}>
                        <Form.Item label="Price Label">
                            <Input value={label} placeholder="Label for Ticket" onChange={(v) => setLabel(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Price Label Gaeilge">
                            <Input value={labelIE} placeholder="Label for Ticket in Gaeilge" onChange={(v) => setLabelIE(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Code">
                            <Input value={code} placeholder="Code" onChange={(v) => setCode(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Number of Passengers">
                            <InputNumber value={passengers} onChange={(v) => setPassengers(v)} />
                        </Form.Item>
                        {maxDeckSpacesAvailable === 0 ? (
                            <></>
                        ) : (
                            <Form.Item label="Number of Deck Spaces (Motorbike = 1, Car = 2, LWB Van = 4, Lorry = 6">
                                <InputNumber value={deckSpaces} onChange={(v) => setDeckSpaces(v)} />
                            </Form.Item>
                        )}

                        <Form.Item label="Price in ¢">
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <InputNumber value={price} onChange={(v) => setPrice(v)} />
                                <Typography.Text>{getFormattedPrice(price)}</Typography.Text>
                            </Space>
                        </Form.Item>
                        <Form.Item label="Island Ticket">
                            <Checkbox checked={islander} onChange={(v) => setIslander(v.target.checked)}>
                                Check box for islander ticket
                            </Checkbox>
                        </Form.Item>
                        <Form.Item label="Admin Only Ticket">
                            <Checkbox checked={adminOnly} onChange={(v) => setAdminOnly(v.target.checked)}>
                                Check box for admin ticket
                            </Checkbox>
                        </Form.Item>
                    </Form>
                    <Divider orientation="right">
                        <Button type="primary" onClick={() => saveNewPrice()} icon={<SaveOutlined />}>
                            Save
                        </Button>
                    </Divider>
                </Space>
            </Drawer>
        </>
    );
}
