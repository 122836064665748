import React, {
    useState,
} from 'react';

import {
    Row,
    Col,
    Typography,
    Divider,
    Space,
    Button,
    Checkbox,
    message
} from 'antd';

import * as Constants from '../../utils/Constants';

import {
    PlusCircleOutlined,
} from '@ant-design/icons';

import { NewDefinitionTable } from '../../components/definitions/NewDefinitionTable'

export function NewDefinition(props) {

    const { saveNew } = props;

    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const [daySelected, setDaySelected] = useState([]);
    const [dayOnly, setDayOnly] = useState(false);
    const [only, setOnly] = useState([]);

    function updateMonths(newMonths) {
        setMonths(newMonths);
    }

    function updateDays(newDays) {
        setDaySelected(newDays);
    }

    function saveDaySet() {
        const _days = days;
        if (daySelected.length !== 0) {
            _days.push(daySelected.sort());
            setDays(_days)
            if (dayOnly) {
                const _only = only;
                _only.push(daySelected.sort())
                setOnly(_only);
            }
            setDaySelected([])
        } else {
            message.info("You need to select at least one day")
        }
    }

    function removeDaySet(removeArray) {
        // 
        const _days = days;
        const _newDays = _days.filter((d) => d !== removeArray)
        const _only = only;
        const _onlyDays = _only.filter((d) => d !== removeArray);
        // console.log(_newDays)
        setDays(_newDays);
        setOnly(_onlyDays);
    }

    function getMonthDef() {
        return {
            "m": months,
            "d": days,
            "only": only
        }
    }


    return <Space direction="vertical" size="middle">
        <Typography.Title level={5}>Months</Typography.Title>
        <Checkbox.Group style={{ width: '100%' }} value={months} onChange={(v) => updateMonths(v)}>
            <Row>
                {Constants.months.map((i, index) => {
                    return <Col key={i} span={8}>
                        <Checkbox value={index}>{Constants.months[index]}</Checkbox>
                    </Col>
                })}
            </Row>
        </Checkbox.Group>
        <Typography.Title level={5}>Days</Typography.Title>
        <Checkbox.Group style={{ width: '100%' }} value={daySelected} onChange={(v) => updateDays(v)}>
            <Row>
                {Constants.days.map((i, index) => {
                    return <Col key={i} span={8}>
                        <Checkbox value={index}>{Constants.days[index]}</Checkbox>
                    </Col>
                })}
            </Row>
        </Checkbox.Group>

        {/* <Checkbox.Group style={{ width: '100%' }} value={daySelected} onChange={(v) => updateDays(v)}> */}
        <Typography.Text>Day Only Range {`${dayOnly}`}</Typography.Text>
        <Checkbox checked={dayOnly} onChange={(v) => setDayOnly(v.target.checked)}>Mark as Day Only Day Range</Checkbox>
        {/* </Checkbox.Group> */}


        <Divider orientation="right">
            <Button type="ghost" onClick={() => saveDaySet()} icon={<PlusCircleOutlined />}>Add Day Range</Button>
        </Divider>

        <Typography.Title level={4}>Preview</Typography.Title>
        {/* <Typography.Text>{JSON.stringify(getMonthDef())}</Typography.Text> */}
        <NewDefinitionTable monthly={getMonthDef()} remove={(v) => removeDaySet(v)} save={(v) => saveNew(v)} />

    </Space>

}