import React from "react";

import { Tag, Space, Descriptions, Typography } from "antd";

import * as Constants from "../../../utils/Constants";

import { PassengerList } from "../passengers/PassengerList";

import { PassengerIconsSummary } from "../passengers/PassengerIconsSummary";

const RecurringTicketViewer = (props) => {
    const { singleTicket } = props;

    return singleTicket === null ? (
        <Typography.Text>No Ticket Selected.</Typography.Text>
    ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Descriptions title="Ticket" layout="vertical" bordered>
                {/* ROW */}
                <Descriptions.Item label="Customer" span={2}>
                    <Space direction="vertical">
                        <Typography.Text>{singleTicket.name}</Typography.Text>
                        <a target={"_blank"} rel="noopener noreferrer" href={"mailto:" + singleTicket.email + ""}>
                            {singleTicket.email}
                        </a>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    <Tag color={Constants.getPaidStatusColour(singleTicket.status)}>{Constants.getPaidStatus(singleTicket.status)}</Tag>
                </Descriptions.Item>
                {/* ROW */}
                {/* <Descriptions.Item label="Special Requirements" span={2}>
                        {singleTicket.meta.specialreq ? singleTicket.meta.specialreq : "No special reqs"}
                    </Descriptions.Item> */}
                <Descriptions.Item label={"Car Info"} span={2}>
                    <Space direction="vertical">
                        <Typography.Text>
                            Car Details: {singleTicket.meta.cardetails ? singleTicket.meta.cardetails : "No Car Details"}
                        </Typography.Text>
                        <Typography.Text>
                            Special Reqs: {singleTicket.meta.specialreq ? singleTicket.meta.specialreq : "No Special Requirements"}
                        </Typography.Text>
                        {singleTicket.smartpass ? (
                            <Typography.Text>
                                {singleTicket.smartpass
                                    ? `SmartPass IDs: ${singleTicket.smartpass.map((p) => <Typography.Text>{p}</Typography.Text>)}`
                                    : ""}
                            </Typography.Text>
                        ) : (
                            <></>
                        )}
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                    {singleTicket.meta.number ? singleTicket.meta.number : "No number provided"}
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Ticket" span={2}>
                    <Space direction="vertical">
                        <Space>
                            <Tag color={"processing"}>
                                {`${singleTicket.recurrance.duration}x ${singleTicket.recurrance.type.toUpperCase()}`}
                            </Tag>
                        </Space>
                        <Space wrap>
                            {singleTicket.recurrance.days.map((d, idx) => (
                                <Tag key={idx} color={"purple"}>{`${Constants.days[d].toUpperCase()}`}</Tag>
                            ))}
                        </Space>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Times" span={1}>
                    <Space direction="vertical">
                        <Typography.Text>Out Time: {`${Constants.getTimestamp(singleTicket.ticket.outTime)}`}</Typography.Text>
                        {singleTicket.ticket.returnTicket ? (
                            <Typography.Text>Return Time: {`${Constants.getTimestamp(singleTicket.ticket.returnTime)}`}</Typography.Text>
                        ) : (
                            <></>
                        )}
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Total (per ticket)">{Constants.getFormattedPrice(singleTicket.price)}</Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Passengers" span={2}>
                    {/* {PassengerUtils.getPassengerList(singleTicket.ticket.passengers)} */}
                    {/* <PassengerList passengers={singleTicket.passengers} />
                     */}
                    <PassengerList newTicket={singleTicket} showRemove={false} showTotals={false} removePassenger={() => console.log("")} />
                </Descriptions.Item>
                <Descriptions.Item label="Total Passengers">
                    <PassengerIconsSummary passengers={singleTicket.passengers} />
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Date Bought">{Constants.getShortDateString(singleTicket.datecreated)}</Descriptions.Item>

                <Descriptions.Item label="Method">{Constants.getMethodIcon(singleTicket.method)}</Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label={"Ticket and User ID"} span={3}>
                    <Space direction="vertical">
                        <Typography.Text>{`Recurring Ticket ID: ${singleTicket._id}`}</Typography.Text>
                        <Typography.Text>{singleTicket.userid ? `User ID: ${singleTicket.userid}` : "Guest"}</Typography.Text>
                    </Space>
                </Descriptions.Item>
            </Descriptions>
        </Space>
    );
};

export default RecurringTicketViewer;
