import React, { useEffect, useState } from "react";
import { message, Drawer, Space, Button, Form, DatePicker, Select, Input, Row } from "antd";
import moment from "moment";
import { format } from "date-fns";
import { SaveOutlined } from "@ant-design/icons";
import { getPrettyTimeAMPM } from "../../../utils/TimeUtils";
import * as NoteService from "../../../services/NoteService";
import { Divider } from "antd";

export function EditNoteDrawer(props) {
    // PROPS
    const { note, setNote, date, time } = props;

    // WIDTH
    const [width] = useState(window.innerWidth);

    // NOTES
    const [newNote, setNewNote] = useState(``);
    const [newDate, setNewDate] = useState(new Date(new Date(date).setHours(12, 0, 0, 0)));
    const [newTime, setNewTime] = useState(time);
    const [newPriority, setNewPriority] = useState(0);

    useEffect(() => {
        updateNote();
    }, [note]);

    async function updateNote() {
        if (note === null) {
            return;
        }
        setNewNote(note.note);
        setNewDate(new Date(note.date));
        setNewTime(note.time);
        setNewPriority(note.priority);
    }

    async function saveEditedNote() {
        if (newNote === "") {
            message.error("Please include a valid note");
            return;
        }
        // SEND TO SERVER
        // console.log(`note: ${newNote}, date: ${newDate}, time: ${newTime}, priorty: ${newPriority}`);

        let { error } = await NoteService.editNote(note._id, newNote, newPriority, newDate.getTime(), newTime);

        if (error === 0) {
            // SUCCESS
            //    // RESET
            //    // CLOSE
            message.success("Edited the note!");
        } else {
            // FAILED
            //    // MESSAGE
            message.error("Failed to edit the note");
        }
    }

    async function deleteNote() {
        // console.log(note._id);
        let { error } = await NoteService.deleteNote(note._id);
        if (error === 0) {
            message.success("Deleted the note!");
            setNote(null);
        } else {
            message.error("Failed to delete this note");
        }
    }

    return (
        <Drawer
            open={note}
            onClose={() => setNote(null)}
            placement="right"
            closeIcon={<></>}
            closable={true}
            title={`Edit Note for ${format(date, "do MMM yyyy")}`}
            width={width > 900 ? 600 : "100%"}
            // extra={[
            //     <Space key="closebuttonspacer">
            //         <Button onClick={() => setNote(false)}>Close</Button>
            //     </Space>
            // ]}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                <Form
                    labelCol={{
                        span: 8
                    }}
                    wrapperCol={{
                        span: 16
                    }}
                >
                    {/* DATE */}
                    <Form.Item label="Date">
                        <DatePicker
                            value={moment(newDate)}
                            onChange={(v) => setNewDate(new Date(new Date(v._d)).setHours(12, 0, 0, 0))}
                            allowClear={false}
                        />
                    </Form.Item>
                    {/* TIME */}
                    <Form.Item label="Time">
                        <Select
                            value={newTime}
                            onChange={(value) => setNewTime(value)}
                            options={[
                                { value: -1, label: "All Day" },
                                { value: time, label: `${getPrettyTimeAMPM(time)}` }
                            ]}
                        ></Select>
                    </Form.Item>
                    {/* NOTE PRIORITY */}
                    <Form.Item label="Priority">
                        <Select
                            value={newPriority}
                            onChange={(value) => setNewPriority(value)}
                            options={[
                                { value: 0, label: "Low" },
                                { value: 1, label: "Normal" },
                                { value: 2, label: "High" },
                                { value: 3, label: "!!! Highest" }
                            ]}
                        ></Select>
                    </Form.Item>
                    {/* NOTE */}
                    <Form.Item label="Note">
                        <Input.TextArea
                            placeholder="Note"
                            onChange={(v) => setNewNote(v.target.value)}
                            value={newNote}
                            style={{ height: 120 }}
                        />
                    </Form.Item>
                </Form>
                <Row justify={"end"}>
                    <Button icon={<SaveOutlined />} type="primary" onClick={() => saveEditedNote()}>
                        Update Note
                    </Button>
                </Row>
                <Divider />
                <Row justify={"end"}>
                    <Button icon={<SaveOutlined />} type="primary" danger onClick={() => deleteNote()}>
                        Delete Note
                    </Button>
                </Row>
            </Space>
        </Drawer>
    );
}
