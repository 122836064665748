import React, { useState, useEffect } from "react";

import {
  Divider,
  PageHeader,
  Button,
  Row,
  Col,
  Radio,
  Popconfirm,
  Card,
  Table,
  DatePicker,
  message,
  Spin,
  Typography,
  Space,
} from "antd";
import LogoutButton from "../components/LogoutButton";

import {
  UpCircleOutlined,
  CloseCircleOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons";

import * as ToryService from "../services/ToryService";
import * as Constants from "../utils/Constants";
import moment from "moment";

function Reroute() {
  const [journey, setJourney] = useState("dm");
  const [chosenDate, setChosenDate] = useState(new Date());
  const [ferries, setFerries] = useState(0);
  const [times, setTimes] = useState([]);
  const [conflictTickets, setConflictTickets] = useState([]);
  const [confirmTime, setConfirmTime] = useState(null);
  const [showArchive, setShowArchive] = useState(false);
  const [archiveFerries, setArchiveFerries] = useState({ dt: [], dm: [] });

  function resetForm() {
    setJourney("dm");
    setChosenDate(new Date());
    setFerries({ dt: [], dm: [] });
    setTimes([]);
    setConflictTickets([]);
    setConfirmTime(null);
  }

  async function loadTables() {
    let response = await ToryService.getReroute();
    // console.log(response);
    setFerries(response);
  }

  async function toggleArchive() {
    // DO POST
    let archiveFerries = await ToryService.getRerouteArchive();

    setShowArchive(!showArchive);
    setArchiveFerries(archiveFerries);
  }

  async function rerouteFerry(time) {
    // console.log(time);
    // console.log(journey);
    // console.log(chosenDate);
    let result = await ToryService.rerouteFerry(journey, chosenDate.getTime(), time);
    // console.log(result);
    if (result.status === 200) {
      message.success("Ferry Rerouted");
      resetForm();
      loadTables();
    } else if (result.status === "CONFLICTS") {
      message.info("Please confirm");
      // CONFLICTS
      // CONFIRM TIME
      setConfirmTime(time);
      setConflictTickets(result.tickets);
    } else {
      message.error("Couldn't Reroute Ferry");
    }
  }

  async function confirmRerouteFerry() {
    // console.log(time);
    // console.log(journey);
    // console.log(chosenDate);
    let result = await ToryService.confirmRerouteFerry(
      journey,
      chosenDate.getTime(),
      confirmTime
    );
    // console.log(result);
    if (result.status === 200) {
      message.success("Ferry Rerouted");
      resetForm();
      loadTables();
    } else {
      message.error("Couldn't Reroute Ferry");
    }
  }

  async function undelete(id) {
    // console.log(id);
    let result = await ToryService.unRerouteFerry(id);
    if (result === 200) {
      message.success("Ferry Reroute Cancelled");
      resetForm();
      loadTables();
    } else {
      message.error("Couldn't undo reroute Ferry");
    }
  }

  useEffect(() => {
    // LOAD TABLES
    loadTables();
    // console.log("[] effect run");
  }, []);

  useEffect(() => {
    // console.log("useEffect only for time");
    loadTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDate, journey]);

  function changeJourney(journey) {
    setJourney(journey);
    // loadTimes()
  }
  function changeDate(date, dateString) {
    if (date !== null) {
      // console.log("non null date");
      // console.log(Constants.getEpochDate(date._d));
      setChosenDate(new Date(Constants.getEpochDate(date._d)));
      // loadTimes();
    }
  }

  async function loadTimes() {
    // console.log(chosenDate)
    if (chosenDate !== 0) {
      // console.log(journey);
      // console.log(chosenDate)
      let result = await ToryService.getDateTimes(journey, chosenDate);
      // console.log(result);
      setTimes(Constants.getOneDayTimesArray(result));
    } else {
      setTimes([]);
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Reroute"
        extra={<LogoutButton />}
      ></PageHeader>

      <div style={{ margin: "8px 16px 0" }}>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
          <Col className="gutter-row" xs={24} md={12}>
            <Card title="Reroute Ferry" bordered={false}>
              <Space
                direction="vertical"
                size="middle"
                style={{ maxWidth: "100%", width: "100%" }}
              >
                <Typography.Title level={5}>Journey</Typography.Title>
                <Radio.Group
                  value={journey}
                  onChange={(e) => changeJourney(e.target.value)}
                  buttonStyle="solid"
                >
                  <Radio.Button value="dm">Departing Magheroarty</Radio.Button>
                  <Radio.Button value="dt">Departing Tory Island</Radio.Button>
                </Radio.Group>
                <Typography.Title level={5}>Date</Typography.Title>

                <DatePicker
                  value={new moment(chosenDate)}
                  onChange={changeDate}
                />

                <Typography.Title level={5}>Times</Typography.Title>
                {times.length === 0 ? (
                  <Typography.Text>No Times Available</Typography.Text>
                ) : (
                  <CancelButtons cancelFerry={rerouteFerry} ferries={times} />
                )}
                {confirmTime ? (
                  <Space direction="vertical" size="middle">
                    <Typography.Title level={5}>
                      Confirm Reroute
                    </Typography.Title>
                    <Row>
                      {`This change conflicts with ${conflictTickets.length}
                      tickets. Please confirm to confirm reroute and send
                      notification.`}
                    </Row>
                    <Button type="primary" danger onClick={confirmRerouteFerry}>
                      Confirm
                    </Button>
                  </Space>
                ) : (
                  <></>
                )}
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Card title="Status" bordered={false}>
              {ferries === 0 ? (
                <Row justify="center">
                  <Spin />
                </Row>
              ) : (
                <CancelTables ferries={ferries} undelete={undelete} />
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <div style={{ margin: "24px 16px 0" }}>
        <Divider orientation="left">
          <Button
            icon={showArchive ? <UpSquareOutlined /> : <DownSquareOutlined />}
            onClick={toggleArchive}
            type="outline"
            color="secondary"
          >
            {showArchive ? "Hide" : "Show"} Archive{" "}
          </Button>
        </Divider>
        {showArchive ? <ArchiveTable archiveFerries={archiveFerries} /> : <></>}
      </div>
    </>
  );
}

export default Reroute;

const CancelTables = (props) => {
  return (
    <>
      <CancelTable
        journey="dm"
        ferries={props.ferries.dm}
        undelete={props.undelete}
      />
      <br />
      <CancelTable
        journey="dt"
        ferries={props.ferries.dt}
        undelete={props.undelete}
      />
    </>
  );
};

const CancelTable = (props) => {
  // console.log(props.ferries);

  let data = [];

  props.ferries.map((i, index) => {
    let j = i;
    j.key = i.id;
    return data.push(j);
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => <span>{Constants.getDateString(value)}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (value) => <span>{Constants.getTimestamp(value)}</span>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value) => (
        <>
          <Popconfirm
            title="Are you sure undo this reroute?"
            okText="Yes"
            onConfirm={() => props.undelete(value)}
            onCancel={Constants.cancel}
            cancelText="No"
          >
            <Button danger>
              {" "}
              Undo <UpCircleOutlined twoToneColor="#ff4d4f" />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const journey = Constants.getJourneyString(props.journey);

  return (
    <>
      <Typography.Title level={5}>{journey}</Typography.Title>
      <Table dataSource={data} columns={columns} pagination={false} />
    </>
  );
};

const CancelButtons = (props) => {
  return (
    <>
      {props.ferries.map((i, index) => {
        return (
          <span key={index} className={"p-1"}>
            <Popconfirm
              title="Are you sure reroute this ferry?"
              okText="Yes"
              onConfirm={() => props.cancelFerry(i.time)}
              onCancel={Constants.cancel}
              cancelText="No"
            >
              <Button danger icon={<CloseCircleOutlined />}>
                {" "}
                {Constants.getTimestamp(i.time)}{" "}
              </Button>
            </Popconfirm>
          </span>
        );
      })}
    </>
  );
};

const ArchiveTable = (props) => {
  console.log(props.archiveFerries);

  let archiveData = [];

  props.archiveFerries.dm.map((i, index) => {
    let j = i;
    j.key = i.id;
    j.journey = "dm";
    return archiveData.push(j);
  });
  props.archiveFerries.dt.map((i, index) => {
    let j = i;
    j.journey = "dt";
    j.key = i.id;
    return archiveData.push(j);
  });

  archiveData.sort((a, b) => {
    return a.time - b.time;
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (value) => (
        <span>{Constants.getIDString(value).toUpperCase()}</span>
      ),
    },
    {
      title: "Journey",
      dataIndex: "journey",
      key: "journey",
      render: (value) => <span>{Constants.getJourneyString(value)}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => <span>{Constants.getDateString(value)}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (value) => <span>{Constants.getTimestamp(value)}</span>,
    },
  ];

  return (
    <>
      <Table dataSource={archiveData} columns={columns} pagination={false} />
    </>
  );
};
