import React, { useState } from "react";

import { Divider, Button, Drawer, Row, Col, Checkbox, Space, message, Typography, TimePicker } from "antd";

import moment from "moment";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";

import * as ToryService from "../../services/ToryService";
import * as Constants from "../../utils/Constants";
import { JourneyPicker } from "../../components/common/JourneyPicker";
import { useEffect } from "react";

const format = "HH:mm";

export function NewTimePanel(props) {
    const { width, addNew, onClose, visible } = props;

    // console.log(visible);

    const [journey, setJourney] = useState("dm");
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const [time, setTime] = useState("00:00");
    const [addMore, setAddMore] = useState(false);

    useEffect(() => {
        if (visible) {
            setJourney(visible.journey);
            setMonths(visible.months);
            setDays(visible.days);
        }
    }, [visible]);

    function resetForm() {
        // console.log("reset form");
        setJourney("dm");
        setMonths([]);
        setDays([]);
        setTime("12:00");
    }

    async function saveFerry() {
        const meta = {
            createdOn: new Date().getTime(),
            createdBy: "admin"
        };

        const newTime = {
            journey: journey,
            months: months,
            days: days,
            time: Constants.getNumberFromTimeString(time),
            archive: false,
            meta: meta
        };

        let response = await ToryService.addMasterTime(newTime);
        console.log(response);
        if (response.status === 200) {
            if (!addMore) {
                resetForm();
            }
            // closeDrawer
            console.log(response);
            addNew(response.data, addMore);
        } else {
            message.error("Couldn't Add Time");
        }
    }

    function selectJourney(e) {
        setJourney(e.target.value);
    }

    function selectMonths(e) {
        // console.log(e);
        // console.log(definitions[e.target.value].monthDefinitions);
        // setMonths(definitions[e.target.value].monthDefinitions);
        // setDays(definitions[e.target.value].dayDefinitions);
        // console.log(e);
        setMonths(e);
    }

    function selectDays(e) {
        // console.log(e.target.value);
        // setChosenDay(days[e.target.value]);
        setDays(e);
    }

    function selectTime(time, timeString) {
        setTime(timeString);
    }

    return (
        <Drawer
            title="Add New Ferry Time"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible}
        >
            <Space direction="vertical" size="middle">
                <Typography.Text level={5}>Journey</Typography.Text>

                <JourneyPicker journey={journey} onJourneyChange={selectJourney} />

                {/* <Radio.Group
                    value={journey}
                    onChange={selectJourney}
                    buttonStyle="solid"
                >
                    <Radio.Button value="dm">Departing Burtonport</Radio.Button>
                    <Radio.Button value="dt">Departing Arranmore</Radio.Button>
                </Radio.Group> */}

                <Typography.Text level={5}>Months</Typography.Text>

                {/* <Checkbox.Group options={Constants.monthsCheckBox} defaultValue={[]} onChange={selectMonths} /> */}
                <Checkbox.Group style={{ width: "100%" }} value={months} onChange={selectMonths}>
                    <Row>
                        {Constants.months.map((i, index) => {
                            return (
                                <Col key={i} span={8}>
                                    <Checkbox checked={months.includes(index)} value={index}>
                                        {Constants.months[index]}
                                    </Checkbox>
                                </Col>
                            );
                        })}
                    </Row>
                </Checkbox.Group>

                <Typography.Text level={5}>Days</Typography.Text>
                <Checkbox.Group style={{ width: "100%" }} value={days} onChange={selectDays}>
                    <Row>
                        {Constants.days.map((i, index) => {
                            return (
                                <Col key={i} span={8}>
                                    <Checkbox checked={days.includes(index)} value={index}>
                                        {Constants.days[index]}
                                    </Checkbox>
                                </Col>
                            );
                        })}
                    </Row>
                </Checkbox.Group>

                <Typography.Text level={5}>Time</Typography.Text>
                <TimePicker value={moment(time, format)} onChange={selectTime} format={format} />

                <Checkbox checked={addMore} onChange={(v) => setAddMore(v.target.checked)}>
                    Add More Times?
                </Checkbox>

                {days.length !== 0 && months.length !== 0 ? (
                    <div style={{ margin: "24px 16px 0" }}>
                        <Divider orientation="right">
                            <Button onClick={saveFerry} icon={<SaveOutlined />} type="primary">
                                Save
                            </Button>
                        </Divider>
                    </div>
                ) : (
                    <></>
                )}
            </Space>
        </Drawer>
    );
}
