export function getDeckSpaceTotal(tickets) {
    let carsCount = 0;
    tickets.forEach((t) => {
        var passengers = 0;
        passengers += t.passengers.map((p) => p.deckSpaces * p.quantity).reduce((total, inc) => (total += inc), 0);
        carsCount += passengers;
    });
    return carsCount;
}

export function getVehicleTotal(tickets, deckSpaces) {
    let carsCount = 0;
    tickets.forEach((t) => {
        var passengers = 0;
        passengers += t.passengers
            .filter((p) => p.deckSpaces === deckSpaces)
            .map((p) => p.quantity)
            .reduce((total, inc) => (total += inc), 0);
        carsCount += passengers;
    });
    return carsCount;
}

export function getSingleTicketVehicleTotal(ticket, deckSpaces) {
    var carsCount = 0;
    carsCount += ticket.passengers
        .filter((p) => p.deckSpaces === deckSpaces)
        .map((p) => p.quantity)
        .reduce((total, inc) => (total += inc), 0);
    return carsCount;
}

// export function getVehicleTotalPassengerList(passengers, deckSpaces) {
//     var carsCount = 0;
//     carsCount += passengers
//         .filter((p) => p.deckSpaces == deckSpaces)
//         .map((p) => p.quantity)
//         .reduce((total, inc) => (total += inc));
//     return carsCount;
// }
