import React from "react";
import { Space, Typography } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { format } from "date-fns";
export function TicketFilterDate(props) {
    const { label, date, setDate } = props;

    function changeDate(inputdate) {
        if (inputdate == null) {
            setDate(null);
            return;
        }
        const newDate = new Date(inputdate._d);
        newDate.setHours(12, 0, 0, 0, 0);
        setDate(newDate);
    }

    return (
        <Space>
            <Typography.Text style={{ fontSize: "0.8em" }}>{`${label}`.toUpperCase()}:</Typography.Text>
            <DatePicker defaultValue={date ? moment(date) : undefined} onChange={(v) => changeDate(v)} />
            {/* <Typography.Text style={{ fontSize: "0.8em" }}>{`${date ? format(date, "yyyy-MM-dd") : "no date"}`}</Typography.Text> */}
        </Space>
    );
}
