import React from "react";
import { Tag } from "antd";

export function CheckInStatus(props) {
    const { passengers, status } = props;

    function getCheckInStatusString(givenStatus) {
        switch (givenStatus) {
            case 1:
                return "Checked In";
            case 2:
                return "Checked In";
            case 3:
                return "In Port, with Label";
            default:
                return "Not Checked In";
        }
    }

    function getCheckInStatusColor(givenStatus) {
        switch (givenStatus) {
            case 1:
                return "green";
            case 2:
                return "green";
            case 3:
                return "orange";
            default:
                return "";
        }
    }

    return passengers.map((p) => p.quantity * p.deckSpaces > 0).reduce((total, increment) => (total += increment), 0) > 0 ||
        status !== 0 ? (
        <Tag color={getCheckInStatusColor(status)}>{getCheckInStatusString(status)}</Tag>
    ) : (
        <></>
    );
}
