import React from "react";
import { Typography, Space, Button, Tooltip, Table, message } from "antd";

import { CarOutlined, UserOutlined, UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";

import { getFormattedPrice } from "../../utils/Constants";
import { EditOutlined, CrownOutlined, ProfileOutlined, CheckCircleTwoTone, GlobalOutlined } from "@ant-design/icons";
import * as GlobalUtils from "../../utils/Global";
import { updatePriceRank } from "../../services/PriceService";
//
export default function PricesTable(props) {
    const { data, viewPrice, editPrice, reload } = props;

    data.map((d) => (d.key = d._id));

    async function updateRank(id, newRank) {
        // console.log(newRank);
        let response = await updatePriceRank(id, newRank);
        if (response.status === 200) {
            message.success("Price Rank Updated");
            reload();
        } else {
            message.error("Price Rank Change Failed");
        }
    }

    const columns = [
        {
            title: "Rank",
            dataIndex: "rank",
            key: "rank",
            render: (value, row) => {
                const currentRank = row.rank ?? 0;
                // console.log(typeof value);
                return (
                    <Space direction="horizontal">
                        <Button onClick={() => updateRank(row._id, currentRank - 1)} icon={<UpCircleOutlined />}></Button>
                        {/* <Typography.Text>{`${row.rank}`}</Typography.Text> */}
                        <Button onClick={() => updateRank(row._id, currentRank + 1)} icon={<DownCircleOutlined />}></Button>
                    </Space>
                );
            },
            sorter: (a, b) => a.rank - b.rank,
            defaultSortOrder: "ascend"
        },
        {
            title: `Code`,
            dataIndex: "code",
            key: "code",
            render: (value, row) => (
                <Button type="link" onClick={() => viewPrice(row)}>
                    {`[${value}]`}
                </Button>
            ),
            sorter: (a, b) => {
                if (a._id < b._id) {
                    return -1;
                }
                if (a._id > b._id) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Ticket Name",
            dataIndex: "label",
            key: "label",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Typography.Text>{`${value}`}</Typography.Text>
                    <Typography.Text style={{ fontSize: "0.8em", paddingTop: "0px" }}>{`(${record.labelIE})`}</Typography.Text>
                </Space>
            ),
            // sorter: true,
            sorter: (a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            }
        },
        // {
        //   title: "Cars",
        //   dataIndex: "cars",
        //   key: "cars",
        //   render: (value, record) => (
        //     <Typography.Text>{`${value}`}</Typography.Text>
        //   ),
        //   sorter: (a, b) => {
        //     if (a.carCount < b.carCount) {
        //       return -1;
        //     }
        //     if (a.carCount > b.carCount) {
        //       return 1;
        //     }
        //     return 0;
        //   },
        // },
        {
            title: "Passengers",
            key: "passengers",
            dataIndex: "passengers",
            render: (value, row) => (
                <Space direction="horizontal">
                    <Space direction="horizontal" size="small">
                        <UserOutlined />
                        <Typography.Text>{row.passengers}</Typography.Text>
                    </Space>
                    {GlobalUtils.cars ? (
                        <Space direction="horizontal" size="small">
                            <CarOutlined />
                            <Typography.Text>{row.cars}</Typography.Text>
                        </Space>
                    ) : (
                        <></>
                    )}
                </Space>
            ),
            sorter: (a, b) => {
                const aIndex = a.cars * 100 + a.passengers;
                const bIndex = b.cars * 100 + b.passengers;
                if (aIndex < bIndex) {
                    return -1;
                }
                if (aIndex > bIndex) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Price",
            key: "price",
            dataIndex: "price",
            render: (value, record) => <Typography.Text>{`${getFormattedPrice(value)}`}</Typography.Text>,
            filters: [
                {
                    text: "Free",
                    value: 0
                },
                {
                    text: "Paid",
                    value: 1
                }
            ],
            onFilter: (value, record) => {
                if (value === 0) {
                    return record.price === 0;
                } else {
                    return record.price > 0;
                }
            },
            sorter: (a, b) => a.price - b.price
        },
        {
            title: "Islander",
            dataIndex: "islander",
            key: "islander",
            render: (value, record, type) => <Typography.Text>{`${value ? "Islander" : "Visitor"}`}</Typography.Text>,
            filters: [
                {
                    text: "Islander",
                    value: true
                },
                {
                    text: "Visitor",
                    value: false
                }
            ],
            onFilter: (value, record) => record.islander === value,
            sorter: (a, b) => {
                if (a.islander < b.islander) {
                    return -1;
                }
                if (a.islander > b.islander) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Admin",
            dataIndex: "adminOnly",
            key: "adminOnly",
            render: (value, record, type) =>
                value ? (
                    <Tooltip title="Only Admins Can Add This Ticket">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </Tooltip>
                ) : (
                    <Tooltip title="Available To Buy Online">
                        <GlobalOutlined twoToneColor="#4ad9f9" />
                    </Tooltip>
                ),
            // filters: [
            //   {
            //     text: "Islander",
            //     value: true,
            //   },
            //   {
            //     text: "Visitor",
            //     value: false,
            //   },
            // ],
            // onFilter: (value, record) => record.islander === value,
            sorter: (a, b) => {
                if (a.islander < b.islander) {
                    return -1;
                }
                if (a.islander > b.islander) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Actions",
            key: "actions",
            render: (value, row) => (
                <Button type="ghost" onClick={() => editPrice(row)} icon={<EditOutlined />}>
                    Edit
                </Button>
            )
        }
    ];

    return (
        <>
            {/* <Typography.Text>Prices Table</Typography.Text> */}
            <Table dataSource={data} columns={columns} pagination={data.length > 20 ? true : false} />
        </>
    );
}
