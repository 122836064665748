import React from "react";
// CSS
import "../../pages/css/Timetables.css";

import {
    Button,
    Space,
    // Popconfirm,
} from "antd";

import {
    // DeleteTwoTone,
    EditTwoTone,
    PlusSquareTwoTone,
} from "@ant-design/icons";

import * as Constants from "../../utils/Constants";
import { getMonthlyData, getDailyData } from "../../utils/Timetable.data";

export function DeleteButtons(props) {

    const { months, only, times, journey, days, updateFerry, showDrawer } = props;

    // console.log(props);
    const monthlyTimes = getMonthlyData(months, times);
    // console.log(monthlyTimes);
    const dayTimes = getDailyData(journey, days, monthlyTimes, only);
    // console.log(dayTimes);

    return (
        <Space direction="horizontal" wrap={true}>
            {dayTimes.length > 0
                ?
                dayTimes.map((i, index) => {
                    // console.log(i);
                    return <Button
                        key={index}
                        onClick={() => updateFerry(i)}>
                        {` ${Constants.getTimestamp(i.time)} `}
                        <EditTwoTone twoToneColor="#FFA44D" />
                    </Button>
                    // <Popconfirm
                    //     key={index}
                    //     title="Are you sure delete this time?"
                    //     okText="Yes"
                    //     onConfirm={() => deleteFerry(i._id)}
                    //     onCancel={Constants.cancel}
                    //     cancelText="No"
                    // >
                    // </Popconfirm>

                })
                : <></>}
            <Button
                onClick={() => showDrawer({ months, days, journey })}
                icon={<PlusSquareTwoTone twoToneColor="#1890ff" />}
            >
                {` Add`}
            </Button>

        </Space>
    );
};
