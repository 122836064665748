import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getPrices() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.prices, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function addPrice(price, userid) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: userid
    };

    let priceObject = {
        price: price,
        ...meta
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.adminPricesAdd, priceObject, headers)
        .then((res) => {
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function updatePrice(price, userid) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: userid
    };

    let priceObject = {
        ...price,
        ...meta
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.adminPricesUpdate, priceObject, headers)
        .then((res) => {
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function removePrice(price) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.adminPricesDelete, { id: price }, headers)
        .then((res) => {
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function updatePriceRank(id, rank) {
    const headers = await Firebase.getHeaders();
    return await axios
        .post(Constants.getApiUrl() + Constants.adminPricesRankUpdate, { id, rank }, headers)
        .then((res) => {
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
