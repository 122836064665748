import React from "react";

import { Typography, Select } from "antd";

import * as Constants from "../../../utils/Constants";

const { Option } = Select;

export default function TimeButtons(props) {
    const { timeArray, time, setTime } = props;

    if (timeArray === null) {
        return <Typography.Text>No ferries available</Typography.Text>;
    } else {
        if (timeArray.length === 0) {
            return <Typography.Text>No ferries available</Typography.Text>;
        } else {
            return (
                <Select
                    onSelect={(v, opt) => {
                        // console.log(v);
                        // console.log(opt);
                        setTime(v[0], v[1] === "reroute" ? true : false);
                    }}
                    placeholder={"Select Ferry Time"}
                    value={
                        time === null || time === undefined
                            ? null
                            : Constants.getTimestamp(time)
                    }
                >
                    {timeArray
                        .sort((a, b) => a.time - b.time)
                        .map((t, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={[t.time, t.ferry]}
                                    // onClick={() => {
                                    //     console.log(
                                    //         `setting: ${t.time} ${t.ferry}`
                                    //     );
                                    //     setTime(
                                    //         t.time,
                                    //         t.ferry === "reroute" ? true : false
                                    //     );
                                    // }}
                                >
                                    {Constants.getTimestamp(t.time)}
                                    {t.ferry === "reroute" ? "*" : ""}
                                </Option>
                            );
                        })}
                </Select>
            );
        }
    }
}
