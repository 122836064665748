import React, { useState } from "react";
import { Modal, Button, message, Space } from "antd";
import * as RecurringTicketService from "../../../services/RecurringTicketService";
import TicketCard from "../../dashboard/mobile/TicketCard";
import { Spin } from "antd";

export default function PreviewRecurringTicketsModal(props) {
    const { ticket } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [previews, setPreviews] = useState([]);

    const showModal = () => {
        // console.log(ticket);
        if (ticket) {
            getPreviewTickets();
            setIsModalVisible(true);
        }
    };

    async function getPreviewTickets() {
        setLoading(true);
        let response = await RecurringTicketService.getPreviewTicket(ticket);
        if (response.status === 200) {
            const _tickets = response.tickets;
            _tickets.forEach((t) => {
                t._id = `${t.uid}${t.ticket.outDate}`;
            });
            setPreviews(_tickets);
        } else {
            message.error("You can't preview tickets at this time");
        }
        setLoading(false);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="ghost" onClick={showModal}>
                Preview Tickets
            </Button>
            <Modal
                title="Ticket Preview"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {loading ? (
                    <Spin />
                ) : (
                    <Space direction={"vertical"} style={{ width: "100%" }}>
                        {previews.map((t, index) => {
                            return (
                                <TicketCard
                                    t={t}
                                    key={index}
                                    viewTicket={() => console.log("view")}
                                    editTicket={() => console.log("edit")}
                                />
                            );
                        })}
                    </Space>
                )}
            </Modal>
        </>
    );
}
