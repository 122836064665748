import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getBlocksForDate(date) {
    const headers = await Firebase.getHeaders();

    const data = { date };
    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.getTicketBlock}`,
            data,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
export async function getBlocksForDateTime(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const data = { journey, date, time };
    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.getTicketBlock}`,
            data,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function addBlock(ferry) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.addTicketBlock}`,
            ferry,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function removeBlock(id) {
    const headers = await Firebase.getHeaders();

    const data = { id };

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.deleteTicketBlock}`,
            data,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
