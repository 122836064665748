import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getStatsData(journey, start, end) {
    const headers = await Firebase.getHeaders();

    const data = { journey, start, end };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminStatsData}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getRevenueData(date) {
    const headers = await Firebase.getHeaders();

    const data = { date };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminRevenueData}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getRevenueTableData(date) {
    const headers = await Firebase.getHeaders();

    const data = { date };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminRevenueTableData}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getRevenueDataRange(start, end) {
    const headers = await Firebase.getHeaders();

    const data = { start, end };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminRevenueRangeData}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
