import * as Constants from "../utils/Constants";
import * as Firebase from "./firebase";
import axios from "axios";
// import { title } from 'process';
// const qs = require('querystring');

export async function getGoldenTickets() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(`${Constants.getApiUrl()}${Constants.goldenTickets}`, headers)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getGoldenRedeemedTickets() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(`${Constants.getApiUrl()}${Constants.goldenRedeemedTickets}`, headers)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}
export async function addGoldenTicket(time, message, priceLimit) {
    const headers = await Firebase.getHeaders();

    const object = { time, message, priceLimit };

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.goldenTickets}`, object, headers)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function editGoldenTicket(id, time, message, priceLimit) {
    const headers = await Firebase.getHeaders();

    const object = { id, time, message, priceLimit };

    return await axios
        .put(`${Constants.getApiUrl()}${Constants.goldenTickets}`, object, headers)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function deleteGoldenTicket(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .delete(`${Constants.getApiUrl()}${Constants.goldenTickets}/${id}`, headers)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}
