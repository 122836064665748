import React, { useState, useEffect, useCallback } from "react";
// import axios from 'axios';
// CSS
import "./css/Timetables.css";

import { Tabs, PageHeader, Spin, Row, message, Typography } from "antd";

import * as ToryService from "../services/ToryService";
import * as Constants from "../utils/Constants";
import LogoutButton from "../components/LogoutButton";
import { NewTimePanel } from "../components/timetables/NewTimePanel";
import { EditTimePanel } from "../components/timetables/EditTimePanel";
import { MonthlyMasterTimetable } from "../components/timetables/MonthlyMasterTimetable";

const { TabPane } = Tabs;

export default function Timetables() {
    const [definitions, setDefinitions] = useState([]);
    const [times, setTimes] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [width] = useState(window.innerWidth);

    // EDIT
    const [editTime, setEditTime] = useState(null);

    function showDrawer(timesInfo) {
        setVisible(timesInfo);
    }

    function onClose() {
        setVisible(false);
        // loadTables();
    }

    function addTime(newTime, close) {
        // console.log(newTime)
        // console.log(close);
        let _times = times;
        _times.push(newTime);
        setTimes(_times);
        if (!close) {
            onClose();
        }
    }

    function updateTime(editedTime) {
        // console.log(editedTime);
        let _times = times.filter((t) => t._id !== editedTime.id);
        _times.push(editedTime);
        setTimes(_times);
    }

    async function deleteFerry(deleteid) {
        //
        let response = await ToryService.deleteMasterTime(deleteid);
        if (response.status === 200) {
            message.success("Deleted");
            let _times = times.filter((t) => t._id !== deleteid);
            setTimes(_times);
            setEditTime(false);
            // loadTables();
        } else {
            message.error("There was an error");
        }
    }

    function getDefinitions(definitions) {
        // console.log(definitions.length);
        let definitionsArray = [];
        if (definitions.length === 0) {
            // console.log(definitions);
            return [];
        } else {
            // console.log("here");
            for (let i = 0; i < definitions.length; i++) {
                const month = definitions[i].m.sort();
                const days = definitions[i].d;
                // console.log(days);
                const only = definitions[i].only;
                if (month.length === 1) {
                    definitionsArray.push({
                        monthLabel: Constants.months[month[0]],
                        monthDefinitions: month.sort(),
                        dayDefinitions: days,
                        onlyDefinitions: only
                    });
                } else {
                    // definitionsArray.push()
                    definitionsArray.push({
                        monthLabel:
                            Constants.months[month[0]] +
                            " - " +
                            Constants.months[month[month.length - 1]],
                        monthDefinitions: month.sort(),
                        dayDefinitions: days,
                        onlyDefinitions: only
                    });
                }
            }
            return definitionsArray;
        }
    }

    const loadTables = useCallback(async () => {
        // console.log("load")
        setLoading(true);
        let response = await ToryService.getPublicTimes();
        const _definitions = getDefinitions(response.master.definitions);
        const _times = response.master.ferries;
        setDefinitions(_definitions);
        setTimes(_times);
        setLoading(false);
    });

    useEffect(() => {
        loadTables();
    }, []);

    // const definitionsLength = definitions.length > 0 ? true : false;

    return (
        <>
            <PageHeader
                ghost={false}
                title="Timetables"
                extra={<LogoutButton />}
            ></PageHeader>

            {/* <div style={{ margin: "24px 16px 0" }}>
        <Divider orientation="left">
          <Typography.Title level={5}>Months</Typography.Title>
        </Divider>
      </div> */}
            <div style={{ margin: "24px 16px 0" }}>
                {loading ? (
                    <Row justify="center">
                        <Spin />
                    </Row>
                ) : definitions.length > 0 && !loading ? (
                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                        {definitions.map((i, index) => (
                            <TabPane tab={`Month: ${i.monthLabel}`} key={index}>
                                <MonthlyMasterTimetable
                                    definitions={i}
                                    times={times}
                                    showDrawer={showDrawer}
                                    loadTables={loadTables}
                                    // deleteFerry={deleteFerry}
                                    updateFerry={setEditTime}
                                />
                            </TabPane>
                        ))}
                    </Tabs>
                ) : (
                    <Row justify="center">
                        <Typography.Text>
                            There are no definitions defined for the timetables
                        </Typography.Text>
                    </Row>
                )}
            </div>

            <NewTimePanel
                addNew={(v, w) => addTime(v, w)}
                onClose={onClose}
                onSave={loadTables}
                visible={visible}
                width={width > 900 ? 640 : width}
                definitions={definitions}
            />

            <EditTimePanel
                onClose={() => setEditTime(false)}
                onEdit={updateTime}
                deleteFerry={deleteFerry}
                visible={editTime}
                width={width > 900 ? 640 : width}
            />
        </>
    );
}
