import React from "react";

import { Tag } from "antd";

import * as Constants from "../../utils/Constants";

export default function TicketStatus(props) {
    return (
        // <Tooltip title={Constants.getStatusString(props.value)}>
        <Tag color={Constants.getPaidStatusColour(props.value)}>
            {Constants.getPaidStatus(props.value)}
        </Tag>
        // </Tooltip>
    );
}
