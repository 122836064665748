import React, { useState, useEffect } from "react";

import {
    Button,
    Col,
    Card,
    Spin,
    Drawer,
    Space,
    Avatar,
    Input,
    Divider,
    message,
    Typography
} from "antd";

import { CloseSquareOutlined, SendOutlined } from "@ant-design/icons";

import * as ToryService from "../../services/ToryService";

const { Meta } = Card;

const SendNotificationPanel = (props) => {
    const { width, onClose, visible, singleAccount } = props;

    const [title, setTitle] = useState("Ferry Notification");
    const [bodyText, setBodyText] = useState("");

    async function sendNotification(account, title, body) {
        // console.log(account.token);
        // console.log(title);
        // console.log(body);
        if (account.token !== "" || account.token !== null) {
            let response = await ToryService.sendNotification(
                account,
                title,
                body
            );
            // let response = { "status": 200 };
            if (response.status === 200) {
                message.success("User Notified");
                onClose();
            } else {
                message.error("Can't Send Notification to User");
            }
        } else {
            message.error("Can't Send Notification to User");
        }
    }

    return (
        <Drawer
            title="Send Notification"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible}
        >
            {singleAccount ? (
                <Space
                    size={"large"}
                    direction="vertical"
                    style={{ width: "100%" }}
                >
                    <Typography.Text>Sending to User:</Typography.Text>
                    <Card>
                        <Meta
                            avatar={
                                singleAccount.photoURL ? (
                                    <Avatar src={singleAccount.photoURL} />
                                ) : (
                                    <Avatar src={singleAccount.photoURL} />
                                )
                            }
                            title={singleAccount.displayName}
                            description={singleAccount.email}
                        />
                    </Card>

                    <Input
                        addonBefore="Title"
                        defaultValue="Ferry Notification"
                        onChange={(e) => setTitle(e.target.value)}
                    />

                    <Input
                        addonBefore="Message"
                        defaultValue=""
                        onChange={(e) => setBodyText(e.target.value)}
                    />

                    <Divider orientation="right">
                        <Button
                            icon={<SendOutlined />}
                            onClick={() =>
                                sendNotification(singleAccount, title, bodyText)
                            }
                            type="primary"
                        >
                            {" "}
                            Send Notification{" "}
                        </Button>
                    </Divider>
                </Space>
            ) : (
                <Col xs={18} md={8} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <Spin />
                    </div>
                </Col>
            )}
        </Drawer>
    );
};

export default SendNotificationPanel;
