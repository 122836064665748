import React, { useEffect, useState } from "react";

import {
    PageHeader,
    Row,
    Col,
    Space,
    Card,
    DatePicker,
    Spin,
    Radio,
    Divider
    // Typography,
    // Statistic
} from "antd";

import LogoutButton from "../components/LogoutButton";
import { RevenueCounter } from "../components/statistics/RevenueCounter";
import moment from "moment";
import { format } from "date-fns";
// import { getDateFerryTimes } from "../utils/TimetableUtils";
// import * as StatsUtils from "../utils/StatisticUtils";
// import * as CarUtils from "../utils/CarUtils";
// import * as PassengerUtils from "../utils/PassengerUtils";

import * as Constants from "../utils/Constants";
// import * as TicketService from "../services/TicketService";
// import PassengerCountChart from "../components/statistics/PassengerCount";
// import TicketCountChart from "../components/statistics/TicketCountChart";
// import { TicketIcon } from "../assets/TicketIcon";
import * as DashboardService from "../services/DashboardService";
import TotalPassengerCountChart from "../components/statistics/TotalPassengerCount";
import TotalTicketTypeChart from "../components/statistics/TotalTicketTypeCount";
import TotalTicketPassengerTypeChart from "../components/statistics/TotalTicketPassengerTypeCount";
import PaymentsCount from "../components/statistics/PaymentsCount";

// const { Meta } = Card;

const { RangePicker } = DatePicker;

export default function Statistics(props) {
    const [singleDay, setSingleDay] = useState(true);
    const [date, setDate] = useState(new Date());
    const [range, setRange] = useState([new Date(), new Date()]);
    const [filterJourney, setFilterJourney] = useState("all");
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        loadDashboardData();
    }, [date]);

    async function loadDashboardData() {
        setLoading(true);
        let dtData = await DashboardService.getDashboardDataForDate("dt", date.getTime());
        let dmData = await DashboardService.getDashboardDataForDate("dm", date.getTime());
        setDashboardData([...dmData.times, ...dtData.times]);
        setLoading(false);
        // setUpdate(update + 1);
    }

    useEffect(() => {
        // loadDashboardData();
        // if (filterJourney === "dt") {
        //     setDashboardData([...dtData.times]);
        // } else if (filterJourney === "dm") {
        //     setDashboardData([...dmData.times]);
        // } else {
        //     setDashboardData([...dmData.times, ...dtData.times]);
        // }
    }, [filterJourney]);

    const filteredData = dashboardData
        .filter((d) => {
            if (filterJourney === "dt") {
                return d.journey === "dt";
            } else if (filterJourney === "dm") {
                return d.journey === "dm";
            } else {
                return d;
            }
        })
        .sort((a, b) => a.time - b.time);

    return (
        <>
            <PageHeader ghost={false} title="Statistics" extra={<LogoutButton />}></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Space direction="horizontal">
                    <Space direction="horizontal">
                        {singleDay ? (
                            <DatePicker defaultValue={moment(date)} onChange={(v) => setDate(new Date(v._d))} />
                        ) : (
                            <RangePicker
                                defaultValue={[moment(range[0]), moment(range[1])]}
                                onChange={(v) => setRange([new Date(v[0]._d), new Date(v[1]._d)])}
                            />
                        )}
                    </Space>

                    {/* <Space direction="horizontal">
                        <Radio.Group
                            options={[
                                {
                                    label: `Day`,
                                    value: "day"
                                },
                                {
                                    label: `Range`,
                                    value: "range"
                                }
                            ]}
                            optionType="button"
                            onChange={(v) => {
                                if (v.target.value === "range") {
                                    setSingleDay(false);
                                } else {
                                    setSingleDay(true);
                                }
                            }}
                            value={singleDay === true ? "day" : "range"}
                        />
                    </Space> */}

                    <Space direction="horizontal">
                        <Radio.Group
                            options={[
                                {
                                    label: `All`,
                                    value: "all"
                                },
                                {
                                    label: `${Constants.getJourneyString("dm", false)}`,
                                    value: "dm"
                                },
                                {
                                    label: `${Constants.getJourneyString("dt", false)}`,
                                    value: "dt"
                                }
                            ]}
                            optionType="button"
                            onChange={(v) => setFilterJourney(v.target.value)}
                            value={filterJourney}
                        />
                    </Space>
                </Space>
                <Row gutter={[16, { sm: 16, md: 24 }]}>
                    <Col xs={24}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Space style={{ width: "100%" }} direction="vertical">
                                {loading ? <Spin /> : <TotalPassengerCountChart data={filteredData} date={date} journey={filterJourney} />}
                                {loading ? <Spin /> : <TotalTicketTypeChart data={filteredData} date={date} journey={filterJourney} />}
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <TotalTicketPassengerTypeChart data={filteredData} date={date} journey={filterJourney} />
                                )}
                                {/* {loading ? <Spin /> : <PaymentsCount data={filteredData} date={date} journey={filterJourney} />} */}
                                <RevenueCounter date={date} />
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </div>
        </>
    );
}
