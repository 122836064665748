import { Typography, Space, Row, Col, Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import * as Constants from "../../utils/Constants";
import * as StatsService from "../../services/StatsService";
import { message } from "antd";
import { add, format } from "date-fns";
import { LoadTicketViewerPanel } from "../tickets/View/LoadTicketViewerPanel";
import { Alert } from "antd";
//
export function RevenueTable(props) {
    const { date } = props;

    const [revenueData, setRevenueData] = useState([]);
    // app web cash kiosk card
    const [filterType, setFilterType] = useState("web");

    useEffect(() => {
        CollectRevenueTableStats();
    }, [date]);

    async function CollectRevenueTableStats() {
        // console.log(date);
        let { error, data } = await StatsService.getRevenueTableData(date);
        // console.log(response);
        if (error === 0) {
            setRevenueData(data);
        } else {
            message.error("Failed to collect revenue data");
        }
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Button type="link" onClick={() => setTicktId(value)}>
                        {`#${`${value}`.substring(`${value}`.length - 6)}`.toUpperCase()}
                    </Button>
                    {/* <Typography.Text>{`#${`${value}`.substring(`${value}`.length - 6)}`.toUpperCase()}</Typography.Text> */}
                </Space>
            )
        },
        {
            title: "Method",
            dataIndex: "method",
            key: "method",
            render: (value, record) => Constants.getMethodIcon(value)
        },
        {
            title: "Amount",
            dataIndex: "price",
            key: "price",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Typography.Text>{`€ ${(value / 100).toFixed(2)}`}</Typography.Text>
                </Space>
            )
        },
        {
            title: "Stripe Reference",
            dataIndex: "payment.stripePayment",
            key: "payment.stripePayment",
            render: (value, record) => {
                return record.method === "cash" ? (
                    <Typography.Text code>Cash Doesn't Use Stripe</Typography.Text>
                ) : (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://dashboard.stripe.com/payments/${record.payment.stripePayment}`}
                    >
                        {record.payment.stripePayment}
                    </a>
                );
            }
        },
        {
            title: "Date Purchased",
            dataIndex: "datecreated",
            key: "datecreated",
            render: (value, record) => <Typography.Text>{format(new Date(value), "do MMM yyyy HH:mm")}</Typography.Text>
            // render: (value, record) => <Typography.Text>{`${value}`}</Typography.Text>
        }
    ];

    const [ticketid, setTicktId] = useState("");
    function closeTicketViewer() {
        setTicktId("");
    }

    return (
        <Row gutter={[16, { sm: 16, md: 24 }]} style={{ width: "100%" }}>
            <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                    <Alert
                        description={`These revenues are calculated from ${format(date, "do MMM yyyy")} 00:00 to ${format(
                            add(date, { days: 1 }),
                            "do MMM yyyy"
                        )} 00:00`}
                    />
                    <Space direction="horizontal">
                        {["web", "app", "cash", "card", "kiosk"].map((type) => (
                            <Button onClick={() => setFilterType(type)} type={type === filterType ? "primary" : "default"}>
                                {`${type}`.toUpperCase()}
                            </Button>
                        ))}
                    </Space>
                    {/* <Typography.Text>{`${JSON.stringify(revenueData.filter((rd) => rd.type === filterType))}`}</Typography.Text>
                    <Typography.Text>{`${JSON.stringify(
                        revenueData.length > 0 ? revenueData.filter((rd) => rd.type === filterType)[0]["tickets"] : []
                    )}`}</Typography.Text> */}
                    <Table
                        columns={columns}
                        dataSource={revenueData.length > 0 ? revenueData.filter((rd) => rd.type === filterType)[0]["tickets"] : []}
                        footer={() => (
                            <Typography.Text>{`${
                                revenueData.length > 0
                                    ? `Total for the day via ${filterType}: € ${(
                                          revenueData
                                              .filter((rd) => rd.type === filterType)[0]
                                              ["tickets"].map((t) => t.price)
                                              .reduce((total, inc) => (total += inc), 0) / 100
                                      ).toFixed(2)}`
                                    : "No Revenue"
                            }`}</Typography.Text>
                        )}
                    />
                </Space>
            </Col>
            <LoadTicketViewerPanel open={ticketid != "" ? true : false} close={() => closeTicketViewer()} id={ticketid} />
        </Row>
    );
}
