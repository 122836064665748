import React from "react";
import { Space, Typography, Tag, Select, Button } from "antd";
import { getPaidStatusColour } from "../../../utils/Constants";

export function TicketFilterMethod(props) {
    const { filterMethod, setFilterMethod } = props;

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                // color={getPaidStatusColour(value)}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                    marginBottom: 2
                }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Space>
            <Typography.Text style={{ fontSize: "0.8em" }}>{`Method`.toUpperCase()}:</Typography.Text>
            <Button
                onClick={() => {
                    setFilterMethod(["web", "app", "inperson", "admin", "kiosk", "other"]);
                }}
            >
                All
            </Button>
            <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                value={filterMethod}
                style={{ width: "450px" }}
                options={[
                    { label: "Web", value: "web" },
                    { label: "App", value: "app" },
                    { label: "In Person", value: "inperson" },
                    { label: "Admin", value: "admin" },
                    { label: "Kiosk", value: "kiosk" },
                    { label: "Other", value: "other" }
                ]}
                onChange={(v) => {
                    console.log(v);
                    setFilterMethod(v);
                }}
            />
        </Space>
    );
}
