import React, { useState } from "react";
import { Typography, Drawer, Form, Input, Space, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import * as TicketService from "../../../services/TicketService";
import { message } from "antd";

export function EditNamePanel(props) {
    const { open, close, ticket, onUpdate } = props;

    async function updateNameEmail() {
        setIsLoading(true);
        let { error } = await TicketService.updateNameEmail(ticket._id, name, email);
        if (error === 0) {
            message.success("Updated Details");
            onUpdate(name, email);
        } else {
            message.error("Error Updating");
        }
        setIsLoading(false);
    }

    const [name, setName] = useState(ticket.name);
    const [email, setEmail] = useState(ticket.email);

    const [isLoading, setIsLoading] = useState(false);

    return (
        <Drawer open={open} onClose={close}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Form layout={"vertical"}>
                    <Form.Item label="Name">
                        <Input value={name} onChange={(v) => setName(v.target.value)} />
                    </Form.Item>
                    <Form.Item label="Message">
                        <Input value={email} onChange={(v) => setEmail(v.target.value)} />
                    </Form.Item>
                </Form>
                <Button type="primary" icon={<SaveOutlined />} onClick={() => updateNameEmail()} loading={isLoading}>
                    Save
                </Button>
            </Space>
        </Drawer>
    );
}
