export const passengerManifest = [
    {
        _id: "627f711144e928841d2d3232",
        label: "Adult",
        labelIE: "Adult",
        code: "adult",
        price: 1500,
        passengers: 1,
        cars: 0,
        islander: false,
        admin: false,
        rank: 0
    },
    {
        _id: "627f713544e928841d2d3233",
        label: "Student",
        labelIE: "Student",
        code: "student",
        price: 1000,
        passengers: 1,
        cars: 0,
        islander: false,
        admin: false,
        rank: 1
    },
    {
        _id: "627f722544e928841d2d3234",
        label: "Child",
        labelIE: "Child",
        code: "child",
        price: 700,
        passengers: 1,
        cars: 0,
        islander: false,
        admin: false,
        rank: 2
    },
    {
        _id: "627f87fcef69f4f67e45f53d",
        label: "Travel / Smart Pass",
        labelIE: "Travel / Smart Pass",
        code: "smartpass",
        price: 200,
        passengers: 1,
        cars: 0,
        islander: false,
        admin: false,
        rank: 2
    },
    {
        _id: "627f725144e928841d2d3235",
        label: "Car and Driver",
        labelIE: "Car and Driver",
        code: "cardriver",
        price: 3000,
        passengers: 1,
        cars: 1,
        islander: false,
        admin: false,
        rank: 3
    },
    {
        _id: "627f727e44e928841d2d3236",
        label: "Car, Driver and 1 Adult",
        labelIE: "Car, Driver and 1 Adult",
        code: "car1adult",
        price: 4500,
        passengers: 2,
        cars: 1,
        islander: false,
        admin: false,
        rank: 4
    },
    {
        _id: "627f751d44e928841d2d3237",
        label: "Car, Driver and 2 Adult",
        labelIE: "Car, Driver and 2 Adult",
        code: "car2adult",
        price: 6000,
        passengers: 3,
        cars: 1,
        islander: false,
        admin: false,
        rank: 5
    },
    {
        _id: "627f755244e928841d2d3238",
        label: "Car, Driver and 3 Adults",
        labelIE: "Car, Driver and 3 Adults",
        code: "car3adult",
        price: 7500,
        passengers: 4,
        cars: 1,
        islander: false,
        admin: false,
        rank: 6
    },
    {
        _id: "627f758244e928841d2d3239",
        label: "Family Ticket (2 Adult, 2 Childr.)",
        labelIE: "Family Ticket (2 Adult, 2 Childr.)",
        code: "family",
        price: 4500,
        passengers: 4,
        cars: 1,
        islander: false,
        admin: false,
        rank: 7
    },
    {
        _id: "627f75b644e928841d2d323a",
        label: "Small Van (eg. Caddy) and Driver",
        labelIE: "Small Van (eg. Caddy) and Driver",
        code: "van",
        price: 3000,
        passengers: 1,
        cars: 1,
        islander: false,
        admin: false,
        rank: 8
    },
    {
        _id: "627f75da44e928841d2d323b",
        label: "Islander Adult",
        labelIE: "Islander Adult",
        code: "iadult",
        price: 800,
        passengers: 1,
        cars: 0,
        islander: true,
        admin: false,
        rank: 9
    },
    {
        _id: "627f770a44e928841d2d323f",
        label: "Islander Car Driver",
        labelIE: "Islander Car Driver",
        code: "icardriver",
        price: 2300,
        passengers: 1,
        cars: 1,
        islander: true,
        admin: false,
        rank: 10
    },
    {
        _id: "627f75f944e928841d2d323c",
        label: "Islander Student",
        labelIE: "Islander Student",
        code: "istudent",
        price: 500,
        passengers: 1,
        cars: 0,
        islander: true,
        admin: false,
        rank: 11
    },
    {
        _id: "627f76e344e928841d2d323e",
        label: "Islander Travel Pass (Car and Driver)",
        labelIE: "Islander Travel Pass (Car and Driver)",
        code: "itravelpass",
        price: 1500,
        passengers: 1,
        cars: 1,
        islander: true,
        admin: false,
        rank: 12
    }
];

export function TicketPassengerTotal(tickets) {
    let totalPassengers = 0;
    tickets.forEach((t) => {
        var passengers = 0;
        passengers += t.passengers.map((p) => p.passengers * p.quantity).reduce((total, inc) => (total += inc), 0);
        totalPassengers += passengers;
    });
    return totalPassengers;
}

export function SingleTicketPassengerTotal(ticket) {
    let passengers = 0;
    passengers += ticket.passengers.map((p) => p.passengers * p.quantity).reduce((total, inc) => (total += inc), 0);
    return passengers;
}
