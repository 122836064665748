import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";
// import { title } from 'process';
// const qs = require('querystring');

export async function getTickets() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(`${Constants.getApiUrl()}${Constants.recurringTicket}`, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function updateTicket(id, ticket) {
    const headers = await Firebase.getHeaders();
    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.updateRecurringTicket}`,
            { ticketid: id, updatedTicket: ticket },
            headers
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getTicketsForDate(date) {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(
            `${Constants.getApiUrl()}${Constants.getTicketDate}/${date}`,
            headers
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function addNewTicket(newTicket) {
    // console.log(newTicket);

    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.addRecurringTicket}`,
            newTicket,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getPreviewTicket(newTicket) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.getPreviewRecurringTicket}`,
            newTicket,
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function updateTicketStatus(id, status) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            Constants.getApiUrl() + Constants.updateStatusRecurringTicket,
            { status: status, id: id },
            headers
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function deleteTicket(id) {
    // console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.deleteRecurringTicket}`,
            ticketID,
            headers
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function searchTickets(searchTerm) {
    let searchData = { search: searchTerm };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.searchRecurringTicket}`,
            searchData,
            headers
        )
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
