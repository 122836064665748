import React from 'react';

import {
    Row,
    Divider,
    Space,
    Button,
    Popconfirm,
    Typography,
} from 'antd';

import {
    DeleteOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

import * as Constants from '../../utils/Constants';
import { checkOnlyContainsDaily } from '../../utils/DefinitionUtils';


export function EditDefinitionTable(props) {
    const { monthly, cancelSelection, remove } = props;
    console.log(monthly);
    const _months = monthly.m.map((mth, index) => Constants.months[mth]);
    return (
        <React.Fragment key={JSON.stringify(monthly.m)}>
            <Row justify="left" className={"primary"}>{
                _months.join(", ").replace(/, ([^,]*)$/, ' and $1')
            }</Row>

            {monthly.d.map((daysArray, index) => {
                const _days = daysArray.map((da) => Constants.days[da]);
                // console.log(_days);
                return <React.Fragment key={JSON.stringify(_days)}  style={{ paddingTop: "10px" }}>
                    <Row style={{ padding: "4px" }} justify="left">
                        <Space size="small">
                            <Typography.Text>
                                {_days.join(", ").replace(/, ([^,]*)$/, ' and $1')}
                            </Typography.Text>
                            {monthly.only
                                ? checkOnlyContainsDaily(monthly.only, daysArray)
                                    ? <Typography.Text>Only *</Typography.Text>
                                    : <></>
                                : <></>}
                        </Space>
                    </Row>
                </React.Fragment>

            })}
            <Divider orientation="right">
                <Space size="middle" direction="horizontal">
                    <Button type="ghost" onClick={() => cancelSelection()} icon={<CloseCircleOutlined />}>Cancel</Button>
                    <Popconfirm title="Are you sure want to delete this whole definition?" okText="Yes" onConfirm={() => remove(monthly._id)} cancelText="Cancel" onCancel={Constants.cancel} >
                        <Button type="ghost" icon={<DeleteOutlined />} danger>Delete Whole Definition</Button>
                    </Popconfirm>
                </Space>
            </Divider>
        </React.Fragment>
    )
}
