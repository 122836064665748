import React, { useEffect, useState } from "react";
import { Typography, Drawer, Form, Input, Space, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import * as TicketService from "../../../services/TicketService";
import { message } from "antd";
import TicketViewer from "../TicketViewer";
import { Spin } from "antd";
import { getTicket } from "../../../services/ToryService";

export function LoadTicketViewerPanel(props) {
    const { open, close, id } = props;

    useEffect(() => {
        //
        loadTicket();
        //
    }, [id]);

    async function loadTicket() {
        if (id !== "") {
            setIsLoading(true);
            if (id != null) {
                let response = await getTicket(id);
                // console.log(response);
                setTicket(response.ticket);
            }
            setIsLoading(false);
        }
    }

    const [width] = useState(window.innerWidth);

    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState(null);

    return (
        <Drawer open={open} onClose={close} width={width > 900 ? 640 : width}>
            <Space direction="vertical" style={{ width: "100%" }}>
                {isLoading ? (
                    <Spin />
                ) : ticket == null ? (
                    <Typography.Text>No Ticket Found</Typography.Text>
                ) : (
                    <TicketViewer singleTicket={ticket} />
                )}
            </Space>
        </Drawer>
    );
}
