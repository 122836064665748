import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getDashboardDataForDate(journey, date) {
    const headers = await Firebase.getHeaders();

    const data = { journey, date };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminDashboardData}`, data, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getTimeKeyForDate(date) {
    const headers = await Firebase.getHeaders();

    const data = { date };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.v2AdminTimeKeys}`, data, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getTicketsForDateTime(date, time) {
    const headers = await Firebase.getHeaders();
    const data = { date, time };
    return await axios
        .post(`${Constants.getApiUrl()}${Constants.v2AdminTickets}`, data, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
