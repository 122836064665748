import React from "react";
import { Space, Typography, Tag, Select, Button } from "antd";
import { getPaidStatusColour } from "../../../utils/Constants";

export function TicketFilterStatus(props) {
    const { filterStatus, setFilterStatus } = props;

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={getPaidStatusColour(value)}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                    marginBottom: 2
                }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Space>
            <Typography.Text style={{ fontSize: "0.8em" }}>{`Status`.toUpperCase()}:</Typography.Text>
            <Button
                onClick={() => {
                    setFilterStatus(["paid", "reserved", "limbo", "requires-action", "inactive", "other"]);
                }}
            >
                All
            </Button>
            <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                value={filterStatus}
                style={{ width: "300px" }}
                options={[
                    { label: "Paid", value: "paid" },
                    { label: "Reserved", value: "reserved" },
                    { label: "Limbo", value: "limbo" },
                    { label: "Requires Attn", value: "requires-action" },
                    { label: "Inactive", value: "inactive" },
                    { label: "Other", value: "other" }
                ]}
                onChange={(v) => {
                    console.log(v);
                    setFilterStatus(v);
                }}
            />
        </Space>
    );
}
