import React, { useEffect, useState } from "react";
import { Card, Statistic, Button } from "antd";
import { PaperClipOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Drawer } from "antd";
import { format } from "date-fns";
import { NewNoteDrawer } from "./NewNoteDrawer";
import * as NoteService from "../../../services/NoteService";
import { Alert } from "antd";
import { EditNoteDrawer } from "./EditNoteDrawer";
import { Typography } from "antd";

export function NoteCard(props) {
    const { date, time, journey } = props;
    //
    const [width] = useState(window.innerWidth);
    // NOTES
    const [dateNotes, setDateNotes] = useState([]);
    // DRAWERS
    const [openNotes, setOpenNotes] = useState(false);
    const [addNew, setAddNew] = useState(false);
    // EDIT
    const [editNote, setEditNote] = useState(null);

    // NOTES
    useEffect(() => {
        getDateNotes();
    }, [date]);

    // GET DATE NOTES
    async function getDateNotes() {
        let { data, error } = await NoteService.getNotesDates(date, -1);
        if (error === 0) {
            // console.log(data);
            setDateNotes(data);
        }
    }

    // NOTES
    useEffect(() => {
        updateDateNotes();
    }, [addNew, editNote]);

    // GET DATE NOTES
    async function updateDateNotes() {
        // console.log("NOTES");
        let { data, error } = await NoteService.getNotesDates(date, -1);
        if (error === 0) {
            // message.success("Downl")
            setDateNotes(data);
        }
    }

    function getPriorityColor(priority) {
        switch (priority) {
            case 0:
                return "success";
            case 1:
                return "info";
            case 2:
                return "warning";
            case 3:
                return "error";
            default:
                return "info";
        }
    }

    //
    return (
        <>
            <Card size="small" onClick={() => setOpenNotes(true)} style={{ cursor: "pointer" }}>
                <Space direction="vertical">
                    <Statistic
                        title="Day Notes"
                        prefix={<PaperClipOutlined />}
                        value={`${dateNotes.length}`}
                        valueStyle={{}}
                        precision={0}
                    />
                    {/* <Typography.Text>{`date: ${date}, time: ${time}, journey: ${journey}`}</Typography.Text> */}
                </Space>
            </Card>
            {/* BOTTOM DRAWER */}
            <Drawer
                open={openNotes}
                placement="right"
                title={`Notes For ${format(date, "do MMM yyyy")}`}
                closeIcon={<></>}
                closable={true}
                onClose={() => setOpenNotes(false)}
                width={width > 900 ? 640 : "100%"}
                extra={[
                    <Space key={"newnotes"}>
                        <Button icon={<PlusCircleOutlined />} onClick={() => setAddNew(true)} type="primary">
                            Add New
                        </Button>
                        <Button onClick={() => setOpenNotes(false)}>Close</Button>
                    </Space>
                ]}
            >
                {/* <Typography.Text>{`${JSON.stringify(dateNotes)}`}</Typography.Text> */}
                <Space direction="vertical" style={{ width: "100%" }}>
                    {dateNotes.length === 0 ? (
                        <Typography.Text>No Notes</Typography.Text>
                    ) : (
                        dateNotes
                            .sort((a, b) => b.priority - a.priority)
                            .map((dn) => (
                                <Alert
                                    key={dn._id}
                                    style={{ width: "100%" }}
                                    message={dn.note}
                                    type={getPriorityColor(dn.priority)}
                                    action={[
                                        <Button size="small" onClick={() => setEditNote(dn)}>
                                            Edit
                                        </Button>
                                    ]}
                                />
                            ))
                    )}
                </Space>
            </Drawer>
            {/* NEW NOTE */}
            <NewNoteDrawer addNew={addNew} setAddNew={setAddNew} date={date} time={time} />
            {/* EDIT / DELETE DRAWER */}
            <EditNoteDrawer note={editNote} setNote={setEditNote} date={date} time={time} />
        </>
    );
}
