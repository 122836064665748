import React, { useEffect, useState } from "react";
import { Button, Space, Tag, Typography } from "antd";
import { CarOutlined, UserOutlined } from "@ant-design/icons";
import * as AvailService from "../../../services/AvailService";
import * as TicketService from "../../../services/TicketService";
import { deckSpaces } from "../../../utils/Global";
import { Drawer } from "antd";
import { AvailabilityDrawer } from "./AvailabilityDrawer";
import { message } from "antd";
import * as GlobalUtils from "../../../utils/Global";

export default function AdminAvailabilityChecker(props) {
    const { date, time, journey } = props;

    // const [cars, setCars] = useState(8);
    // const [passengers, setPassengers] = useState(8);

    // const [avails, setAvails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [manifestCars, setManifestCars] = useState(0);
    const [manifestPassengers, setManifestPassengers] = useState(0);
    const [hasTwoFerries, setHasTwoFerries] = useState(false);

    const [showFullTickets, setShowFullTickets] = useState(false);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        getAvails();
        getTickets();
    }, [date, time]);

    async function getAvails() {
        // console.log(date, time);
        setLoading(true);
        if (date && time) {
            // console.log("not null");
            let response = await AvailService.getManifestForTime(journey, date, time);
            // console.log(response);
            const { data } = response;
            const { cars, passengers, isSecondFerry } = data;
            setManifestCars(cars);
            setManifestPassengers(passengers);
            setHasTwoFerries(isSecondFerry === 1 ? true : false);
        } else {
            // console.log("null values for date and time");
        }
        setLoading(false);
    }
    async function getTickets() {
        // console.log(date, time);
        setLoading(true);
        if (date && time) {
            // console.log("not null");
            let response = await TicketService.getTicketsForDateTimeJourney(date, time, journey);
            // console.log(response);
            if (response.error === 0) {
                setTickets(response.data);
            } else {
                setTickets([]);
                message.error("Unable to get tickets for date and time");
            }
        } else {
            // console.log("null values for date and time");
        }
        setLoading(false);
    }

    return time === null || time === undefined ? (
        <Space>
            <Typography.Text>Select time for availability</Typography.Text>
        </Space>
    ) : (
        <Space direction="vertical">
            <Space direction={hasTwoFerries ? "vertical" : "horizontal"}>
                <Typography.Text>Spaces: </Typography.Text>
                {/* <Typography.Text>{`cars: ${manifestCars}, pass: ${manifestPassengers}`}</Typography.Text> */}
                <Tag
                    style={{ cursor: "pointer" }}
                    color={manifestCars >= GlobalUtils.totalCars || manifestPassengers >= GlobalUtils.totalPassengers ? "error" : "success"}
                    onClick={() => setShowFullTickets(true)}
                >
                    {`BOOKED:`} <CarOutlined /> {`${manifestCars}`} <UserOutlined />
                    {`${manifestPassengers}`}
                </Tag>
                {hasTwoFerries ? <Tag color="success">* TWO FERRIES RUNNING</Tag> : <></>}
            </Space>
            <AvailabilityDrawer
                journey={journey}
                date={date}
                time={time}
                tickets={tickets}
                isOpen={showFullTickets}
                onClose={() => setShowFullTickets(false)}
            />
        </Space>
    );
}
