import React, { useState } from "react";
import { Table, Space, Button } from "antd";
import { Typography } from "antd";
import { format, isAfter, isBefore } from "date-fns";
import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { EditGoldenTicketPanel } from "./EditGoldenTicketTable";
import { getIDString } from "../../utils/Constants";
export function GoldenTicketTable(props) {
    const { tickets, reload } = props;

    const [editId, setEditId] = useState(null);

    function closeEditPanel() {
        setEditId(null);
        reload();
    }

    const columns = [
        {
            title: `ID`,
            dataIndex: "_id",
            key: "_id",
            render: (value, row) => (
                <Typography.Text style={{ fontSize: "10px" }}>{`#${value.substring(value.length - 6).toUpperCase()}`}</Typography.Text>
            )
        },
        {
            title: "Golden Ticket Time",
            dataIndex: "goldenTime",
            key: "goldenTime",
            render: (value, record) => {
                if (isAfter(new Date(), new Date(value)) && record.redeemed === 1) {
                    return (
                        <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                            <Typography.Text>{`This ticket was redeemed`}</Typography.Text>
                            <Typography.Text style={{ fontSize: "12px" }}>{`Golden Time was ${
                                value === 0 ? `the next available ticket` : `at ${format(value, "dd/MM/yyyy HH:mm")}`
                            }`}</Typography.Text>
                        </Space>
                    );
                }
                if (isAfter(new Date(), new Date(value))) {
                    return (
                        <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                            <Typography.Text>{`This ticket will be a golden ticket`}</Typography.Text>
                            <Typography.Text style={{ fontSize: "12px" }}>{`Golden Time is ${
                                value === 0 ? `the next available ticket` : `at ${format(value, "dd/MM/yyyy HH:mm")}`
                            }`}</Typography.Text>
                        </Space>
                    );
                }
                return (
                    <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                        <Typography.Text>{`This ticket will be Golden ${format(value, "dd/MM/yyyy")}`}</Typography.Text>
                        <Typography.Text style={{ fontSize: "12px" }}>{`Will be available at: ${format(
                            value,
                            "dd/MM/yyyy HH:mm"
                        )}`}</Typography.Text>
                    </Space>
                );
            }
        },
        {
            title: "Price Limit",
            dataIndex: "priceLimit",
            key: "priceLimit",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Typography.Text>{`€ ${(value / 100).toFixed()}`}</Typography.Text>
                </Space>
            )
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Typography.Text>{`${value}`}</Typography.Text>
                </Space>
            )
        },
        {
            title: "Redeemed",
            // dataIndex: "redeemed",
            key: "redeemed",
            render: (value, record) =>
                record.redeemed === 1 ? (
                    <Space direction="horizontal" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                        <a href={`/tickets?t=${record.redeemedTicket}`}>
                            <Button icon={<CheckCircleOutlined color="green" />}>
                                <Typography.Text>{`${getIDString(record.redeemedTicket).toUpperCase()}`}</Typography.Text>
                            </Button>
                        </a>
                    </Space>
                ) : (
                    <Space direction="horizontal" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                        <QuestionCircleOutlined />
                        <Typography.Text>Still Available</Typography.Text>
                    </Space>
                )
        },
        {
            title: "Actions",
            dataIndex: "_id",
            key: "actions",
            render: (value, record) => (
                <Space direction="vertical" size="small" style={{ maxWidth: "100%", padding: "0px" }}>
                    <Button onClick={() => setEditId(value)}>Edit</Button>
                </Space>
            )
        }
    ];

    return (
        <>
            {/* <Typography.Text>{`${JSON.stringify(tickets)}`}</Typography.Text> */}
            {/* <Typography.Text>{`${editId}`}</Typography.Text> */}
            <Table columns={columns} dataSource={tickets} />
            <EditGoldenTicketPanel onClose={closeEditPanel} openDrawer={editId != null ? true : false} id={editId} reload={reload} />
        </>
    );
}
