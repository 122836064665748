import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";
// import { title } from 'process';
// const qs = require('querystring');

export async function getTicketsForDate(date) {
    // console.log(date);
    const headers = await Firebase.getHeaders();

    return await axios
        .get(`${Constants.getApiUrl()}${Constants.getTicketDate}/${date}`, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getTicketsForDateTime(date, time) {
    // console.log(`date ${date} time ${time}`);
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.getTicketDateTime}`,
            {
                date,
                time
            },
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            // console.log(e);
            return { status: 404 };
        });
}

export async function getTicketsForDateTimeJourney(date, time, journey) {
    // console.log(`date ${date} time ${time}`);
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.getTicketDateTimeJourney}`,
            {
                date,
                time,
                journey
            },
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            // console.log(e);
            return { status: 404 };
        });
}

export async function getAdminTickets(showPast, ticketStatus, pagination, sorter) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.v2AdminTicket}`, { showPast, ticketStatus, pagination, sorter }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getFilteredTickets(
    filterStatus,
    filterDateCreated,
    filterTravelDate,
    filterStripePayment,
    filterMethod,
    filterShowPast,
    filterNameEmail,
    pagination,
    sorter
) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(
            `${Constants.getApiUrl()}${Constants.v2AdminTicketFilter}`,
            {
                filterStatus,
                filterDateCreated,
                filterTravelDate,
                filterStripePayment,
                filterMethod,
                filterShowPast,
                filterNameEmail,
                pagination,
                sorter
            },
            headers
        )
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function updateNameEmail(id, name, email) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.updateTicketv2NameEmail}`, { id, name, email }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404, error: 1 };
        });
}

export async function updateCheckInStatus(id, checkin) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.updateCarStatus}`, { id, checkin }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404, error: 1 };
        });
}
