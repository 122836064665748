import React from "react";
import { Typography, Space, Button, Row, Col, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { maxDeckSpacesAvailable } from "../../../utils/Constants";
import { PassengerIconsSummary } from "./PassengerIconsSummary";

export function PassengerList(props) {
    const { newTicket, removePassenger, showRemove, showTotals, setEditCustomPrice } = props;
    const { passengers } = newTicket;

    const totalCars =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 2)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalBikes =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 1)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalVans =
        passengers === null
            ? 0
            : passengers
                .filter((p) => p.deckSpaces === 3)
                .map((p) => p.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return passengers.length === 0 ? (
        <Space direction="vertical" style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}>
            <Typography.Text style={{ fontWeight: "bold" }}>No Passengers Selected For Ticket</Typography.Text>
        </Space>
    ) : (
        <Space style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }} direction="vertical">
            {/* <Typography.Text>{`${JSON.stringify(passengers)}`}</Typography.Text>
            <Typography.Text>{`${JSON.stringify(passengers.filter((p) => p.deckSpaces === 2))}`}</Typography.Text> */}

            {showTotals ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Row justify="space-between" style={{ width: "100%" }}>
                        <Col>
                            <Typography.Text style={{ fontWeight: "bold" }}>
                                {`Passengers: ${passengers
                                    .map((p) => p.quantity * p.passengers)
                                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)}`}
                                {maxDeckSpacesAvailable > 0 && totalCars > 0 ? ` Cars: ${totalCars}` : ""}
                                {maxDeckSpacesAvailable > 0 && totalBikes > 0 ? ` Motorbikes: ${totalBikes}` : ""}
                                {maxDeckSpacesAvailable > 0 && totalVans > 0 ? ` Vans: ${totalVans}` : ""}
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Typography.Text style={{ fontWeight: "bold" }}>
                                {newTicket.ticket.returnTicket == true
                                    ? `Total: € ${(
                                        passengers
                                            .map((p) => p.quantity * p.price)
                                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 100
                                    ).toFixed(2)}`
                                    : `Total: € ${(
                                        passengers
                                            .map((p) => p.quantity * p.price)
                                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 200
                                    ).toFixed(2)}`}
                            </Typography.Text>
                        </Col>
                    </Row>
                    <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.06)" }} />
                </Space>
            ) : (
                <></>
            )}
            <Space style={{ width: "100%" }} direction="vertical">
                {passengers.map((p) => (
                    <Space key={p.code} direction="vertical" style={{ width: "100%" }}>
                        <Row justify="space-between" style={{ width: "100%" }}>
                            <Col span={14}>
                                <Typography.Text key={p.code}>{`${p.quantity}x ${p.label}`}</Typography.Text>
                                {p.islander ? (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="green">
                                        Islander
                                    </Tag>
                                ) : (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="blue">
                                        Visitor
                                    </Tag>
                                )}
                                {p.code === "custom" ? (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="orange">
                                        Custom
                                    </Tag>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <Col span={showRemove ? 6 : 10}>
                                <PassengerIconsSummary passengers={[p]} showText={false} />
                                {/* <Space direction="horizontal" wrap>
                                    {[
                                        ...Array(
                                            p.quantity * p.passengers
                                        ).keys()
                                    ].map((p) => (
                                        <UserOutlined key={p} />
                                    ))}
                                </Space> */}
                            </Col>
                        </Row>
                        <Row>
                            {showRemove ? (
                                <Col span={4} justify="right">
                                    <Space direction="horizontal" size="small">
                                        <Button onClick={() => setEditCustomPrice(true)} key={`${p.code}-customedit`} size="small" type="primary">{`Edit`}</Button>
                                        <Button onClick={() => removePassenger(p.code)} key={p.code} size="small" danger>{`Remove`}</Button>
                                    </Space>
                                </Col>
                            ) : (
                                <></>
                            )}
                        </Row>
                        {showTotals ? (
                            <hr
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.06)"
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Space>
                ))}
            </Space>
        </Space>
    );
}
