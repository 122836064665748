import React, { useState } from "react";
import * as GoldenTicketService from "../../services/GoldenTicketService";
import { Drawer, Typography, DatePicker, Space, Button, Form, Input, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { add } from "date-fns";
import moment from "moment";

export function NewGoldenTicketPanel(props) {
    const { onClose, openDrawer } = props;
    const [width] = useState(window.innerWidth);

    // time, message, priceLimit
    const [time, setTime] = useState(new Date().getTime());
    const [gtMessage, setgGMessage] = useState("");
    const [priceLimit, setPriceLimit] = useState(100);
    const [isLoading, setIsLoading] = useState(false);

    function updateDate(date) {
        const timeInt = date.getTime();
        console.log(timeInt);
        setTime(timeInt);
    }

    function getRandomNumber(max, min) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function setRandomDate() {
        let date = new Date();
        let randomDate = add(date, { days: getRandomNumber(0, 7), hours: getRandomNumber(0, 23), minutes: getRandomNumber(0, 23) });
        setTime(randomDate.getTime());
    }

    function resetForm() {
        setTime(new Date().getTime());
        setgGMessage("");
        setPriceLimit(100);
    }

    async function addGoldenTicket() {
        setIsLoading(true);
        let { error } = await GoldenTicketService.addGoldenTicket(time, gtMessage, priceLimit * 100);
        if (error === 0) {
            message.success("Added Golden Ticket");
            resetForm();
            onClose();
        } else {
            message.error("Couldn't Add Golden Ticket");
        }
        setIsLoading(false);
    }

    return (
        <Drawer title="Golden Ticket" placement="right" onClose={() => onClose()} open={openDrawer} width={width > 900 ? 640 : "100%"}>
            <Form layout={"vertical"}>
                <Form.Item label="Date">
                    <Space style={{ width: "100%" }}>
                        <DatePicker value={new moment(time)} onChange={(v) => updateDate(v._d)} showTime allowClear={false} />
                        <Button onClick={() => setRandomDate()}>Pick Random Time in Week</Button>
                    </Space>
                </Form.Item>
                <Form.Item label="Message">
                    <Input value={gtMessage} placeholder="Congratulations Message to user" onChange={(v) => setgGMessage(v.target.value)} />
                </Form.Item>
                <Form.Item label="Price Limit €">
                    <Typography.Text style={{ paddingBottom: "0.5em" }}>
                        This is the upper limit on ticket price give away, default is €100
                    </Typography.Text>
                    <Input type="number" value={priceLimit} placeholder={100} onChange={(v) => setPriceLimit(v.target.value)} />
                </Form.Item>
            </Form>

            <Button type="primary" icon={<SaveOutlined />} onClick={() => addGoldenTicket()} loading={isLoading}>
                Save
            </Button>
            {/* <Typography.Text>{`time: ${time}, gtMessage: ${gtMessage}, priceLimit: ${priceLimit}`}</Typography.Text> */}
        </Drawer>
    );
}
