import { Button, Drawer, Form, Typography, Input, Checkbox, Divider } from "antd";
import React, { useState } from "react";
import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { maxDeckSpacesAvailable } from "../../../utils/Constants";

// {
//     code: 'vfamily',
//     label: 'Family (2 Adults, 2 Children)',
//     price: 6000,
//     quantity: 1,
//     passengers: 4,
//     deckSpaces: 0,
//     islander: false,
//     key: 1678224417048
//   }

export function AddCustomPrice(props) {
  const { updateCustomPrice } = props;

  const [width] = useState(window.innerWidth);
  const [openCustom, setOpenCustom] = useState(false);

  const [customLabel, setCustomLabel] = useState("");
  const [customPrice, setCustomPrice] = useState(0);
  const [customPassengerTotal, setCustomPassengerTotal] = useState(0);
  const [customDeckSpaces, setCustomDeckSpaces] = useState(0);
  const [customIslander, setCustomIslander] = useState(false);

  function addCustomPrice() {
    // console.table({
    //   code: "custom",
    //   label: customLabel,
    //   price: customPrice,
    //   quantity: 1,
    //   passengers: customPassengerTotal,
    //   deckSpaces: customDeckSpaces,
    //   islander: customIslander
    // });
    updateCustomPrice({
      code: "custom",
      label: customLabel,
      price: customPrice,
      quantity: 1,
      passengers: customPassengerTotal,
      deckSpaces: customDeckSpaces,
      islander: customIslander
    });
    setCustomLabel("");
    setCustomPrice(0);
    setCustomPassengerTotal(0);
    setCustomDeckSpaces(0);
    setCustomIslander(false);
    setOpenCustom(false);
  }

  return (
    <>
      <Button onClick={() => setOpenCustom(true)}>Add Custom Price</Button>
      <Drawer
        title="Add Custom Ticket"
        placement="right"
        closable={true}
        closeIcon={<CloseSquareOutlined />}
        width={width > 900 ? 620 : width}
        onClose={() => setOpenCustom(false)}
        open={openCustom}
      >
        <Form
          labelCol={{
            span: 8
          }}
          wrapperCol={{
            span: 16
          }}
        >
          <Form.Item label="Label">
            <Input placeholder="Label for Ticket" onChange={(v) => setCustomLabel(v.target.value)} value={customLabel} />
          </Form.Item>
          <Form.Item label={`Price in cents`}>
            <Input
              type="number"
              placeholder="Price in &#162;"
              onChange={(v) => {
                if (v.target.value !== "") {
                  setCustomPrice(parseInt(v.target.value) ?? 0);
                } else {
                  setCustomPrice(0);
                }
              }}
              value={customPrice}
            />
            <Typography.Text>{`€ ${(customPrice / 100).toFixed(2)}`}</Typography.Text>
          </Form.Item>
          <Form.Item label="Passengers">
            <Input
              type="number"
              placeholder={0}
              onChange={(v) => {
                if (v.target.value !== "") {
                  setCustomPassengerTotal(parseInt(v.target.value) ?? 0);
                } else {
                  setCustomPassengerTotal(0);
                }
              }}
              value={customPassengerTotal}
            />
          </Form.Item>
          {maxDeckSpacesAvailable > 0 ? (
            <Form.Item label="Deck Spaces">
              <Input
                placeholder={0}
                onChange={(v) => {
                  if (v.target.value) {
                    setCustomDeckSpaces(parseInt(v.target.value) ?? 0);
                  } else {
                    setCustomDeckSpaces(0);
                  }
                }}
                value={customDeckSpaces}
              />
              <Typography.Text>{`1 = bike, 2 = car, 4 = LWB, 6 = lorry`}</Typography.Text>
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item label="Islander">
            <Checkbox checked={customIslander} onChange={(v) => setCustomIslander(v.target.checked)}>
              Check box for islander ticket
            </Checkbox>
          </Form.Item>
        </Form>
        <Divider orientation="right">
          <Button onClick={addCustomPrice} icon={<SaveOutlined />} type="primary">
            Add Ticket
          </Button>
        </Divider>
      </Drawer>
    </>
  );
}
