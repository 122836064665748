import React, { useState } from "react";
import { Card, Row, Col, Typography, message, Spin, Space } from "antd";
import * as ToryService from "../../services/ToryService";
import EditPanel from "../tickets/EditTicketPanel";
import ViewPanel from "../tickets/ViewPanel";

import TicketCard from "./mobile/TicketCard";

export default function DashboardCards(props) {
    const { reload, tickets } = props;

    // const [tickets, setTickets] = useState(null);

    // SETTING NEW ONES
    // const [tickets, setTickets] = useState([]);
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    // const [newTicket, setNewTicket] = useState(null);
    const [showViewDrawer, setShowViewDrawer] = useState(false);
    const [singleTicket, setSingleTicket] = useState(null);
    const [width] = useState(window.innerWidth);

    function editTicket(id) {
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(getTicketDetails(id));
            setShowEditDrawer(true);
        }
    }

    function viewTicket(id) {
        console.log(id);
        if (id !== null) {
            setSingleTicket(getTicketDetails(id));
            setShowViewDrawer(true);
        }
    }

    async function sendReceipt(id) {
        console.log(`id of receipt ${id}`);
        let response = await ToryService.sendReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Receipt Sent");
            // this.props.onClose();
            setShowEditDrawer(false);
            // loadTickets();
        } else {
            message.error("Can't send receipt for ticket");
        }
    }

    async function sendUpdateReceipt(id) {
        // console.log(`id of receipt ${id}`);
        let response = await ToryService.sendUpdateReceipt(id);
        if (response.status === 200) {
            message.success("Ticket Update Email Sent");
            // this.props.onClose();
            setShowEditDrawer(false);
            // loadTickets();
        } else {
            message.error("Can't send email for ticket");
        }
    }

    function getTicketDetails(id) {
        let ticket = null;
        // console.log(tickets);
        tickets.forEach((element) => {
            if (element._id === id) {
                ticket = element;
            }
        });
        return ticket;
    }

    function closeEditDrawer() {
        setShowEditDrawer(false);
        setSingleTicket(null);
        reload();
    }
    function closeViewDrawer() {
        setShowViewDrawer(false);
        setSingleTicket(null);
    }

    // useEffect(() => {
    //     getTickets(props.journey, props.date, props.time);
    // }, [props.date, props.time, props.journey]);

    // function getTickets() {
    //     let _tickets = props.tickets;
    //     // console.log(props.time);
    //     if (props.time === null) {
    //         _tickets = props.tickets.filter((t) => {
    //             // OUT JOURNEY
    //             if (t.ticket.outDate > getStartOfDate(props.date) && t.ticket.outDate < getEndOfDate(props.date)) {
    //                 return t;
    //             }
    //             // RETURN JOURNEY
    //             if (t.ticket.returnDate > getStartOfDate(props.date) && t.ticket.returnDate < getEndOfDate(props.date)) {
    //                 return t;
    //             }
    //             return false;
    //         });
    //     } else {
    //         _tickets = props.tickets.filter((t) => {
    //             // OUT JOURNEY
    //             if (
    //                 t.ticket.outJourney === props.journey &&
    //                 t.ticket.outDate > getStartOfDate(props.date) &&
    //                 t.ticket.outDate < getEndOfDate(props.date)
    //             ) {
    //                 if (t.ticket.outTime === props.time) {
    //                     return t;
    //                 }
    //             }
    //             // RETURN JOURNEY
    //             if (
    //                 t.ticket.outJourney !== props.journey &&
    //                 t.ticket.returnDate > getStartOfDate(props.date) &&
    //                 t.ticket.returnDate < getEndOfDate(props.date)
    //             ) {
    //                 // return t;
    //                 if (t.ticket.returnTime === props.time) {
    //                     return t;
    //                 }
    //             }
    //             return false;
    //         });
    //     }
    //     // console.log(_tickets);
    //     setTickets(_tickets);
    // }

    return (
        <>
            {tickets ? (
                tickets.length === 0 ? (
                    <Row justify="center">
                        <Card style={{ width: "100%", textAlign: "center" }}>
                            <Typography.Text>No Tickets For Selected Ferry</Typography.Text>
                        </Card>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                {tickets.map((t, index) => (
                                    <TicketCard key={index} t={t} viewTicket={viewTicket} editTicket={editTicket} />
                                ))}
                            </Space>
                        </Col>
                    </Row>
                )
            ) : (
                <Row>
                    <Spin />
                </Row>
            )}

            {singleTicket ? (
                <EditPanel
                    onClose={closeEditDrawer}
                    visible={showEditDrawer}
                    width={width > 900 ? 640 : width}
                    singleTicket={singleTicket}
                    editTicket={editTicket}
                    sendReceipt={sendReceipt}
                    sendUpdateReceipt={sendUpdateReceipt}
                />
            ) : (
                <></>
            )}

            <ViewPanel onClose={closeViewDrawer} visible={showViewDrawer} width={width > 900 ? 640 : width} singleTicket={singleTicket} />
        </>
    );
}
