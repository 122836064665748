import React from "react";

import {
    Button,
    // Popconfirm,
    Space,
    Tooltip,
} from "antd";

import {
    // CloseCircleOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";

import * as Constants from "../../utils/Constants";

export default function CancelMoveButtons(props) {

    const { ferries, cancelTime, moveTime, setMoveFerry } = props;

    return (
        <Space size="middle" wrap>
            {ferries.sort((a, b) => a.time - b.time).map((i, index) => {
                return i.time === cancelTime
                    ? <React.Fragment key={index}></React.Fragment>
                    : <React.Fragment key={index}>
                        {i.ferry === "extra" ?
                            <Tooltip key={index} title={`Note: This is an extra ferry`}>
                                <Button onClick={() => setMoveFerry(i.time)} type={moveTime === i.time ? "primary" : "default"} icon={<ArrowRightOutlined />} style={{}}>
                                    {` ${Constants.getTimestamp(i.time)} `}
                                </Button>
                            </Tooltip>
                            :
                            <Button key={index} onClick={() => setMoveFerry(i.time)} type={moveTime === i.time ? "primary" : "default"} icon={<ArrowRightOutlined />} style={{}}>
                                {` ${Constants.getTimestamp(i.time)} `}
                            </Button>}
                    </React.Fragment>
            })}
        </Space>
    );
};
