import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";
// import { title } from 'process';
const qs = require("querystring");

export async function getPublicTimes() {
    return axios
        .get(Constants.getApiUrl() + Constants.masterTimes)
        .then((res) => {
            const masterData = res.data;
            // console.log(masterData);
            // SET MASTER DEFINITIONS
            return masterData;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

export async function getAdminTimes() {
    return axios
        .get(Constants.getApiUrl() + Constants.adminMasterTimes)
        .then((res) => {
            const masterData = res.data;
            // console.log(masterData);
            // SET MASTER DEFINITIONS
            return masterData;
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

// ADD MASTER

export async function addMasterTime(newTime) {
    const headers = await Firebase.getHeaders();

    return axios
        .post(Constants.getApiUrl() + Constants.addMasterTimes, newTime, headers)
        .then((res) => {
            // console.log(res)
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
        });
}

export async function updateMasterTime(newTime) {
    const headers = await Firebase.getHeaders();

    return axios
        .post(Constants.getApiUrl() + Constants.updateMasterTimes, newTime, headers)
        .then((res) => {
            // console.log(res)
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
        });
}

export async function deleteMasterTime(deleteid) {
    const headers = await Firebase.getHeaders();

    return axios
        .get(Constants.getApiUrl() + Constants.removeMasterTimes + deleteid, headers)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getCancel() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.adminCancel, headers)
        .then((res) => {
            // console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getDateTimes(journey, date) {
    // let checkDate = {
    // 	"journey": journey,
    // 	"date": Constants.getEpochDate(date),
    // 	"dst": false
    // }
    // console.log(checkDate);

    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.oneDayTimes + Constants.getEpochDate(date), headers)
        .then((res) => {
            let times = {};
            if (journey === "dt") {
                times.master = res.data.dt;
                times.reroute = res.data.dt_b;
            } else {
                times.master = res.data.dm;
                times.reroute = res.data.dm_b;
            }
            return times;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function cancelFerry(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };
    let cancelDate = {
        journey: journey,
        time: time,
        date: date,
        archive: false,
        meta: meta
    };
    // console.log(cancelDate);

    return await axios
        .post(Constants.getApiUrl() + Constants.adminCancelAdd, cancelDate, headers)
        .then((res) => {
            // console.log(res)
            // console.log(res.data)
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function confirmCancelFerry(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };
    let cancelDate = {
        journey: journey,
        time: time,
        date: date,
        archive: false,
        meta: meta
    };
    // console.log(cancelDate);

    return await axios
        .post(Constants.getApiUrl() + Constants.adminCancelAddConfirm, cancelDate, headers)
        .then((res) => {
            // console.log(res);
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function confirmCancelMoveFerry(cancelMoveBody) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };

    const body = {
        ...cancelMoveBody,
        meta
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.adminCancelMove, body, headers)
        .then((res) => {
            // console.log(res);
            console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function unCancelFerry(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminCancelRemove + id, headers)
        .then((res) => {
            return res.status;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getCancelArchive() {
    const headers = await Firebase.getHeaders();

    return axios
        .get(Constants.getApiUrl() + Constants.adminCancelArchive, headers)
        .then((res) => {
            // console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

// ADD EXTRA

export async function getExtra() {
    const headers = await Firebase.getHeaders();

    return axios
        .get(Constants.getApiUrl() + Constants.adminExtra, headers)
        .then((res) => {
            // console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function addExtraFerry(journey, date, time) {
    const headers = await Firebase.getHeaders();
    // console.log(journey, date, time);

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };
    let extraFerry = {
        journey: journey,
        time: time,
        date: date,
        archive: false,
        meta: meta
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.adminExtraAdd, extraFerry, headers)
        .then((res) => {
            // console.log(res);
            console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getExtraArchive() {
    const headers = await Firebase.getHeaders();

    return axios
        .get(Constants.getApiUrl() + Constants.adminExtraArchive, headers)
        .then((res) => {
            console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function removeExtra(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminExtraRemove + id, headers)
        .then((res) => {
            return res.status;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

// REROUTE

export async function getReroute() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.adminReroute, headers)
        .then((res) => {
            console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function rerouteFerry(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };
    let rerouteData = {
        journey: journey,
        time: time,
        date: date,
        archive: false,
        meta: meta
    };
    // console.log(rerouteData);

    return await axios
        .post(Constants.getApiUrl() + Constants.adminRerouteAdd, rerouteData, headers)
        .then((res) => {
            console.log(res);
            console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function confirmRerouteFerry(journey, date, time) {
    const headers = await Firebase.getHeaders();

    const meta = {
        createdOn: new Date().getTime(),
        createdBy: "admin"
    };
    let rerouteData = {
        journey: journey,
        time: time,
        date: date,
        archive: false,
        meta: meta
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.adminRerouteAddConfirm, rerouteData, headers)
        .then((res) => {
            // console.log(res);
            console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getRerouteTimes(journey, date) {
    const headers = await Firebase.getHeaders();

    let cancelDate = {
        journey: journey,
        date: Constants.getEpochDate(date),
        dst: false
    };
    // console.log(cancelDate);
    return await axios
        .post(Constants.getApiUrl() + Constants.oneDayRerouteTimes, cancelDate, headers)
        .then((res) => {
            // console.log(res);
            console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function unRerouteFerry(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminRerouteRemove + id, headers)
        .then((res) => {
            return res.status;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getRerouteArchive() {
    const headers = await Firebase.getHeaders();
    return axios
        .get(Constants.getApiUrl() + Constants.adminRerouteArchive, headers)
        .then((res) => {
            console.log(res.data.ferries);
            return res.data.ferries;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

// MESSAGES

export async function getMessages() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.adminMessages, headers)
        .then((res) => {
            // console.log(res.data.messages);
            return res.data.messages;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function addMessage(message, date, time) {
    const headers = await Firebase.getHeaders();
    let newMessage = {
        time: time,
        date: Constants.getEpochDate(date),
        message: message
    };
    return await axios
        .post(Constants.getApiUrl() + Constants.adminMessagesAdd, newMessage, headers)
        .then((res) => {
            console.log(res);
            return res.status;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function removeMessage(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminMessagesRemove + id, headers)
        .then((res) => {
            return res.status;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getMessageArchive() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.adminMessagesArchive, headers)
        .then((res) => {
            console.log(res);
            return res.data.messages;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function addNotification(title, message, type) {
    const headers = await Firebase.getHeaders();
    let notification = {
        title: title,
        text: message,
        notificationType: type
    };
    return await axios
        .post(Constants.getApiUrl() + Constants.getNotificationEndpoint(notification.notificationType), notification, headers)
        .then((res) => {
            console.log(res);
            return res.status;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getNotificationArchive() {
    const headers = await Firebase.getHeaders();
    return await axios
        .get(Constants.getApiUrl() + Constants.adminNotificationsArchive, headers)
        .then((res) => {
            console.log(res);
            return res.data.notifications;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

// TICKETS

export async function getTickets() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminTickets, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getAdvancedTickets(pagination, filter, sorter) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.adminAdvancedTickets, { pagination, filter, sorter }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getTicket(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(`${Constants.getApiUrl()}${Constants.adminTickets}/${id}`, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getLimboTickets(pagination, filter, sorter) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.adminLimboTickets, { pagination, filter, sorter }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

// SEARCH
export async function searchTickets(searchParams) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.adminSearchTickets, { search: searchParams }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

// ARCHIVE
export async function getTicketArchive() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminTicketsArchive, headers)
        .then((res) => {
            // console.log(res);
            return res.data.tickets;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function updateTicket(newTicket) {
    // console.log(newTicket);

    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.updateTicketv2}`, { ticket: newTicket }, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function updateTicketStatus(id, status) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.updateTicketStatus, { status: status, id: id }, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function addNewTicket(newTicket) {
    // console.log(newTicket);

    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.addNewTicketv2}`, { ticket: newTicket }, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
export async function sendReceipt(id) {
    console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.ticketReceipt, ticketID, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function sendUpdateReceipt(id) {
    console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.ticketReceiptUpdate, ticketID, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function archiveTicket(id) {
    // console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.archiveTicket, ticketID, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
export async function deleteTicket(id) {
    // console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.deleteTicket, ticketID, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function refundTicket(id) {
    // console.log(id);
    let ticketID = { id: id };

    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.refundTicket, ticketID, headers)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function createUser(authToken, user) {
    // console.log(user);
    user.token = "";

    return await axios
        .post(Constants.getApiUrl() + Constants.createAccount, qs.stringify(user), {
            headers: {
                AuthToken: authToken,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getUser(authToken, uid) {
    // FIREBASE HEADERS
    let headersConfig = {
        headers: {
            AuthToken: authToken
        }
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.getAccount, qs.stringify({ uid: uid }), headersConfig)
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

export async function getAccounts() {
    const headers = await Firebase.getHeaders();
    // console.log(headers);
    return await axios
        .get(Constants.getApiUrl() + Constants.getAccounts, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function searchAccounts(searchTerm) {
    const headers = await Firebase.getHeaders();
    const searchObject = {
        search: searchTerm
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.searchAccounts, searchObject, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function updateAccount(type, value, uid) {
    const headers = await Firebase.getHeaders();
    // console.log(headers);

    const tempUpdate = {};
    tempUpdate[type] = value;

    const userUpdate = {
        update: tempUpdate,
        uid: uid
    };

    // console.log(userUpdate)

    return await axios
        .post(Constants.getApiUrl() + Constants.updateAccounts, userUpdate, headers)
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
export async function updateAccountV2(type, value, uid) {
    const headers = await Firebase.getHeaders();
    // console.log(headers);
    const userUpdate = {
        uid: uid,
        update: JSON.parse(`{ "${type}": "${value}" }`)
    };
    // console.log(userUpdate);

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.updateAccounts}`, userUpdate, headers)
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function removeAccount(uid) {
    const headers = await Firebase.getHeaders();
    console.log(headers);

    const userDelete = {
        uid: uid
    };

    console.log(userDelete);

    return await axios
        .post(Constants.getApiUrl() + Constants.deleteAccounts, userDelete, headers)
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getDashboardTimes() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.adminDashboard, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function cleanUpTickets() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.cleanUpTickets, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function sendNotification(account, title, message) {
    const headers = await Firebase.getHeaders();

    const notification = {
        account: account,
        title: title,
        message: message
    };

    console.log(notification);

    return await axios
        .post(Constants.getApiUrl() + Constants.sendNotification, notification, headers)
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function getDefinitions() {
    const headers = await Firebase.getHeaders();

    return await axios
        .get(Constants.getApiUrl() + Constants.definitions, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function saveNewDefinition(month) {
    const headers = await Firebase.getHeaders();
    // const username = await Firebase.getUsername();

    // console.log(username);

    const newDefinition = {
        m: month.m,
        d: month.d,
        only: month.only,
        order: 0,
        user: null
    };

    return await axios
        .post(Constants.getApiUrl() + Constants.addDefinitions, newDefinition, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function removeDefinition(id) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(Constants.getApiUrl() + Constants.removeDefinitions, { id: id }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}

export async function prioritiseDefinition(id, order) {
    const headers = await Firebase.getHeaders();

    if (order < 0) {
        order = 0;
    }

    return await axios
        .post(Constants.getApiUrl() + Constants.prioritiseDefinitions, { id: id, order: order }, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return e;
        });
}
