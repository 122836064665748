import React, { useState } from "react";
import { Button, Select, Space, Row, Col, Form } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import * as ToryService from "../../../services/ToryService";

const { Option } = Select;

function checkForPrefix(car, number) {
    let string = "";
    if (car !== null && car !== "") {
        string += "🚗 ";
    }
    if (number !== null && number !== "") {
        string += "☎️";
    }
    if (string === "") {
        string = "Temp:";
    } else {
        string += ":";
    }
    return string;
}

async function fetchUserList(username) {
    // console.log('fetching user', username);
    if (username.length > 3) {
        const results = await ToryService.searchAccounts(username);
        // console.log(results);

        const userLists = results.accounts.map((a) => ({
            label: `⭐: ${a.displayName === "" ? "" : `${a.displayName}, `} ${
                a.email
            }`,
            value: JSON.stringify({ ...a, accountType: "account" })
        }));

        const accountLists = results.tickets.map((t) => ({
            label: `${checkForPrefix(t.cardetails, t.number)}: ${t.name}, ${
                t.email
            }`,
            value: JSON.stringify({ ...t, accountType: "temp" })
        }));
        // console.log(userLists);
        return [...userLists, ...accountLists];
    } else {
        return [];
    }
}

export const NewTicketFindAccount = (props) => {
    const { updateAccount, clearAccount } = props;

    const ref = React.createRef();

    const [data, setData] = useState([]);
    const [value] = useState(undefined);

    async function handleSearch(value) {
        if (value) {
            let results = await fetchUserList(value);
            setData(results);
        } else {
            setData([]);
        }
    }

    function handleChange(value) {
        updateAccount(value);
    }

    return (
        // <Space direction="horizontal">
        <Row>
            <Col flex={"auto"}>
                <Form
                    ref={ref}
                    labelCol={{
                        span: 8
                    }}
                    wrapperCol={{
                        span: 16
                    }}
                >
                    <Form.Item
                        label="Account"
                        name="account"
                        style={{ paddingRight: "5px" }}
                    >
                        <Select
                            showSearch
                            value={value}
                            placeholder={"Find User"}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearch}
                            onSelect={handleChange}
                            notFoundContent={"No Users Found"}
                        >
                            {data.map((d) => (
                                <Option key={d.value}>{d.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Col>
            <Col>
                {value !== null ? (
                    <Space>
                        <Button
                            icon={<CloseCircleOutlined />}
                            onClick={() => {
                                ref.current.setFieldsValue({ account: null });
                                clearAccount();
                            }}
                        ></Button>
                    </Space>
                ) : (
                    <></>
                )}
            </Col>
        </Row>
        // </Space>
    );
};
