import { isSameDay } from "date-fns";
import * as PassengerUtils from "./PassengerUtils";
import * as CarUtils from "./CarUtils";

export function GetTicketCount(tickets) {
    return tickets.length;
}

export function getOneTimeTicket(time, date, tickets) {
    if (time === undefined || time === null) {
        return tickets;
    }
    return tickets.filter((t) => {
        if (
            (isSameDay(new Date(date), new Date(t.ticket.outDate)) && t.ticket.outTime === time) ||
            (isSameDay(new Date(date), new Date(t.ticket.returnDate)) && t.ticket.returnTime === time)
        ) {
            // console.log(true);
            return true;
        } else {
            // console.log(false);
            return false;
        }
    });
}

export function getOneDayTotalFerries(times, journey) {
    // console.log(times);
    if (times) {
        switch (journey) {
            case "dt":
                return times.dtTimes.length + times.dt_bTimes.length;
            case "dm":
                return times.dmTimes.length + times.dm_bTimes.length;
            default:
                return times.dtTimes.length + times.dt_bTimes.length + times.dmTimes.length + times.dm_bTimes.length;
        }
    } else {
        return 0;
    }
}

export function generateFerryArray(times) {
    // console.log(times);
    return [...times.dtTimes, ...times.dt_bTimes, ...times.dmTimes, ...times.dm_bTimes].map((t) => t.time).sort((a, b) => a - b);
}

export function passengerPerFerry(date, times, tickets) {
    const array = times.map((time, index) => {
        let count = 0;
        time.tickets.forEach((timeTicket) => {
            count += PassengerUtils.SingleTicketPassengerTotal(timeTicket.ticket);
        });
        return count;
    });
    // console.log(array);
    return array;
}

// NEW
export function carsPerFerry(date, times) {
    const array = times.map((time, index) => {
        let count = 0;
        time.tickets.forEach((timeTicket) => {
            count += CarUtils.getVehicleTotal(timeTicket.ticket.passengers, 2);
        });
        return count;
    });
    // console.log(array);
    return array;
}

export function TicketTypeTotal(tickets, type) {
    // console.log(tickets);
    let totalType = 0;
    tickets.forEach((t) => {
        if (t.method === type) {
            totalType = totalType + 1;
        }
    });
    return totalType;
}

export function PassengerTypeTotal(tickets, type) {
    let visitorCodes = PassengerUtils.passengerManifest
        .filter((pm) => pm.islander === false)
        .map((p) => {
            return p.code;
        });
    // console.log(visitorCodes);
    let islanderCodes = PassengerUtils.passengerManifest
        .filter((pm) => pm.islander === true)
        .map((p) => {
            return p.code;
        });
    // console.log(islanderCodes);

    let total = 0;
    // console.log(type);
    if (type === "visitor") {
        tickets.forEach((t) => {
            // console.log(t._id);
            let passengers = t.ticket.passengers;
            visitorCodes.forEach((v) => {
                // console.log(v);
                // console.log(passengers);
                // console.log(passengers[v]);
                // total += passengers[v];
                if (passengers[v] > 0) {
                    total += passengers[v];
                }
            });
        });
        return total;
    } else {
        tickets.forEach((t) => {
            // console.log(t.id);
            let passengers = t.ticket.passengers;
            islanderCodes.forEach((v) => {
                // console.log(v);
                // console.log(passengers);
                // console.log(passengers[v]);
                if (passengers[v] > 0) {
                    total += passengers[v];
                }
            });
        });
        return total;
    }
    // console.log(total);
}

export function calculateTotalTicketRevenue(tickets) {
    let passengers = [];
    for (let i = 0; i < tickets.length; i++) {
        const element = tickets[i].passengers;
        passengers.push(...element);
    }
    // console.log(passengers);
    let total = 0;
    for (let j = 0; j < passengers.length; j++) {
        const element = passengers[j];
        total += element.price * element.quantity;
    }
    return total;
}
