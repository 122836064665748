import React, { useEffect, useState } from "react";

import { Space, Card, Statistic, Drawer, Typography } from "antd";

import * as CarUtils from "../../../utils/CarUtils";
import * as Constants from "../../../utils/Constants";
import * as GlobalUtils from "../../../utils/Global";
import * as PassengerUtils from "../../../utils/PassengerUtils";
import TicketStatus from "../../tickets/TicketStatus";

import { UserOutlined, InfoCircleOutlined, CarOutlined } from "@ant-design/icons";
import { PassengerIconsSummary } from "../../tickets/passengers/PassengerIconsSummary";
import { TbMotorbike, TbTruck } from "react-icons/tb";
import { FaShuttleVan } from "react-icons/fa";
import { NoteCard } from "../notes/NoteCard";

export default function V2DashboardStats(props) {
    const { date, time, journey, combinedFerry, secondFerry, tickets } = props;

    useEffect(() => {
        // console.log(`stats update`, date, time, journey);
    }, [date, time, journey]);

    const [open, setOpen] = useState(false);

    // console.log(tickets);
    const totalPassengers = PassengerUtils.TicketPassengerTotal(tickets);

    const totalDeckSpaces = CarUtils.getDeckSpaceTotal(tickets);
    const totalCars = CarUtils.getVehicleTotal(tickets, 2);
    const totalVans = CarUtils.getVehicleTotal(tickets, 4);
    const totalBikes = CarUtils.getVehicleTotal(tickets, 1);
    const totalTrucks = CarUtils.getVehicleTotal(tickets, 6);

    function getTotalRevenue(tickets) {
        let totalPassengers = 0;
        if (tickets !== null) {
            // console.log(tickets);
            tickets.forEach((t) => {
                let price = 0;
                if (price !== null && t.payment.stripePayment) {
                    price = t.price;
                }
                totalPassengers += price;
            });
        }
        return totalPassengers / 100;
    }

    // console.log(tickets);

    return (
        <>
            <Space direction="horizontal" wrap>
                <Card size="small">
                    <Statistic
                        title="Total Passengers"
                        prefix={
                            totalPassengers >= GlobalUtils.totalPassengers && time ? (
                                <UserOutlined style={{ color: "red" }} />
                            ) : (
                                <UserOutlined />
                            )
                        }
                        value={`${totalPassengers}`}
                        valueStyle={{
                            color: totalPassengers >= GlobalUtils.totalPassengers && time ? "red" : ""
                        }}
                        precision={0}
                    />
                </Card>

                {GlobalUtils.deckSpaces ? (
                    <Space direction="horizontal">
                        <Card
                            size="small"
                            onClick={totalCars >= 0 ? () => setOpen(true) : () => console.log("")}
                            style={{ cursor: "pointer" }}
                        >
                            <Statistic
                                title="Total Cars"
                                prefix={
                                    totalDeckSpaces >= GlobalUtils.deckSpaces && time ? (
                                        <CarOutlined style={{ color: "red" }} />
                                    ) : (
                                        // ? <CarTwoTone twoToneColor={"red"} />
                                        <CarOutlined />
                                    )
                                }
                                value={` ${totalCars}`}
                                valueStyle={{
                                    color: totalDeckSpaces >= GlobalUtils.deckSpaces && time ? "red" : ""
                                }}
                                precision={0}
                            />
                        </Card>
                    </Space>
                ) : (
                    <></>
                )}

                {totalVans > 0 ? (
                    <Space direction="horizontal">
                        <Card
                            size="small"
                            onClick={totalVans >= 0 ? () => setOpen(true) : () => console.log("")}
                            style={{ cursor: "pointer" }}
                        >
                            <Statistic
                                title="Total Vans"
                                prefix={
                                    totalDeckSpaces >= GlobalUtils.deckSpaces && time ? (
                                        <FaShuttleVan style={{ color: "red" }} />
                                    ) : (
                                        // ? <CarTwoTone twoToneColor={"red"} />
                                        <FaShuttleVan />
                                    )
                                }
                                value={` ${totalVans}`}
                                valueStyle={{
                                    color: totalDeckSpaces >= GlobalUtils.deckSpaces && time ? "red" : ""
                                }}
                                precision={0}
                            />
                        </Card>
                    </Space>
                ) : (
                    <></>
                )}

                {totalTrucks > 0 ? (
                    <Space direction="horizontal">
                        <Card
                            size="small"
                            onClick={totalTrucks >= 0 ? () => setOpen(true) : () => console.log("")}
                            style={{ cursor: "pointer" }}
                        >
                            <Statistic
                                title="Total Trucks"
                                prefix={
                                    totalDeckSpaces >= GlobalUtils.deckSpaces && time ? (
                                        <TbTruck style={{ color: "red" }} />
                                    ) : (
                                        // ? <CarTwoTone twoToneColor={"red"} />
                                        <TbTruck />
                                    )
                                }
                                value={` ${totalTrucks}`}
                                valueStyle={{
                                    color: totalDeckSpaces >= GlobalUtils.deckSpaces && time ? "red" : ""
                                }}
                                precision={0}
                            />
                        </Card>
                    </Space>
                ) : (
                    <></>
                )}

                {totalBikes > 0 ? (
                    <Space direction="horizontal">
                        <Card
                            size="small"
                            onClick={totalBikes >= 0 ? () => setOpen(true) : () => console.log("")}
                            style={{ cursor: "pointer" }}
                        >
                            <Statistic
                                title="Total Bikes"
                                prefix={
                                    totalDeckSpaces >= GlobalUtils.deckSpaces && time ? (
                                        <TbMotorbike style={{ color: "red" }} />
                                    ) : (
                                        // ? <CarTwoTone twoToneColor={"red"} />
                                        <TbMotorbike />
                                    )
                                }
                                value={` ${totalBikes}`}
                                valueStyle={{
                                    color: totalDeckSpaces >= GlobalUtils.deckSpaces && time ? "red" : ""
                                }}
                                precision={0}
                            />
                        </Card>
                    </Space>
                ) : (
                    <></>
                )}

                <NoteCard date={date} time={time} journey={journey} />

                {GlobalUtils.showPrices() ? (
                    <Card size="small">
                        <Statistic title="Online Revenue" prefix={"€"} value={getTotalRevenue(tickets)} precision={2} />
                    </Card>
                ) : (
                    <></>
                )}

                <Drawer open={open} closable={true} height={"50%"} onClose={() => setOpen(false)} placement="bottom">
                    <Space direction="vertical">
                        {tickets.filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 2) > 0).length > 0 ? (
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <Typography.Title level={5}>
                                        <CarOutlined />
                                        {`  Car List ${time ? `at ${Constants.getTimestamp(time)}` : ""}`}
                                    </Typography.Title>
                                </Space>

                                {tickets
                                    // .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 6) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`car${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`car${index}`}>
                                                {`${t.name}, ${t.meta.cardetails ?? "No details"
                                                    }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {/* {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 2) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`car${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`car${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 2)}x ${t.name}, ${
                                                    t.meta.cardetails ?? "No car details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 4) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`van${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`van${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 4)}x ${t.name}, ${
                                                    t.meta.cardetails ?? "No van details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 1) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`bike${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`bike${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 1)}x ${t.name}, ${
                                                    t.meta.cardetails ?? "No bike details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))}

                                {tickets
                                    .filter((t) => CarUtils.getSingleTicketVehicleTotal(t, 6) > 0)
                                    .map((t, index) => (
                                        <Space direction="horiztonal" key={`lorry${index}`}>
                                            <PassengerIconsSummary passengers={t.passengers} cardetails={""} />
                                            <TicketStatus value={t.status ?? ""} />
                                            <Typography.Text key={`lorry${index}`}>
                                                {`${CarUtils.getSingleTicketVehicleTotal(t, 6)}x ${t.name}, ${
                                                    t.meta.cardetails ?? "No lorry details"
                                                }`}
                                            </Typography.Text>
                                        </Space>
                                    ))} */}

                                {/* {`${tickets.filter((t) => t.meta.specialreq !== null || `${t.meta.specialreq}` !== "null").length}`} */}
                            </Space>
                        ) : (
                            <Typography.Text>No Cars Booked</Typography.Text>
                        )}
                        {tickets.filter((t) => t.meta.specialreq !== null && `${t.meta.specialreq}` !== "null" && t.meta.specialreq !== "")
                            .length > 0 ? (
                            <>
                                <Space direction="vertical">
                                    <Space direction="horizontal">
                                        <Typography.Title level={5}>
                                            <InfoCircleOutlined />
                                            {`  Special Requirements ${time ? `at ${Constants.getTimestamp(time)}` : ""}`}
                                        </Typography.Title>
                                    </Space>
                                    {tickets
                                        .filter(
                                            (t) =>
                                                t.meta.specialreq !== null && `${t.meta.specialreq}` !== "null" && t.meta.specialreq !== ""
                                        )
                                        .map((t, index) => (
                                            <Typography.Text key={index}>{`${t.name}: ${t.meta.specialreq}`}</Typography.Text>
                                        ))}
                                </Space>
                            </>
                        ) : (
                            <></>
                        )}
                    </Space>
                </Drawer>
            </Space>
        </>
    );
}
