import React from "react";

import {
    Button,
    Popconfirm,
    Space,
    Tooltip,
} from "antd";

import {
    CloseCircleOutlined,
} from "@ant-design/icons";

import * as Constants from "../../utils/Constants";

export default function CancelButtons(props) {

    const { ferries, lock, cancelFerry } = props;

    return (
        <Space size="middle" wrap>
            {ferries.sort((a, b) => a.time - b.time).map((i, index) => {
                return (
                    <Popconfirm
                        key={index}
                        title={`Are you sure ${lock ? "lock" : "cancel"} this ferry?`}
                        okText="Yes"
                        onConfirm={() => cancelFerry(i.time)}
                        onCancel={Constants.cancel}
                        cancelText="No"
                    >
                        {i.ferry === "extra" ? (
                            <Tooltip title={`Note: This is an extra ferry, you can ${lock ? "lock" : "cancel"} it`}>
                                <Button icon={<CloseCircleOutlined />} style={{}}>
                                    {` ${Constants.getTimestamp(i.time)} `}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button danger icon={<CloseCircleOutlined />} style={{}}>
                                {` ${Constants.getTimestamp(i.time)} `}
                            </Button>
                        )}
                    </Popconfirm>
                );
            })}
        </Space>
    );
};
