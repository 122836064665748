import { Typography, Space, Row, Col, Statistic, Divider } from "antd";
import React from "react";
import * as Constants from "../../utils/Constants";
import Chart from "react-apexcharts";
// import {
//     carsPerFerry,
//     generateFerryArray,
//     passengerPerFerry
// } from "../../utils/StatisticUtils";
// import { TicketIcon } from "../../assets/TicketIcon";
// import * as CarUtils from "../../utils/CarUtils";
import * as StatsUtils from "../../utils/StatisticUtils";
// import * as PassengerUtils from "../../utils/PassengerUtils";

import { UserOutlined, StarOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { Card } from "antd";

export default function TotalTicketPassengerTypeChart(props) {
    const { data, date, journey } = props;

    let chartData = {
        options: {
            chart: {
                id: "Passenger & Car Count",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: data.map((f) => Constants.getTimestamp(f.time))
            }
        },
        series: [
            {
                name: "Visitors",
                data: data.map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "visitor"))
            },
            {
                name: "Islander",
                data: data.map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "islander"))
            }
        ]
    };
    return (
        <Card
            title={`Ticket Type Breakdown for ${format(date, "do MMM yyyy")} ${
                journey === "all" ? "" : Constants.getJourneyString(journey, false)
            }`}
            bordered={false}
            actions={[
                <Statistic
                    title={"Total Islanders"}
                    value={`${data
                        .map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "visitor"))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}`}
                    prefix={<StarOutlined />}
                />,
                <Statistic
                    title={"Total Islanders"}
                    value={data
                        .map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "islander"))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)}
                    prefix={<UserOutlined />}
                />
            ]}
        >
            <Row gutter={[16, { sm: 16, md: 24 }]} style={{ width: "100%" }}>
                <Col span={16}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Chart
                                options={chartData.options}
                                // toolbar={false}
                                series={chartData.series}
                                type="bar"
                                width="100%"
                                height="340px"
                            />
                        </Space>
                    </Space>
                </Col>
                <Col span={8}>
                    <Typography.Title level={5}>Visitors vs Islanders</Typography.Title>
                    <Chart
                        options={{
                            toolbar: { show: false },
                            labels: ["Visitor", "Islander"],
                            legend: {
                                position: "bottom"
                            }
                        }}
                        type="donut"
                        width="100%"
                        height="340px"
                        series={[
                            data
                                .map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "visitor"))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0),
                            data
                                .map((f) => StatsUtils.PassengerTypeTotal(f.tickets, "islander"))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                        ]}
                    />
                </Col>
            </Row>
        </Card>
    );
}
