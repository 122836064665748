import React from "react";

import { Col, Drawer, Spin } from "antd";

import { CloseSquareOutlined } from "@ant-design/icons";

import RecurringTicketViewer from "./RecurringTicketViewer";

const ViewRecurringPanel = (props) => {
    const { width, onClose, visible, singleTicket } = props;

    return (
        <Drawer
            title="View Ticket"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible}
        >
            {singleTicket ? (
                <RecurringTicketViewer singleTicket={singleTicket} />
            ) : (
                <Col xs={18} md={8} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <Spin />
                    </div>
                </Col>
            )}
        </Drawer>
    );
};
export default ViewRecurringPanel;
