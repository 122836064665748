import React, { useContext } from "react";

import { Button, Typography, Space } from "antd";

import { signOutWithGoogle } from "../services/firebase";
import { UserContext } from "../provider/UserProvider";
import { getFirstName } from "../utils/Constants";

function LogoutButton() {
    const user = useContext(UserContext);

    function signOut() {
        localStorage.setItem("loggedIn", "false");
        signOutWithGoogle();
    }

    return (
        <Space direction="horizontal">
            <Typography.Text style={{ paddingRight: "10px" }}>
                Hi, {getFirstName(user.displayName) === "" ? "Admin" : getFirstName(user.displayName)}
            </Typography.Text>
            {/* <Calculator /> */}
            <Button onClick={() => signOut()}>Logout</Button>
        </Space>
    );
}

export default LogoutButton;
