import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function getNotes(date, time) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminNotes}`, { date, time }, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function getNotesDates(date, time) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminNotesDates}`, { date, time }, headers)
        .then((res) => {
            // console.log(res.data);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function addNewNote(note, priority, date, time) {
    const headers = await Firebase.getHeaders();

    return await axios
        .post(`${Constants.getApiUrl()}${Constants.adminNotes}`, { note, priority, date, time }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function editNote(id, note, priority, date, time) {
    const headers = await Firebase.getHeaders();

    return await axios
        .put(`${Constants.getApiUrl()}${Constants.adminNotes}`, { id, note, priority, date, time }, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}

export async function deleteNote(id) {
    const headers = await Firebase.getHeaders();
    // console.log(id);
    // console.log(headers);
    return await axios
        .delete(`${Constants.getApiUrl()}${Constants.adminNotes}/${id}`, headers)
        .then((res) => {
            // console.log(res);
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return { status: 404 };
        });
}
