import React from 'react';
// CSS
import "../../pages/css/Timetables.css";

import {
  Row,
  Col,
} from "antd";

import * as Constants from "../../utils/Constants";
import { getMonthlyData, getDailyData } from "../../utils/Timetable.data";

export const DayCells = (props) => {

    const { months, times, journey, days, only } = props;

    // console.log(times)
    const monthlyTimes = getMonthlyData(months, times);
    // console.log(monthlyTimes);
    const dayTimes = getDailyData(journey, days, monthlyTimes, only);

    if (dayTimes.length % 2 !== 0 && dayTimes.length > 4) {
        // ODD NUMBER
        dayTimes.push({ time: "-" });
    }

    if (dayTimes.length === 0) {
        // RETURN 2 ROWS
    } else if (dayTimes.length > 0 && dayTimes.length < 6) {
        // ONE ROW
    } else if (dayTimes.length >= 6 && dayTimes.length < 12) {
        // TWO ROWS
    }

    return (
        <Row
            className={!Constants.getTheme() ? "white-bg" : "dark-bg"}
            style={{
                textAlign: "center",
            }}
            justify="space-around"
        >
            {dayTimes.length > 0 ? (
                dayTimes.map((i, index) => {
                    return (
                        <Col key={index} style={{ marginTop: "1px", padding: "6px" }}>
                            {" "}
                            {Constants.getTimestamp(i.time)}
                        </Col>
                    );
                })
            ) : (
                <Col
                    style={{ textAlign: "center", marginTop: "1px", padding: "6px" }}
                    span={24}
                >
                    <span>No ferry times added.</span>
                </Col>
            )}
        </Row>
    );
};
